<template>
    <div class="component" v-loading="loading">
        <div class="search-area">
            <el-input class="search-text" @change="getTableData(true)" clearable placeholder="组件型号" suffix-icon="el-icon-search" v-model="query.moduleModel"></el-input>
            <el-select class="search-company" @change="getTableData(true)" filterable clearable v-model="query.companyId" placeholder="厂商">
                <el-option v-for="item in companyList" :key="item.id" :label="companyLabel(item)" :value="item.id"></el-option>
            </el-select>
            <el-select class="search-component-type" @change="getTableData(true)" clearable v-model="query.componentType" placeholder="组件类型">
                <el-option label="单晶" value="MONO_C_SI"></el-option>
                <el-option label="多晶" value="MULTI_C_SI"></el-option>
                <el-option label="薄膜" value="THIN_FILM"></el-option>
                <el-option label="碲化镉" value="CDTE"></el-option>
                <el-option label="铜铟镓硒" value="CIGS"></el-option>
            </el-select>
            <el-select class="search-component-status" @change="getTableData(true)" clearable v-model="query.status" placeholder="状态">
                <el-option label="正常" value="ENABLE"></el-option>
                <el-option label="禁用" value="DISABLE"></el-option>
            </el-select>
        </div>
        <div class="table-area">
            <el-table :data="componentData" stripe class="table" height="100" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="index" width="80" :index="computeIndex"></el-table-column>
                <el-table-column prop="company.companyNameCn" label="厂商中文名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="company.companyNameEn" label="厂商英文名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="moduleModel" label="组件型号" ></el-table-column>
                <el-table-column label="组件类型" width="120" align="center">
                    <template slot-scope="scope">
                        {{scope.row.moduleType}}
                    </template>
                </el-table-column>
                <el-table-column sortable label="更新时间" width="120">
                    <template slot-scope="scope">
                        {{scope.row.updatedAtStr == null ? scope.row.createdAtStr.substring(0, 10) : scope.row.updatedAtStr.substring(0, 10)}}
                    </template>
                </el-table-column>
                <el-table-column label="状态" width="80">
                    <template slot-scope="scope">
                        {{scope.row.status === 'ENABLE' ? '正常' : '禁用'}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center"  width="260">
                    <template slot-scope="scope">
                        <el-button class="btn" type="text" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button class="btn" type="text" @click="handleDelete(scope.row)">删除</el-button>
                        <el-button class="btn" type="text" @click="handleDisable(scope.row)">{{scope.row.status == 'ENABLE' ? '禁用' : '启用'}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <el-button class="add-component" @click="handleAdd" size="small" type="primary">添加组件</el-button>
            <el-button class="download-component" @click="handleDownload" type="text">下载Excel</el-button>
            <el-button class="import-component" @click="importComponent" type="text">批量维护</el-button>
            <div class="page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="query.currentPage"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="query.pageSize"
                    layout="total, sizes, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <transition name="el-fade-in-linear">
            <div @click.self="handleCancel" v-show="showShadow" class="shadow">
                <div class="component-detail-dialog">
                    <transition name="el-fade-in-linear">
                        <ComponentDetail @commitSuccess="handleCommitSuccess" @cancel="handleCancel" v-if="showEditDialog" :dialogType="dialogType" :componentId="componentId" :companyList="companyList"></ComponentDetail>
                    </transition>
                </div>
                <div class="component-detail-dialog">
                    <transition name="el-fade-in-linear">
                        <ComponentBatch v-if="showBatch" @cancel="handleCancel"></ComponentBatch>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import ComponentDetail from './ComponentDetail'
import ComponentBatch from './ComponentBatch';
export default {
    data(){
        return {
            query: {
                moduleModel: '',
                companyId: '',
                componentType: '',
                status: '',
                pageSize: 10,
                currentPage: 1
            },
            companyList: [],
            loading: false,
            componentData: [],
            total: 0,
            showShadow: false,
            showEditDialog: false,
            dialogType: 'ADD',
            componentId: null,
            showBatch: false,
            multipleSelection: [],
        }
    },
    mounted(){
        this.loading = true;
        var queryParams = {
            status: 'ENABLE',
            companyType: 'COMPONENT',
        };
        this.$http.get(this.$apiPath.adminCompanyList, {params: queryParams}).then(res => {
            this.loading = false;
            this.companyList = res.data.data.companyList;
        });
        this.getTableData(true);
    },
    methods: {
        computeIndex(index) {
            return (this.query.currentPage - 1) * this.query.pageSize + index + 1
        },
        handleSelectionChange(val) {
            var tmpArr = []
            val.forEach(item => {
                tmpArr.push(item.id)
            })
            this.multipleSelection = tmpArr.join(',');
        },
        getComponentType(componentType){
            switch(componentType){
                case 'MONO_C_SI': return '单晶'
                case 'MULTI_C_SI': return '多晶'
                case 'THIN_FILM': return '薄膜'
                case 'CDTE': return '碲化镉'
                case 'CIGS': return '铜铟镓硒'
            }
        },
        companyLabel(item){
            if(item.companyNameCn !== '' && item.companyNameCn != null){
                return item.companyNameCn + ' - ' + item.companyNameEn
            }else{
                return item.companyNameEn
            }
        },
        getTableData(reset){
            this.loading = true;
            this.$http.get(this.$apiPath.adminComponentList,{params: this.query}).then(res => {
                var data = res.data;
                this.total = data.data.total;
                this.componentData = data.data.componentList;
                if(reset){
                    this.query.currentPage = 1;
                }
                this.loading = false;
            });
        },
        handleAdd(){
            this.componentId = null;
            this.showShadow = true
            this.dialogType = 'ADD'
            this.showEditDialog = true
        },
        handleEdit(row){
            this.componentId = row.id;
            this.dialogType = 'EDIT';
            this.showEditDialog = true;
            this.showShadow = true;
        },
        handleDelete(row){
            this.$confirm('此操作将永久删除该组件，是否继续？', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$apiPath.adminDeleteComponent, {
                    componentId: row.id,
                    userId: this.$store.getters.loginUserId
                }).then(res => {
                    if(res.status === 200 && res.data.status === 200){
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getTableData();
                    }else{
                        this.$message.error(res.data.message);
                    }

                });

            }).catch(() => {
                //cancel
            });
        },
        handleDisable(row){
            var type = '', typeStr = '';
            if(row.status === 'ENABLE'){
                type = 'DISABLE';
                typeStr = '禁用';
            }else{
                type = 'ENABLE';
                typeStr = '启用';
            }
            this.$confirm('确认要' + typeStr +'当前组件吗？', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$apiPath.adminComponentStatus, {componentId: row.id, type: type}).then(res => {
                    if(res.status === 200 && res.data.status === 200){
                        this.$message({
                            type: 'success',
                            message: typeStr + '成功!'
                        });
                        this.getTableData();
                    }
                });
            }).catch(() => {
                //cancel
            });
        },
        handleSizeChange(value){
            this.query.pageSize = value
            this.getTableData()
        },
        handleCurrentChange(value){
            this.query.currentPage = value
            this.getTableData()
        },
        handleCancel(){
            this.showEditDialog = false;
            this.showShadow = false;
            this.showBatch = false
        },
        handleCommitSuccess(){
            this.showEditDialog = false;
            this.showShadow = false;
            this.getTableData();
        },
        importComponent(){
            this.showShadow = true
            this.showBatch = true
        },
        handleDownload(){
            var _this = this
            this.$message({
                message: '即将开始下载',
                duration: 2000,
                onClose: function(){
                    _this.$http({
                        method: 'get',
                        url: _this.$apiPath.adminDownloadComponent,
                        params: {ids: _this.multipleSelection},
                        responseType: 'blob'
                    }).then(res => {
                        var blob = new Blob([res.data], {type: 'application/force-download'})
                        var aTag = document.createElement('a')
                        aTag.download = 'Component.xlsx'// 下载的文件名
                        aTag.href = URL.createObjectURL(blob)
                        aTag.click()
                        URL.revokeObjectURL(blob)
                    });
                }
            });
        },
    },
    components: {
        ComponentDetail,
        ComponentBatch
    }
}
</script>

<style lang="scss" scoped>
.search-area{
    height: 32px;
    padding: 10px 14px;
    border-bottom: solid 1px #e8e8e8;
}
.search-text{
    width: 285px;
    height: 32px;
    float: left;
}
.search-company, .search-component-type, .search-component-status{
    margin-left: 8px;
    float: left;
}
.search-company{
    width: 180px;
}
.search-component-type{
    width: 135px;
}
.search-component-status{
    width: 112px;
}
.table{
    height: calc(100vh - 270px) !important;
}
.footer{
    .add-component{
        font-size: 14px;
        line-height: 14px;
        height: 30px;
        padding: 7px 15px;
        background-color: #5C8EFF;
        margin: 14px 0 0 10px;
    }

    .download-component, .import-component{
        color: #5C8EFF;
        height: 30px;
        line-height: 14px;
        padding: 7px 0;
        margin-left: 20px;
        text-decoration: underline;
    }

    .page{
        float: right;
        margin: 11px 6px 0 0;
    }
}
.component-detail-dialog{
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -300px 0 0 -281px;
}
</style>

<style lang="scss">
.search-area .el-input__inner{
    height: 32px !important;
    // line-height: 30px !important;
}
.el-input__icon{
    line-height: 32px !important;
}
</style>
