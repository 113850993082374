<template>
  <el-drawer title="草稿箱" size="700px" :modal="false" :visible.sync="drawer" :direction="direction" :show-close="false"
    :before-close="handleClose">
    <div class="context">
      <el-table :data="tableData" style="width: 100%" height="100" class="table"  stripe>
        <el-table-column prop="username" label="收件人" width="160" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ getUserName(scope.row.userList) }}
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" width="120" >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editHandle(scope.row)">编辑</el-button>
            <el-button type="text"  size="small" @click="delHandle(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page.sync="currentPage"
      :page-sizes="[10, 20, 30]" :page-size="pageSize" layout="total, sizes, prev, pager, next" :total="totalNum">
    </el-pagination>
    <div class="footer">
      <div class="btn">
        <el-button size="small" type="primary" @click="handleClose">取消</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
export default {
  name: 'daftDrawer',
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      direction: 'rtl',
      tableData: [],
      // 分页
      currentPage: 1,
      pageSize: 10,
      totalNum: 0,
      innerDrawer: false,
      innerTitle: '', // 标题
      
    }
  },
  computed: {

  },
  created(){
    // this.getDaftList()
  },
  methods: {
    getUserName(userList){
			// console.log(userList);
			return userList.map(item=>item.username).join(',')
		},
    // 获取草稿箱列表
    getDaftList() {
      this.$http.get(this.$apiPath.adminEmailDraftList, {
        params: {
          currentPage: this.currentPage,
          pageSize: this.pageSize
        }
      }).then(res => {
        if (res.status == 200) {
          this.tableData = res.data.data.list
          this.totalNum = res.data.data.total || 0
        }
      })
    },
    // 关闭外部抽屉
    handleClose() {
      this.$emit("update:drawer", false)
    },
    //编辑草稿箱
    editHandle(data) {
      this.$emit("editDrawerHandle",data)
      // this.handleClose()
    },
    delHandle(params){
      this.$confirm('确定删除该数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete(this.$apiPath.adminEmailDelete+`/${params.id}`)
          .then(()=>{
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getDaftList()
          })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //分页
    sizeChange(val) {
      this.pageSize = val
      this.currentPage = 1
      this.getDaftList()
    },
    currentChange(val) {
      this.currentPage = val
      this.getDaftList()
    },
  },
}
</script>
<style lang="scss" scoped>
.flex-end {
  display: flex;
  justify-content: flex-end;

  .top-btn {
    width: 140px;
  }
}

.context {
  padding: 0 20px;
  height: calc(100% - 115px);
  overflow-y: auto;

  .table {
    border: 1px solid #EBEEF5;
    border-bottom: none;
    height: 100% !important;
  }
}
.el-pagination {
    text-align: right;
    margin-top: 10px;
  }
.footer {
  background: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 700px;
  border-top: 1px solid #EBEEF5;
  height: 60px;
  text-align: right;
  padding: 15px;
  box-sizing: border-box;
  z-index: 1;
  
  .btn {
    // margin-top: 20px;
    text-align: right;
    margin-right: 20px;
    .el-button {
      width: 88px;
    }
  }
}
.label{
  margin-right: 10px;
  font-size: 14px;
  color: #606266;
}
.inner_drawer__footer {
  background: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 700px;
  border-top: 1px solid #EBEEF5;
  height: 60px;
  text-align: right;
  padding: 15px;
  box-sizing: border-box;
  .el-button {
    width: 88px;
  }
}

</style>
