<template>
  <div class="inverter-tab">
    <div class="search-area">
      <el-input class="search-text" @change="getTableData(true)" clearable placeholder="汇流箱型号" suffix-icon="el-icon-search" v-model="query.inverterModule"></el-input>
      <el-select class="search-company" @change="getTableData(true)" filterable clearable v-model="query.companyId" placeholder="厂商">
        <el-option v-for="item in companyList" :key="item.id" :label="companyLabel(item)" :value="item.id"></el-option>
      </el-select>
      <el-select class="search-component-status" @change="getTableData(true)" clearable v-model="query.status" placeholder="状态">
          <el-option label="正常" value="ENABLE"></el-option>
          <el-option label="禁用" value="DISABLE"></el-option>
      </el-select>
    </div>
    <div class="table-area">
      <el-table :data="comboboxData" stripe class="table" height="100" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="index" width="80" :index="computeIndex"></el-table-column>
        <el-table-column prop="company.companyNameCn" label="厂商中文名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="company.companyNameEn" label="厂商英文名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="acDcType" label="汇流箱交直流种类" ></el-table-column>
        <el-table-column prop="comboboxType" label="汇流箱型号" ></el-table-column>
        <el-table-column prop="numMaxLine" label="汇流箱最大输入路数" ></el-table-column>
        <el-table-column prop="iMax" label="汇流箱最大输入电流（A）" ></el-table-column>
        <el-table-column sortable label="更新时间" width="120">
          <template slot-scope="scope">
            {{scope.row.updatedAtStr == null ? scope.row.createdAtStr.substring(0, 10) : scope.row.updatedAtStr.substring(0, 10)}}
          </template>
        </el-table-column>
        <el-table-column label="状态" width="80">
          <template slot-scope="scope">
            {{scope.row.status === 'ENABLE' ? '正常' : '禁用'}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center"  width="260">
          <template slot-scope="scope">
            <el-button class="btn" type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button class="btn" type="text" @click="handleDelete(scope.row)">删除</el-button>
            <el-button class="btn" type="text" @click="handleDisable(scope.row)">{{scope.row.status === 'ENABLE' ? '禁用' : '启用'}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="footer">
      <el-button class="add-component" @click="handleAdd" size="small" type="primary">添加汇流箱</el-button>
      <el-button class="download-component" @click="handleDownload" type="text">下载Excel</el-button>
      <el-button class="import-component" @click="importInverter" type="text">批量维护</el-button>
      <div class="page">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="query.currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="query.pageSize"
            layout="total, sizes, prev, pager, next"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <transition name="el-fade-in-linear">
      <div @click.self="handleCancel" v-show="showShadow" class="shadow">
        <div class="component-detail-dialog">
          <transition name="el-fade-in-linear">
            <ComboboxDetail @commitSuccess="handleCommitSuccess" @cancel="handleCancel" v-if="showEditDialog" :dialogType="dialogType" :comboboxId="comboboxId" :companyList="companyList"></ComboboxDetail>
          </transition>
        </div>
        <div class="component-detail-dialog">
          <transition name="el-fade-in-linear">
<!--            <ComponentBatch v-if="showBatch" @cancel="handleCancel"></ComponentBatch>-->
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ComboboxDetail from './ComboboxDetail'
export default {
  data(){
    return {
      query: {
        companyId: '',
        inverterModule: '',
        status: '',
        pageSize: 10,
        currentPage: 1
      },
      total: 0,
      companyList: [],
      comboboxData: null,
      showBatch: false,
      showShadow: false,
      showEditDialog: false,
      dialogType: '',
      comboboxId: null,
      multipleSelection: []
    }
  },
  mounted(){
    this.loading = true;
    var queryParams = {
        status: 'ENABLE',
        companyType: 'COMBOBOX',
    }
    this.$http.get(this.$apiPath.adminCompanyList, {params: queryParams}).then(res => {
        this.loading = false
        this.companyList = res.data.data.companyList
    })
    this.getTableData(true)
  },
  methods: {
    computeIndex(index) {
      return (this.query.currentPage - 1) * this.query.pageSize + index + 1
    },
    getTableData(reset){
      this.loading = true
      this.$http.get(this.$apiPath.adminComboboxList,{params: this.query}).then(res => {
        var data = res.data
        this.total = data.data.total
        this.comboboxData = data.data.comboboxList
        if(reset){
            this.query.currentPage = 1
        }
        this.loading = false
      });
    },
    companyLabel(item){
      if(item.companyNameCn != '' && item.companyNameCn != null){
        return item.companyNameCn + ' - ' + item.companyNameEn
      }else{
        return item.companyNameEn
      }
    },
    handleSizeChange(value){
      this.query.pageSize = value
      this.getTableData()
    },
    handleCurrentChange(value){
      this.query.currentPage = value
      this.getTableData()
    },
    handleAdd(){
      this.dialogType = 'ADD';
      this.showShadow = true
      this.showEditDialog = true
    },
    handleEdit(row){
      this.comboboxId = row.id;
      this.dialogType = 'EDIT';
      this.showEditDialog = true;
      this.showShadow = true;
    },
    handleDelete(row){
      this.$confirm('此操作将永久删除该汇流箱，是否继续？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(this.$apiPath.adminDeleteCombobox, {
          comboboxId: row.id,
          userId: this.$store.getters.loginUserId
        }).then(res => {
          if(res.status == 200 && res.data.status == 200){
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getTableData();
          }else{
            this.$message.error(res.data.message);
          }

        });

      }).catch(() => {
        //cancel
      });
    },
    handleDisable(row){
      let type = '', typeStr = '';
      if(row.status === 'ENABLE'){
        type = 'DISABLE';
        typeStr = '禁用';
      }else{
        type = 'ENABLE';
        typeStr = '启用';
      }
      this.$confirm('确认要' + typeStr +'当前汇流箱吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(this.$apiPath.adminComboboxStatus, {comboboxId: row.id, type: type}).then(res => {
          if(res.status === 200 && res.data.status === 200){
            this.$message({
              type: 'success',
              message: typeStr + '成功!'
            });
            this.getTableData();
          }
        });
      }).catch(() => {
        //cancel
      });
    },
    handleDownload(){
      var _this = this
      this.$message({
        message: '即将开始下载',
        duration: 2000,
        onClose: function(){
          _this.$http({
            method: 'post',
            url: _this.$apiPath.adminDownloadInverter,
            data: {ids: _this.multipleSelection},
            responseType: 'blob'
          }).then(res => {
            var blob = new Blob([res.data], {type: 'application/force-download'})
            var aTag = document.createElement('a')
            aTag.download = 'Combobox.xlsx'// 下载的文件名
            aTag.href = URL.createObjectURL(blob)
            aTag.click()
            URL.revokeObjectURL(blob)
          });
        }
      });
    },
    importInverter(){

    },
    handleCancel() {
      this.showEditDialog = false;
      this.showShadow = false;
      this.showBatch = false
    },
    handleCommitSuccess() {
      this.showEditDialog = false;
      this.showShadow = false;
      this.getTableData();
    },
    handleSelectionChange(val) {
      var tmpArr = []
      val.forEach(item => {
        tmpArr.push(item.id)
      })
      this.multipleSelection = tmpArr.join(',');
    }
  },
  components: {
    ComboboxDetail
  }
}
</script>

<style scoped lang="scss">
.search-area{
    height: 32px;
    padding: 10px 14px;
    border-bottom: solid 1px #e8e8e8;
}
.search-text{
    width: 285px;
    height: 32px;
    float: left;
}
.search-company, .search-component-type, .search-component-status{
    margin-left: 8px;
    float: left;
}
.search-company{
    width: 180px;
}
.search-component-type{
    width: 135px;
}
.search-component-status{
    width: 112px;
}
.table{
    height: calc(100vh - 270px) !important;
}
.footer{
    .add-component{
        font-size: 14px;
        line-height: 14px;
        height: 30px;
        padding: 7px 15px;
        background-color: #5C8EFF;
        margin: 14px 0 0 10px;
    }

    .download-component, .import-component{
        color: #5C8EFF;
        height: 30px;
        line-height: 14px;
        padding: 7px 0;
        margin-left: 20px;
        text-decoration: underline;
    }

    .page{
        float: right;
        margin: 11px 6px 0 0;
    }
}
.component-detail-dialog{
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -300px 0 0 -281px;
}
</style>
