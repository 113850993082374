

import { h, VNode } from 'snabbdom'
import { DomEditor, IDomEditor, SlateElement } from '@wangeditor/editor'
// import { MentionElement } from './custom-types'

function renderMention (elem, children, editor) {
  // 当前节点是否选中
  const selected = DomEditor.isNodeSelected(editor, elem)
  const { value = '' } = elem 

  // 构建 vnode
  const vnode = h(
    'span',
    {
      props: {
        contentEditable: false // 不可编辑
      },
      style: {
        marginLeft: '3px',
        marginRight: '3px',
        color: 'rgb(56,92,223)',
        background: selected // 选中/不选中，样式不一样
          ? '#eee' // wangEditor 提供了 css var https://www.wangeditor.com/v5/theme.html
          : '',
        borderRadius: '3px',
      }
    },
    `${value}` // 如 `@张三`
  )

  return vnode
}

const conf = {
  type: 'mention', // 节点 type ，重要！！！
  renderElem: renderMention
}

export default conf
