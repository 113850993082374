<template>
    <div class="component-detail" v-loading="loading">
        <CloseHeader :title="getDialogType()" @cancel="cancel"></CloseHeader>
        <div class="container">
            <el-form size="small" :rules="rules" :label-position="labelPosition" class="component-detail-form" ref="dialogForm" :model="dialogForm" label-width="115px">
                <el-form-item class="form-label" label="厂商名称" prop="companyId">
                    <el-select clearable filterable v-model="dialogForm.companyId" placeholder="厂商名称">
                        <el-option v-for="item in companyList" :key="item.id" :label="item.companyNameEn" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="form-label" label="组件型号" prop="moduleModel">
                    <el-input v-model="dialogForm.moduleModel" clearable placeholder="组件型号"></el-input>
                </el-form-item>
                <el-form-item class="form-label short" label="组件类型" prop="moduleType">
                    <el-select clearable v-model="dialogForm.moduleType" placeholder="组件类型">
                        <el-option v-for="item in componentTypeList" :key="item.id" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="form-label" label="是否双面组件">
                    <el-radio-group v-model="dialogForm.bool_bifacialpv">
                        <el-radio label="true">是</el-radio>
                        <el-radio label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item class="form-label" label="双面组件参数" prop="bifaciality_factor">
                    <el-input v-model="dialogForm.bifaciality_factor" clearable placeholder="双面组件参数"></el-input>
                </el-form-item>
                <el-form-item class="form-label" label="是否建筑一体化组件">
                    <el-radio-group v-model="dialogForm.bool_bipv">
                        <el-radio label="true">是</el-radio>
                        <el-radio label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item class="form-label" label="标准测试条件下的输出功率" prop="p_stc">
                    <el-input v-model="dialogForm.p_stc" clearable placeholder="标准测试条件下的输出功率">
                        <template slot="append">W</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="组件面积" prop="a_c">
                    <el-input v-model="dialogForm.a_c" clearable placeholder="组件面积">
                        <template slot="append">㎡</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="组件长度" prop="l_c">
                    <el-input v-model="dialogForm.l_c" clearable placeholder="组件长度">
                        <template slot="append">m</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="组件厚度" prop="depth">
                    <el-input v-model="dialogForm.depth" clearable placeholder="组件厚度">
                        <template slot="append">m</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="组件宽度" prop="w_c">
                    <el-input v-model="dialogForm.w_c" clearable placeholder="组件宽度">
                        <template slot="append">m</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="串联电池片数" prop="n_s">
                    <el-input v-model="dialogForm.n_s" clearable placeholder="串联电池片数">
                        <template slot="append">片</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="并联电池片数" prop="n_p">
                    <el-input v-model="dialogForm.n_p" clearable placeholder="并联电池片数">
                        <template slot="append">片</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="最大耐受电压" prop="vmax">
                    <el-input v-model="dialogForm.vmax" clearable placeholder="最大耐受电压">
                        <template slot="append">V</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="参考条件下的短路电流" prop="i_sc_ref">
                    <el-input v-model="dialogForm.i_sc_ref" clearable placeholder="参考条件下的短路电流">
                        <template slot="append">A</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="参考条件下的开路电压" prop="v_oc_ref">
                    <el-input v-model="dialogForm.v_oc_ref" clearable placeholder="参考条件下的开路电压">
                        <template slot="append">V</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="参考条件下MPPT电流" prop="i_mp_ref">
                    <el-input v-model="dialogForm.i_mp_ref" clearable placeholder="参考条件下MPPT电流">
                        <template slot="append">A</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="参考条件下MPPT电压" prop="v_mp_ref">
                    <el-input v-model="dialogForm.v_mp_ref" clearable placeholder="参考条件下MPPT电压">
                        <template slot="append">V</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="短路电流温度因子" prop="alpha_sc">
                    <el-input v-model="dialogForm.alpha_sc" clearable placeholder="短路电流温度因子">
                        <template slot="append">A/K</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="开路电压温度因子" prop="beta_oc">
                    <el-input v-model="dialogForm.beta_oc" clearable placeholder="开路电压温度因子">
                        <template slot="append">V/K</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="额定条件下的工作温度" prop="t_noct">
                    <el-input v-model="dialogForm.t_noct" clearable placeholder="额定条件下的工作温度">
                        <template slot="append">℃</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="参考条件下的曲线拟合因子" prop="a_ref">
                    <el-input v-model="dialogForm.a_ref" clearable placeholder="参考条件下的曲线拟合因子">
                        <template slot="append">V</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="参考条件下的光生电流" prop="i_l_ref">
                    <el-input v-model="dialogForm.i_l_ref" clearable placeholder="参考条件下的光生电流">
                        <template slot="append">A</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="参考条件下的反向饱和电流" prop="i_o_ref">
                    <el-input v-model="dialogForm.i_o_ref" clearable placeholder="参考条件下的反向饱和电流">
                        <template slot="append">A</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="参考条件下的串联电阻" prop="r_s">
                    <el-input v-model="dialogForm.r_s" clearable placeholder="参考条件下的串联电阻">
                        <template slot="append">Ω</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="参考条件下的并联电阻" prop="r_sh_ref">
                    <el-input v-model="dialogForm.r_sh_ref" clearable placeholder="参考条件下的并联电阻">
                        <template slot="append">Ω</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="功率温度系数" prop="gamma_r">
                    <el-input v-model="dialogForm.gamma_r" clearable placeholder="功率温度系数">
                        <template slot="append">%/K</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="衰减年限" prop="num_year_decay">
                    <el-input v-model="dialogForm.num_year_decay" clearable placeholder="衰减年限">
                        <template slot="append">年</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="衰减比例" prop="perc_decay">
                    <el-input v-model="dialogForm.perc_decay" clearable placeholder="衰减比例（20年）">
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="二极管数目" prop="by_pass_num">
                    <el-input v-model="dialogForm.by_pass_num" clearable placeholder="二极管数目">
                    </el-input>
                </el-form-item>
                <el-form-item class="form-label" label="产品出厂日期">
                    <el-date-picker value-format="timestamp" type="date" placeholder="选择日期" v-model="dialogForm.productionDate" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item class="form-label short" label="进入市场年份" prop="marketIn">
                    <el-select clearable v-model="dialogForm.marketIn" placeholder="进入市场年份">
                        <el-option v-for="item in marketInTypeList" :key="item.id" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="form-label short" label="退出市场年份" prop="marketOut">
                    <el-select clearable v-model="dialogForm.marketOut" placeholder="退出市场年份">
                        <el-option v-for="item in marketOutTypeList" :key="item.id" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="footer-part">
            <el-button :loading="loading" class="btn" type="primary" @click="commit">确定</el-button>
            <el-button class="btn" @click="cancel">取消</el-button>
        </div>
    </div>
</template>

<script>
import CloseHeader from './CloseHeader'
export default {
    props: {
        dialogType:{
            type: String,
            required: true
        },
        componentId:{
            type: Number,
            required: false
        },
        companyList: {
            type: Array,
            required: true
        }
    },
    data(){
        var validateFloat = (rule, value, callback) => {
            if(value && value != ''){
                // 浮点
                var padden = /^([+-])?\d+(\.\d+)?$/g
                // 科学计数法
                var paddenEx = /^[+-]?[\d]+([\.][\d]+)?([Ee][+-]?[\d]+)?$/
                if(padden.test(value) || paddenEx.test(value)){
                    callback();
                }
                callback(new Error('格式不正确'));
            }
            callback();
        };
        var validatePercentage = (rule, value, callback) => {
            if(value != ''){
                var padden = /^\d+(\.\d+)?$/g
                if(padden.test(value)){
                    if(value >= 0 && value <= 100){
                        callback();
                    }
                    callback(new Error('需在0-100之间'));
                }
                callback(new Error('格式不正确'));
            }
            callback();
        };
        return {
            loading: false,
            labelPosition: 'left',
            componentTypeList: [],
            marketInTypeList: [],
            marketOutTypeList: [],
            dialogForm: {
                by_pass_num: '',
                vmax: '',
                marketIn: '',
                marketOut: '',
                bifaciality_factor: '',
                depth: '',
                companyId: null,
                moduleModel: '',
                moduleType: '',
                bool_bifacialpv: '',
                bool_bipv: '',
                p_stc: '',
                p_ptc: '',
                a_c: '',
                l_c: '',
                w_c: '',
                n_s: '',
                n_p: '',
                i_sc_ref: '',
                v_oc_ref: '',
                i_mp_ref: '',
                v_mp_ref: '',
                alpha_sc: '',
                beta_oc: '',
                t_noct: '',
                a_ref: '',
                i_l_ref: '',
                i_o_ref: '',
                r_s: '',
                r_sh_ref: '',
                gamma_r: '',
                num_year_decay: '',
                perc_decay: '',
                productionDate: '',
                createdBy: this.$store.getters.loginUserId,
            },
            rules: {
                companyId: [
                    { required: true, message: '请选择组件厂商', trigger: ['blur', 'change']}
                ],
                moduleModel: [
                    { required: true, message: '请输入组件型号', trigger: 'blur' }
                ],
                moduleType: [
                    { required: true, message: '请选择组件类型', trigger: ['blur', 'change'] }
                ],
                p_stc: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                p_ptc: [
                    { validator: validateFloat, trigger: 'blur' },
                ],
                a_c: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                l_c: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                w_c: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                n_s: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                i_sc_ref: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                v_oc_ref: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                i_mp_ref: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                v_mp_ref: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                alpha_sc: [
                    { required: true, message: '必填', trigger: 'blur' },
                ],
                beta_oc: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                t_noct: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                a_ref: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                i_l_ref: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                i_o_ref: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                r_s: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                r_sh_ref: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                gamma_r: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validateFloat, trigger: 'blur' },
                ],
                num_year_decay: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { pattern: /^[1-9]+[0-9]*$/, message: '格式不正确', trigger: 'blur'}
                ],
                perc_decay: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { validator: validatePercentage, trigger: 'blur' },
                ],
                marketIn: [
                    { required: true, message: '必填', trigger: 'blur' },
                ],
            }
        }
    },
    mounted(){
        if(this.componentId != null){
            this.loading = true;
            this.$http.get(this.$apiPath.adminComponentInfo, {params: {componentId: this.componentId}}).then(res => {
                var data = res.data;
                if(data.status !== 200){
                    this.loading = false;
                    this.$message.error(data.message);
                    return;
                }
                this.dialogForm = data.data;
                this.dialogForm.bool_bifacialpv = data.data.bool_bifacialpv + ''
                this.dialogForm.bool_bipv = data.data.bool_bipv + ''
                this.loading = false;
            });
        }

        this.$http.get(this.$apiPath.clientDictList, {params: {dictType: 'componentType'}}).then(res => {
            let data = res.data
            if(data.status !== 200){
                this.loading = false
                this.$message.error(data.message)
                return
            }
            this.componentTypeList = data.data
        })
        this.$http.get(this.$apiPath.clientDictList, {params: {dictType: 'marketInType'}}).then(res => {
            let data = res.data
            if(data.status !== 200){
                this.loading = false
                this.$message.error(data.message)
                return
            }
            this.marketInTypeList = data.data
        })
        this.$http.get(this.$apiPath.clientDictList, {params: {dictType: 'marketInType'}}).then(res => {
            let data = res.data
            if(data.status !== 200){
                this.loading = false
                this.$message.error(data.message)
                return
            }
            this.marketOutTypeList = data.data
        })
    },
    methods:{
        getDialogType(){
            return this.dialogType === 'ADD' ? '添加组件' : '修改组件';
        },
        cancel(){
            this.$emit('cancel');
        },
        commit(){
            this.$refs['dialogForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    if(this.dialogType == 'ADD'){
                        this.$http.post(this.$apiPath.adminAddComponent, this.dialogForm).then(res => {
                            var data = res.data;
                            if(data.status != 200){
                                this.$message.error(data.message);
                            }else{
                                this.$message.success('添加组件成功');
                                this.$emit('commitSuccess');
                            }
                            this.loading = false;
                        });
                    }else if(this.dialogType == 'EDIT'){
                        this.$http.post(this.$apiPath.adminUpdateComponent, this.dialogForm).then(res => {
                            var data = res.data;
                            if(data.status != 200){
                                this.$message.error(data.message);
                            }else{
                                this.$message.success('修改组件成功');
                                this.$emit('commitSuccess');
                            }
                            this.loading = false;
                        });
                    }
                } else {
                    return false;
                }
            });
        }
    },
    components:{
        CloseHeader
    }
}
</script>


<style lang="scss" scoped>
.component-detail{
    width: 560px;
    height: 596px;
    background-color: #ffffff;

    .container{
        width: 100%;
        height: 473px;
        margin-top: 30px;
        overflow-x: hidden;
        // overflow-y: scroll;
    }

    .component-detail-form{
        margin-left: 30px;
        width: 100%;
    }

    .footer-part{
        position: absolute;
        height: 54px;
        width: 100%;
        border-top: 1px solid #EEE;
        bottom: 0;
        left: 0;

        .btn{
            height: 32px;
            width: 80px;
            line-height: 30px;
            font-size: 14px;
            padding: 0 20px;
            margin: 10px 12px 0 0;
            float: right;

            span{
                display: block;
                height: 30px;
                line-height: 30px;
            }
        }
    }

    .form-label, .form-label-remark{
         label{
            color: #000 !important;
            position: relative;
        }
    }

    .form-label::v-deep .el-input-group__append{
        min-width: 25px;
        text-align: center;
    }
}
</style>
