<template>
    <div id="user" class="user">
        <div class="title">
            {{ title }}
        </div>
        <div class="container" :v-loading="loading">
            <div class="query-header">
                <el-input placeholder="姓名/手机号/工程ID" @change="getTableData(true)" clearable suffix-icon="el-icon-search"
                    size="small" class="query-input" v-model="queryData.keyword"></el-input>
                <el-select v-model="queryData.license" @change="getTableData(true)" size="small" clearable
                    placeholder="可用系统" class="account-type">
                    <el-option v-for="item in isTops" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="queryData.status" @change="getTableData(true)" size="small" clearable
                    placeholder="用户状态" class="account-type">
                    <el-option v-for="item in isTops" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <el-table stripe :data="tableData" class="table" height="100" style="width: 100%">
                <el-table-column type="selection" min-width="55">
                </el-table-column>
                <el-table-column label="#" type="index" width="50">
                </el-table-column>
                <el-table-column prop="projectId" label="工程ID" align="left" min-width="80">
                </el-table-column>
                <el-table-column prop="projectName" label="工程名称" align="left">
                </el-table-column>
                <el-table-column prop="name" label="姓名" align="left">
                </el-table-column>
                <el-table-column prop="username" label="账户" align="left">
                </el-table-column>
                <el-table-column prop="companyName" label="单位" align="left">
                </el-table-column>
                <el-table-column prop="createdAt" label="需求提交时间" align="left" min-width="100">
                </el-table-column>
                <el-table-column prop="uploadTime" label="复现文件上传时间" align="left" min-width="100">
                </el-table-column>
                <el-table-column align="center" label="状态">
                    <template v-slot="scope">
                        {{ scope.row.status === 0 ? '需求提交' : scope.row.status === 1 ? '处理中' : '处理完成' }}
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="remark" label="备注">
                </el-table-column>
                <el-table-column label="操作" align="center" width="120">
                    <template v-slot="scope">
                        <el-button class="btn" type="text" @click="handleDemandProcessing(scope.row)">需求处理</el-button>
                        <!-- <el-button class="btn" type="text" @click="showDrawer('edit', scope.row)">编辑</el-button>
                        <el-button class="btn" type="text" @click="handleDelete(scope.row)">删除</el-button>
                        <el-button class="btn" type="text" @click="handleDisable(scope.row)">
                            {{ scope.row.status === 'ENABLE' ? '禁用' : '启用' }}
                        </el-button>
                        <el-button class="btn" type="text" @click="changeTop(scope.row)">
                            {{ scope.row.placingTop ? '取消置顶' : '置顶' }}
                        </el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <div class="footer">
                <!-- <el-button class="add-user" @click="showDrawer('add', '')" size="small" type="primary">添加新闻</el-button> -->
                <div class="page">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="queryData.currentPage" :page-sizes="[10, 20, 50, 100]"
                        :page-size="queryData.pageSize" layout="total, sizes, prev, pager, next" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>


        <el-dialog :visible.sync="dialogVisible" title="需求处理" width="1130px" top="5vh">
            <div class="reproduction-body-moduler">
                <div class="body-box-moduler">
                    <div class="body-detail-moduler">
                        <div class="body-step-moudler">
                            <div class="step-content-moduler">

                                <div class="reproduction-3d-content">
                                    <canvas id="imgCanvas" ref="canvaxbox"></canvas>
                                    <canvas id="saveCanvas" ref="canvasSave"></canvas>
                                </div>
                            </div>
                            <div class="step-content-moduler">
                                <div class="content-top-moduler">
                                    <div class="drone-file-download">
                                        <div class="text">无人机文件</div>
                                        <span class="download-btn">点此下载</span>
                                    </div>
                                    <div class="project-info">
                                        <div class="info-detail">
                                            <div class="label">工程ID</div>
                                            <div class="info">{{ droneProjectInfo.projectId }}</div>
                                        </div>
                                        <div class="info-detail">
                                            <div class="label">联系电话</div>
                                            <div class="info">{{ droneProjectInfo.phone }}</div>
                                        </div>
                                    </div>
                                    <div class="upload-file-label">3D工程上传</div>
                                    <div class="upload-file-moduler">
                                        <div class="select-file-moduler">
                                            <div class="select-boder"></div>
                                            <div class="btn select-btn">选择</div>
                                        </div>
                                        <div class="btn upload-btn" @click="uploadFile">上传</div>
                                    </div>
                                    <!-- <div class="file-upload-schedule" v-if="uploadShow">
                                        <div class="tip">文件上传中，请稍后！</div>
                                        <el-progress :percentage="percentage"></el-progress>
                                    </div> -->
                                    <div class="file-upload-schedule" v-if="uploadSuccessShow">
                                        <div class="tip">上传完成，请及时通知客户！</div>
                                        <div class="file-name">XXXXX.zip <span class="delete-text">删除</span></div>
                                    </div>
                                </div>
                                <div class="content-bottom-moduler">
                                    <div class="detail-title">无人机区域额度扣减</div>
                                    <div class="zone-credit-deduction-info">
                                        <div class="deduction-text">
                                            无人机区域剩余额度：<span class="deduction-value">90,000m²</span>
                                        </div>
                                        <div class="credit-info-moduler">
                                            扣减<el-input v-model="input"></el-input><span class="unit">m²</span>
                                            <span>扣减后剩余【90000m²】</span>
                                        </div>
                                        <div class="confirm-credit-moduler">
                                            <div class="credit-btn">确定扣减</div>
                                            <div class="credit-record">扣减履历</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="remark-moduler">
                            <div class="label">备注</div>
                            <el-input v-model="droneProjectInfo.remark" placeholder="请输入内容"></el-input>
                            <div class="save-remark-btn">保存备注</div>
                        </div>
                    </div>
                    <!-- <div class="footer-moduler">
                        <el-popconfirm title="确认关闭？" hide-icon @confirm="cancel">
                            <div class="btn cancel-btn" slot="reference">关闭</div>
                        </el-popconfirm>
                    </div> -->
                </div>
            </div>
            <div class="dialog-footer">
                <div class="cancel-btn" @click="dialogVisible = false">关闭</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
/* eslint-disable */
// import Tinymce from '../../components/Tinymce/index'
// import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
    // components: { Editor, Toolbar },
    data() {
        return {
            loading: false,
            queryData: {
                keyword: '',
                license: '',
                status: null,
                currentPage: 1,
                pageSize: 10,
            },
            dialogVisible: false,
            // uploadUrl: this.$apiPath.adminNewsImgUpload,
            isTops: [{ value: 0, label: '否' }, { value: 1, label: '是' },],
            tableData: [],
            total: 0,
            drawer: false,
            dialogType: '',
            userId: null,
            drawerTitle: '',
            content: '',
            urlContent: '',
            dataForm: {
                id: 0,
                title: '',
                img: "",
                content: "",
                createTime: "",
                updateTime: '',
                placingTop: false,
                type: 'CONTENT',
                intro: '',
            },
            rules: {
                title: [{ required: true, message: '此项必填', trigger: 'blur' },],
                createTime: [{ required: true, message: '此项必填', trigger: 'change' },],
            },
            btnLoading: false,
            insertedImg: [],
            dialogLoading: false,
            uploadShow: false,
            uploadSuccessShow: false,
            input: '',
            isDrawing: true,
            imgCanvas: null,
            imgCtx: null,
            saveCanvas: null,
            saveCtx: null,
            submitData: [
                { "polygon": { "x1": 38, "y1": 113, "x2": 65, "y2": 217, "x3": 226, "y3": 170, "x4": 197, "y4": 71 } },
            ],
            imgUrl: "",
            ratio: 1,
            imgWidth: 515,
            imgHeight: 515,
            wrapWidth: 515,
            wrapHeight: 515,
            canvasWidth: 515,
            canvasHeight: 515,
            droneProjectInfo: {
                projectId: "285487",
                phone: '13900989876',
                remark: "",
            },

        }
    },
    computed: {
        title: function () {
            return '无人机复现工程'
        }
    },
    mounted() {
        let _that = this
        this.getTableData()
        this.editorConfig.MENU_CONF['uploadImage'] = {
            server: this.$apiPath.adminNewsImgUpload,
            // 小于该值就插入 base64 格式（而不上传），默认为 0
            base64LimitSize: 5 * 1024, // 5kb
            async customUpload(file, insertFn) {
                let formData = new FormData()
                formData.append('file', file)
                let ret = await _that.$http.post(_that.$apiPath.adminNewsImgUpload, formData)
                if (ret.status === 200 && ret.data.errno === 0) {
                    insertFn(ret.data.data.url, '', '')
                }
            }
        }
        this.editorConfig.MENU_CONF['insertImage'] = {
            onInsertedImage(imageNode) {
                if (imageNode == null) return
                const { src } = imageNode
                _that.insertedImg.push(src)
                console.log('inserted image', src)
            }
        }
        this.editorConfig.MENU_CONF['uploadVideo'] = {
            server: this.$apiPath.adminNewsVideoUpload,
            async customUpload(file, insertFn) {
                let formData = new FormData()
                formData.append('file', file)
                let ret = await _that.$http.post(_that.$apiPath.adminNewsVideoUpload, formData)
                if (ret.status === 200 && ret.data.errno === 0) {
                    insertFn(ret.data.data.url, '', '')
                }
            }
        }
        this.editorConfig.MENU_CONF['insertVideo'] = {
            onInsertedVideo(videoNode) {
                if (videoNode == null) return

                const { src } = videoNode
                console.log('inserted video', src)
            }
        }




    },
    methods: {
        imgUploadSuccess(res, file, fileLists) {
            this.dataForm.img = res.data.url
            console.log(res, file, fileLists)
        },
        onEditorCreated(editor) {
            this.editor = Object.seal(editor)
        },
        handleRemove(file, fileList) {
            this.dataForm.img = ''
        },
        /**
         * 需求处理 弹窗
         * @param item 
         */
        handleDemandProcessing(item) {
            this.dialogVisible = true
            this.droneProjectInfo.projectId = item.projectId
            this.droneProjectInfo.phone = item.phone
            this.droneProjectInfo.remark = item.remark
            this.$nextTick(() => {
                this.initCanvas();
                this.getImage();
                this.drawSaveArea();
            });
        },
        getNewsDetail(id) {
            this.$http.get(this.$apiPath.adminNews + `/${id}`).then(res => {
                this.dialogLoading = false
                if (res.data.status === 200) {
                    this.dataForm = {
                        ...res.data.data
                    }
                    this.dataForm.content = ''
                    if (res.data.data.type === 'URL') {
                        this.urlContent = res.data.data.content
                    } else {
                        this.content = res.data.data.content
                    }
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        handleClose() {
            this.$refs.dataForm.clearValidate()
            this.drawer = false;
            if (this.insertedImg.length > 0) {
                this.$http.delete(this.$apiPath.adminNewsResDelete, {
                    data: { objects: this.insertedImg }
                }).then(res => {
                    console.log(res)
                })
            }
            this.insertedImg = []
            this.dataForm.content = ''
            this.content = ''
            this.urlContent = ''
        },

        handleConfirm() {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    let data = {
                        ...this.dataForm
                    }
                    if (data.type === 'CONTENT') {
                        data.content = this.content
                    } else {
                        data.content = this.urlContent
                    }
                    if (this.dialogType === 'add') {
                        this.$http.post(this.$apiPath.adminNews, data).then((res) => {
                            this.$message({
                                type: 'success',
                                message: '操作成功!'
                            });
                            this.btnLoading = false;
                            this.insertedImg = []
                            this.handleClose()
                            this.getTableData(true)
                        }).catch(() => {
                            this.btnLoading = false;
                        })
                    } else {
                        this.$http.put(this.$apiPath.adminNews, data).then((res) => {
                            this.$message({
                                type: 'success',
                                message: '操作成功!'
                            });
                            this.btnLoading = false;
                            this.insertedImg = []
                            this.handleClose()
                            this.getTableData(true)
                        }).catch(() => {
                            this.btnLoading = false;
                        })
                    }
                }
            })
        },


        handleEdit(row) {
            this.userId = row.id;
            this.dialogType = 'EDIT';
            this.showEditDialog = true;
            this.showShadow = true;
        },
        handleDelete(row) {
            this.$confirm('确定要删除本新闻吗？', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.delete(this.$apiPath.adminNews + `/${row.id}`).then(res => {
                    if (res.status === 200 && res.data.status === 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getTableData();
                    }
                })
            }).catch(() => {
                //cancel
            });
        },
        handleDisable(row) {
            let data = {
                ...row
            }
            if (data.status === 'ENABLE') {
                data.status = 'DISABLE'
            } else {
                data.status = 'ENABLE'
            }
            this.toggleNewsStatusOrTop(data)
        },

        changeTop(row) {
            let data = {
                ...row
            }
            data.placingTop = !data.placingTop
            this.toggleNewsStatusOrTop(data)
        },
        toggleNewsStatusOrTop(data) {
            this.$http.patch(this.$apiPath.adminNews, data).then(res => {
                if (res.status === 200 && res.data.status === 200) {
                    this.$message.success('修改成功')
                    this.getTableData()
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },

        handleSizeChange(size) {
            this.queryData.pageSize = size;
            this.getTableData()
        },
        handleCurrentChange(currentPage) {
            this.queryData.currentPage = currentPage;
            this.getTableData()
        },
        getTableData(reset) {
            this.loading = true;
            if (reset) {
                this.queryData.currentPage = 1;
            }
            this.$http.get(this.$apiPath.getDroneProjectList, { params: this.queryData }).then(res => {
                console.log("getDroneProjectList", res);
                let data = res.data;
                this.total = data.data.total;
                this.tableData = data.data.list;
                // this.tableData = [
                //     {
                //         projectId: '554877',
                //         projectName: 'xxxxx',
                //         name: 'XX',
                //         account: '13900989876',
                //         unit: '常州天合光能',
                //         submitDate: '2024-05-25 14:47',
                //         uploadDate: '2024-05-27 14:47',
                //         status: 1,
                //         remark: "",
                //     },
                //     {
                //         status: 2,
                //     },
                //     {
                //         status: 3,
                //     },
                // ]
                this.loading = false;
            });
        },

        /**
         * 上传
         */
        uploadFile() {
            this.uploadShow = true
            setTimeout(() => {
                this.uploadSuccessShow = true
            }, 2000)
        },
        /**
         * canvas
         * 展示框选的屋面
         */
        initCanvas() {
            this.imgCanvas = document.getElementById('imgCanvas')
            this.imgCtx = this.imgCanvas.getContext('2d')

            this.saveCanvas = document.getElementById("saveCanvas")
            this.saveCtx = this.saveCanvas.getContext('2d')
        },
        getImage() {
            // 初始化画布大小
            this.imgCanvas.width = 515;
            this.imgCanvas.height = 515;
            // 图片加载绘制
            this.imgUrl = "https://file.imgcc.cloud/images/2024/07/04/7403afb35459d7102a0ea732aff0ac8e.jpeg"
            this.imgUrl && this.initImgCanvas()

            // this.drawSaveArea();
        },
        initImgCanvas() {
            // 计算宽高比
            let ww = this.wrapWidth; // 画布宽度
            let wh = this.wrapHeight; // 画布高度
            let iw = this.imgWidth; // 图片宽度
            let ih = this.imgHeight; // 图片高度

            if (iw / ih < ww / wh) {
                // 以高为主
                this.ratio = ih / wh;
                this.canvasHeight = wh;
                this.canvasWidth = (wh * iw) / ih;
            } else {
                // 以宽为主
                this.ratio = iw / ww;
                this.canvasWidth = ww;
                this.canvasHeight = (ww * ih) / iw;
            }
            this.imgCanvas.width = this.canvasWidth;
            this.imgCanvas.height = this.canvasHeight;
            this.saveCanvas.width = this.canvasWidth;
            this.saveCanvas.height = this.canvasHeight;

            let img = document.createElement('img')
            img.src = this.imgUrl
            console.log("img----", img);
            img.onload = () => {
                console.log("图片已加载");
                this.imgCtx.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight)
            }
        },
        /**
         * 绘制保存的区域
         */
        drawSaveArea() {
            if (this.submitData.length == 0) return
            console.log("this.submitData", this.submitData);
            this.submitData.map((item) => {
                let polygon = item.polygon
                let points = []
                // console.log(Object.keys(polygon).length);
                for (let i = 1; i < Object.keys(polygon).length / 2 + 1; i++) {
                    if (!isNaN(polygon[`x${i}`]) && !isNaN(polygon[`y${i}`])) {
                        points.push([polygon[`x${i}`], polygon[`y${i}`]])
                    }
                    console.log(polygon[`x${i}`] / this.ratio, polygon[`y${i}`] / this.ratio);
                }
                console.log("points----", points);

                points.forEach((item, i) => {
                    this.saveCtx.lineTo(...item)
                })
                this.saveCtx.closePath()
                this.saveCtx.lineWidth = '2'
                this.saveCtx.fillStyle = "rgba(255,0, 255, 0.17)"
                this.saveCtx.strokeStyle = "#FF0000"
                this.saveCtx.stroke();
                this.saveCtx.fill(); //填充
                this.saveCtx.restore();

            })

        }

    },

}
</script>
<!-- <style src="@wangeditor/editor/dist/css/style.css"></style> -->
<style lang="scss" scoped>
.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before {
    content: '';
    display: none;
}

.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:after,
.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:after {
    content: '*';
    color: #F56C6C;
    margin-left: 4px;
}

.user {

    position: relative;

    .title {
        padding: 10px 20px;
        font-size: 16px;
        height: 26px;
        line-height: 26px;
        color: #404040;
        font-weight: bold;
    }

    .container {
        margin-left: 10px;
        height: calc(100% - 56px);
        background-color: #ffffff;

        .query-header {
            padding: 12px 15px;
            border-bottom: 1px solid #ddd;

            .query-input {
                width: 285px;
            }

            .account-type {
                margin-left: 8px;
                width: 180px;
            }
        }

        .footer {
            .add-user {
                font-size: 14px;
                line-height: 14px;
                height: 30px;
                padding: 7px 15px;
                background-color: #5C8EFF;
                margin: 14px 0 0 10px;
            }

            .import-user {
                color: #5C8EFF;
                height: 30px;
                line-height: 14px;
                padding: 7px 0;
                margin-left: 20px;
                text-decoration: underline;
            }

            .page {
                float: right;
                margin: 11px 6px 0 0;
            }
        }

    }

    .user-detail-dialog {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -300px 0 0 -281px;
    }

    .user-login-info-dialog {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -265px 0 0 -375px;
    }

    .import-user-dialog {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -161px 0 0 -280px;
    }
}

.shadow {
    position: absolute;
    top: -56px;
    left: -200px;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
}

.btn {
    color: #3F51B5;
}

.avatar-uploader {
    width: 120px;
    height: 120px;
    background: #fafafa;
    border: 1px dashed #eee;
    display: inline-block;
    vertical-align: bottom;
}

.ps {
    padding-left: 10px;
    color: #cccccc;
    vertical-align: bottom;
    font-size: 12px;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}

.table {
    height: calc(100vh - 225px) !important;
}

.el-dialog__body {
    padding: 0;
    padding-top: 20px;
}

.reproduction-body-moduler {
    // width: 100%;
    // padding: 0 100px;
    // height: 795px;

    .body-box-moduler {
        // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.13);
        // border: 1px solid rgba(187, 187, 187, 0.27);
        // border-radius: 8px;
        // width: 1179px;
        box-sizing: border-box;

        .body-detail-moduler {
            // padding: 25px 20px;

            .header-title {
                font-size: 22px;
                line-height: 1;
                margin-bottom: 40px;
                font-family: Roboto-regular;
            }

            .body-step-moudler {
                display: flex;
                justify-content: space-between;

                .step-content-moduler {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .title {
                        font-size: 14px;
                        line-height: 1;

                        .step-text {
                            font-weight: bold;
                        }

                        .view-btn {
                            // line-height: 1;
                            color: rgba(95, 83, 209, 1);
                            border-bottom: 1px solid rgba(95, 83, 209, 1);
                        }
                    }

                    .reproduction-3d-content {
                        // flex: 1;
                        height: 515px;
                        // height: 100%;
                        background-color: bisque;
                        // margin-top: 20px;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        #imgCanvas,
                        #saveCanvas {
                            background: rgba(255, 0, 255, 0);
                            position: absolute;
                            // top: 50%;
                            // left: 50%;
                            // transform: translate(-50%, -50%);
                            width: 515px;
                            height: 515px;
                        }

                        #saveCanvas {
                            z-index: 2;
                        }
                    }

                    .drone-detail {
                        border-radius: 4px;
                        background-color: rgba(224, 227, 236, 0.54);
                        text-align: center;
                        padding: 16px 0;
                        font-size: 14px;
                        line-height: 1;
                        margin-top: 20px;

                        .limit-text {
                            color: rgba(0, 0, 0, 0.92);
                            font-size: 20px;
                        }
                    }
                }

                .step-content-moduler:first-child {
                    margin-right: 50px;
                }

                .content-top-moduler {
                    color: rgba(16, 16, 16, 1);

                    .drone-file-download {
                        .text {
                            font-size: 16px;
                            margin-bottom: 10px;
                        }

                        .download-btn {
                            color: rgba(14, 50, 136, 1);
                            font-size: 16px;
                            line-height: 1;
                            border-bottom: 1px solid rgba(14, 50, 136, 1);
                        }
                    }

                    .project-info {
                        display: flex;
                        margin-top: 20px;
                        margin-bottom: 20px;

                        .info-detail {
                            width: 50%;

                            .label {
                                font-size: 16px;
                                margin-bottom: 20px
                            }

                            .info {
                                line-height: 1;
                                font-size: 28px;
                            }
                        }
                    }

                    .upload-file-label {
                        font-size: 16px;
                    }

                    .upload-tip-moduler {
                        padding: 10px;
                        border-radius: 4px;
                        background-color: rgba(224, 227, 236, 0.54);
                        margin-top: 20px;

                        .tip-detail-moduler {
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;

                            .tip-icon {
                                margin-left: 3px;
                                margin-right: 3px;
                            }

                            // .el-checkbox__original {
                            //   margin: 0;
                            //   margin-right: 10px;
                            // }

                        }

                        .tip-text {
                            margin-left: 7px;
                            font-size: 14px;
                        }

                    }

                    .upload-file-moduler {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        // margin-top: 20px;
                        margin-top: 15px;

                        .select-file-moduler {
                            margin-right: 20px;
                            display: flex;
                            align-items: center;
                            flex: 1;

                            // flex: 1;
                            .select-boder {
                                flex: 1;
                                // width: 200px;
                                height: 36px;
                                border-radius: 4px 4px 4px 4px;
                                border: 1px solid rgba(0, 0, 0, 0.15);
                                border-right: none;
                                box-sizing: border-box;
                                padding: 0 10px;
                                display: flex;
                                align-items: center;
                            }

                            .select-btn {
                                width: 56px;
                                height: 36px;
                                border-radius: 4px;
                                color: #FFFFFF;
                                background-color: rgba(0, 0, 0, 0.5);
                            }
                        }

                        .btn {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .btn:hover {
                            cursor: pointer;
                        }

                        .upload-btn {
                            width: 87px;
                            height: 36px;
                            border-radius: 4px;
                            background-color: rgba(95, 83, 209, 1);
                            color: #FFFFFF;
                        }
                    }

                    .file-upload-schedule {
                        margin-top: 20px;

                        .tip {
                            font-size: 14px;
                        }

                        .file-name {
                            margin-top: 10px;

                            .delete-text {
                                margin-left: 10px;
                                color: #F02121;
                                border-bottom: 1px solid #F02121;
                            }
                        }
                    }
                }

                .content-bottom-moduler {

                    // margin-top: 20px;
                    .detail-title {
                        font-size: 16px;
                        margin-bottom: 10px;
                        color: rgba(16, 16, 16, 1);
                    }

                    .zone-credit-deduction-info {
                        border-radius: 4px;
                        background-color: rgba(224, 227, 236, 0.54);
                        box-sizing: border-box;
                        padding: 15px;
                        color: rgba(0, 0, 0, 0.92);

                        .deduction-text {
                            font-size: 14px;
                            margin-bottom: 20px;

                            .deduction-value {
                                font-size: 20px;
                                font-weight: 600;
                            }
                        }

                        .credit-info-moduler {
                            font-size: 14px;
                            display: flex;
                            align-items: center;
                            margin-bottom: 20px;

                            .el-input {
                                // width: 136px;
                                flex: 1;
                                margin-left: 15px;
                            }

                            .el-input__inner {
                                background-color: rgba(0, 0, 0, 0.09);
                            }

                            .unit {
                                margin-left: 10px;
                                margin-right: 10px;
                                color: rgba(16, 16, 16, 1);
                                font-size: 20px;
                            }
                        }

                        .confirm-credit-moduler {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .credit-btn {
                                width: 87px;
                                height: 36px;
                                border-radius: 4px;
                                background-color: rgba(0, 0, 0, 0.26);
                                color: #FFFFFF;
                                font-size: 14px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            .credit-record {
                                cursor: pointer;
                                color: rgba(14, 50, 136, 1);
                                font-size: 14px;
                                line-height: 1;
                                border-bottom: 1px solid rgba(14, 50, 136, 1);
                            }
                        }
                    }
                }
            }

            .remark-moduler {
                display: flex;
                align-items: center;
                margin-top: 25px;

                .label {
                    color: rgba(16, 16, 16, 1);
                    font-size: 16px;
                    margin-right: 40px;
                }

                .el-input {
                    flex: 1;

                }

                .save-remark-btn {
                    width: 87px;
                    height: 36px;
                    border-radius: 4px;
                    background-color: rgba(95, 83, 209, 1);
                    color: #FFFFFF;
                    font-size: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .footer-moduler {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 10px;
            box-shadow: 0px -2px 4px 2px rgba(187, 187, 187, 0.14);

            .btn {
                width: 92px;
                height: 36px;
                border-radius: 8px;
                font-size: 14px;

            }
        }

    }
}

.dialog-footer {
    margin-top: 25px;
    border-top: 1px solid rgba(208, 208, 208, 1);
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;

    .cancel-btn {
        width: 80px;
        height: 30px;
        border-radius: 4px;
        background-color: rgba(104, 104, 104, 1);
        border: 1px solid rgba(255, 0, 0, 0);
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
</style>

<style lang="scss">
.el-table {
    color: #404040;

    thead {
        color: #686868 !important;
    }
}

.el-table .caret-wrapper {
    width: 16px !important;
}

.el-table .sort-caret {
    left: 3px !important;
}

.el-input__inner {
    height: 36px !important;
}
</style>