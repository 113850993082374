<script>

export default {
  data() {
    return {
      editPort: false,
      form: {},
      tableData: [],
      portLog: false,
      tablePort: [],
      index: 0,
      description: '',
      portManagement: false,
      tableCall: [],
      pageSize: null,
      currentPage: null,
      total: null,
    }
  },
  methods: {
    shutDown() {
      this.$emit('closelimit')
    },
    changeState(row) {
      console.log(row)
      this.form = {}
      // this.id = row.id
      if (row.state === 0) {
        row.periodUse = 0;
        row.residualCredit = 0;
        row.totalAmount = 0;
        row.useFail = 0;
        row.usedTotal = 0
        this.form = row
        this.editPort = true

      } else if (row.state === 1) {
        this.$confirm('确认禁用当前接口吗？')
            .then(_ => {
              row.state = 0
              row.periodUse = '-';
              row.residualCredit = '-';
              row.totalAmount = '-';
              row.useFail = '-';
              row.usedTotal = '-'
              // this.form = row

            })
            .catch(_ => {
            });


      }
    },
    shutPort() {
      this.editPort = false
    },
    showEditor(item) {
      console.log(item)
      this.form = item
      this.editPort = true
    },
    submitPort() {
      console.log(this.form)
      this.form.state = 1
      this.$http.post(this.$apiPath.editorPort, { ...this.form, totalAmount: Number(this.form.totalAmount) },
      ).then(res => {
        console.log(res)
        this.form = {}
        this.editPort = false
        this.$message({
          type: 'success',
          message: '修改成功'
        })
        this.$store.commit('shutEditor')
        this.$http.get(this.$apiPath.limitManagement) // '/api/data'为后端API地址
            .then(res => {
              console.log('成功', res.data.data);
              this.tableData = res.data.data
              // 处理返回的数据
            })
            .catch(error => {
              console.error('失败', error);
              // 错误处理逻辑
            });
      })


    },
    //查看履历接口
    showPortLog(row) {
      this.portLog = true;
      this.description = row.description
      console.log(row)
      const params = {
        id: row.id,
        pageIndex: '1',
        pageSize: '10'
      }
      this.$http.get(this.$apiPath.checkPort, { params: params }).then(res => {
        console.log('1234', res.data.data)
        this.tablePort = res.data.data.list


      })


    },
    //调用履历
    UsedHistory(row_1) {
      console.log(row_1)
      this.description = row_1.description
      const params = {
        id: row_1.id,
        pageIndex: '1',
        pageSize: '10',
        startTime: this.$store.state.startTime,
        endTime: this.$store.state.endTime,
        apiClientId: row_1.apiClientId
      }
      this.portManagement = true;
      this.$http.get(this.$apiPath.callHistory, { params: params }).then(res => {
        console.log(res.data.data)
        this.tableCall = res.data.data.list

      })

    },
    //失败次数履历
    FailedHistory(row) {
      this.description = row.description
      const params = {
        id: row.id,
        pageIndex: '1',
        pageSize: '10',
        startTime: this.$store.state.startTime,
        endTime: this.$store.state.endTime,
        apiClientId: row.apiClientId,

      }
      this.portManagement = true;
      this.$http.get(this.$apiPath.callHistory, { params: params }).then(res => {
        console.log(res.data.data)
        this.tableCall = res.data.data.list

      })
    },
    //累计已使用调用履历
    totalHistory(row) {
      this.description = row.description
      const params = {
        id: row.id,
        pageIndex: '1',
        pageSize: '10'
      }
      this.portManagement = true;
      this.$http.get(this.$apiPath.callHistory, { params: params }).then(res => {
        console.log(res.data.data)
        this.tableCall = res.data.data.list

      })
    },
    // handleSizeChange(val) {
    //   this.pageSize = val
    //   this.$http.get('/api/admin/saasUser/getApiQuota', {
    //     params:{
    //       id:this.$store.state.userId,
    //       pageSize:this.pageSize,
    //       pageIndex: this.currentPage,
    //     }
    //   }) // '/api/data'为后端API地址
    //       .then(res => {
    //         console.log('成功', res.data.data);
    //         this.tableData = res.data.data
    //         // 处理返回的数据
    //       })
    //       .catch(error => {
    //         console.error('失败', error);
    //         // 错误处理逻辑
    //       });
    // },
    // handleCurrentChange(val) {
    //   console.log(val)
    //   this.currentPage = val
    //   this.$http.get('/api/admin/saasUser/getApiQuota',{params:{
    //       id:this.$store.state.userId,
    //       pageIndex: this.currentPage,
    //       pageSize:this.pageSize
    //     }}) // '/api/data'为后端API地址
    //       .then(res => {
    //         this.users = res.data.data.list
    //         this.currentPage = res.data.data.pageIndex;
    //         this.pageSize = res.data.data.pageSize;
    //         this.total = res.data.data.count
    //         // 处理返回的数据
    //       })
    //       .catch(error => {
    //         console.error('失败', error);
    //         // 错误处理逻辑
    //       });
    // },


    //提交搜索
    submitSearch() {

    }
  },

  mounted() {

  },
  created() {
    this.$http.get('/api/admin/saasUser/getApiQuota', {
      params: {
        id: this.$store.state.userId
      }
    }) // '/api/data'为后端API地址
        .then(res => {
          console.log('成功', res.data.data);
          this.tableData = res.data.data
          // this.currentPage = res.data.data.pageIndex;
          // this.pageSize = res.data.data.pageSize;
          // this.total = res.data.data.count
          // 处理返回的数据
        })
        .catch(error => {
          console.error('失败', error);
          // 错误处理逻辑
        });
  }

}
</script>

<template>
  <div class="limit">
    <div class="limit-head">
      <div class="title">额度管理</div>
      <div class="icon">
        <el-button @click="shutDown" style="border: none" size="medium" icon="el-icon-close" circle></el-button>

      </div>
    </div>
    <el-table
        class="table"
        :data="tableData"
        border
        style="width: 80% "
        height="80%"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center' }"
    >
      <el-table-column
          label="#"
          width="55"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
          width="120"
          label="接口ID">
        <template slot-scope="scope">{{ scope.row.apiUid }}</template>
      </el-table-column>
      <el-table-column
          label="接口名称"
          width="200">
        <template slot-scope="scope">{{ scope.row.description }}</template>
      </el-table-column>
      <el-table-column
          width="100"
          label="是否可用">
        <template slot-scope="scope">{{ scope.row.state == 0 ? "禁用" : "可用" }}</template>
      </el-table-column>
      <el-table-column
          width="160"
          label="期间总额度">
        <template slot-scope="scope">{{ scope.row.totalAmount }}</template>

      </el-table-column>
      <el-table-column
          label="期间已使用"
          width="160"
      >
        <template slot-scope="scope">
          <div class="periodUse">
            <el-button class="btn" @click="UsedHistory(scope.row)" type="text">{{ scope.row.periodUse }}</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="#"
          width="160"
          label="期间剩余额度">
        <template slot-scope="scope">
          <div class="residualCredit" :style="scope.row.residualCredit === 0 ? 'color:red':''">
            {{ scope.row.residualCredit }}
          </div>
        </template>

      </el-table-column>
      <el-table-column
          prop="#"
          width="160"
          label="期间调用失败次数">
        <template slot-scope="scope">
          <div :style="scope.row.useFail === 0 ? '':'color:red' ">
            <el-button class="btn" @click="FailedHistory(scope.row)" :type="scope.row.useFail === 0 ? '':'danger'"
                       type="text">{{ scope.row.useFail }}
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          width="160"
          label="累计已使用">
        <template slot-scope="scope">
          <div class="periodUse">
            <el-button class="btn" @click="totalHistory(scope.row)" type="text">{{ scope.row.usedTotal }}</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          width="300"
          label="操作">
        <template slot-scope="scope">
          <div id="opItems">
            <el-button class="btn" v-if="scope.row.state === 1 ? 'true':'false'" type="text"
                       @click="showEditor(scope.row)">编辑
            </el-button>
            <el-button class="btn" v-if="scope.row.state === 1 ? 'true':'false'" type="text"
                       @click="showPortLog(scope.row)">调整履历
            </el-button>
            <el-button class="btn" @click="changeState(scope.row)" v-if="scope.row.state === 1 ? 'true':'false'"
                       type="text">{{ scope.row.state === 0 ? "启用" : "禁用" }}
            </el-button>

          </div>
        </template>


      </el-table-column>
    </el-table>
    <div class="limitFooter">
      <div>asd</div>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="`${form.apiUid}${form.description}`"
        :visible.sync="editPort"
        :append-to-body="true"
        width="50%">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="期间总额度">
          <el-input type="number" v-model="form.totalAmount" controls-position="right" min="0"></el-input>
        </el-form-item>
        <el-form-item label="期间已使用">
          {{ form.usedTotal }}
        </el-form-item>
        <el-form-item label="期间剩余额度">
          {{ form.residualCredit }}
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="shutPort">取消</el-button>
        <el-button @click="submitPort" type="primary">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        :append-to-body="true"
        :title="description"
        :visible.sync="portLog">
      <el-table

          class="table"
          :data="tablePort"
          border
          style="width: 100% "
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{ textAlign: 'center' }"
          max-height="300px"
      >
        <el-table-column
            label="#">
          {{ index }}
        </el-table-column>
        <el-table-column
            label="调整时间">
          <template slot-scope="scope">{{ scope.row.time }}</template>
        </el-table-column>
        <el-table-column
            label="调整详情">
          <template slot-scope="scope">{{ scope.row.content }}</template>
        </el-table-column>
        <el-table-column
            label="备注">
          <template slot-scope="scope">{{ scope.row.remark }}</template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
        :title="'【'+`${description}`+'】'+'调用履历'" width="80%"
        :append-to-body="true"
        :visible.sync="portManagement">
      <div class="search" style="display: flex;justify-content: space-around;margin-bottom: 10px;
    align-items: center;">
        <div class="block">
          <span class="demonstration">调用时间</span>
          <el-date-picker
              size="mini"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['12:00:00']">
          </el-date-picker>
        </div>
        <div class="dw_1"><span class="demonstration">调用结果</span>
          <el-dropdown style="border: 1px solid black;padding:0 10px" trigger="click">
      <span class="el-dropdown-link">
        全部<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>全部</el-dropdown-item>
              <el-dropdown-item>成功</el-dropdown-item>
              <el-dropdown-item>失败</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="dw-2">
          <span class="demonstration" style="margin-left: 10px">错误类型</span>
          <el-dropdown style="border: 1px solid black;padding:0 10px" trigger="click">
      <span class="el-dropdown-link">
        全部<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
            <el-dropdown-menu size="mini" slot="dropdown">
              <el-dropdown-item>全部</el-dropdown-item>
              <el-dropdown-item>成功</el-dropdown-item>
              <el-dropdown-item>失败</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="dw_btn">
          <el-button type="primary" size="mini" @click="submitSearch">确定</el-button>

        </div>
      </div>
      <el-table
          class="table"
          :data="tableCall"
          border
          style="width: 100% "
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{ textAlign: 'center' }"
          max-height="300px"
      >
        <el-table-column
            label="#"
            max-height="300px">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>

        </el-table-column>
        <el-table-column
            label="调用时间">
          <template slot-scope="scope">{{ scope.row.callTime }}</template>
        </el-table-column>
        <el-table-column
            label="调用人">
          <template slot-scope="scope">{{ scope.row.companyName }}</template>
        </el-table-column>
        <el-table-column
            label="调用结果">
          <template slot-scope="scope">
            <div :style="scope.row.statusCode ===200?'':'color:red'">{{
                scope.row.statusCode === 200 ? "成功" : "失败"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="错误类型">
          <template slot-scope="scope">{{ scope.row.remark }}</template>
        </el-table-column>
        <el-table-column
            label="错误详情">
          <template slot-scope="scope">{{ scope.row.remark }}</template>
        </el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<style scoped lang="scss">

.limit {
  padding: 30px 30px;
  box-sizing: border-box;
  //width: 100vh;
  //height: 100vh;
  background: #FFFFFF;
  border: 1px solid black;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .limit-head {
    border: 1px solid #606266;
    border-bottom: none;
    box-sizing: border-box;
    width: 80%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    //margin-bottom: 20px;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }

  .limitFooter {
    width: 80%;
    border: 1px solid #606266;
    border-top: none;
    box-sizing: border-box;
    padding: 10px;
    //border-top: 1px solid gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mask {
    box-sizing: border-box;
    background: #FFFFFF;
    position: absolute;
    top: 20%;
    left: 30%;
    width: 500px;
    height: 400px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .head {
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;

    }

    .contain {
      padding: 0 20px;
      box-sizing: border-box;

    }

  }

  .table {
    border: 1px solid #606266;
  }

  .portLog {
    width: 100%;
    height: 100%;
    z-index: 1001;
    background: #FFFFFF;
    border: 1px solid black;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;

  }

  .periodUse {
    color: #5C8EFF;
  }

  .search {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

.btn {
  color: #3F51B5;
}
</style>