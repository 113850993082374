<template>
    <div class="change-password">
        <div class="head-part">
            <span class="title">修改密码</span>
            <i class="el-icon-close close" @click="cancel" title="关闭"></i>
        </div>
        <div class="main-container">
            <el-form size="small" :rules="rules" :label-position="labelPosition" class="user-detail-form" ref="changePasswordDialog" :model="userPassword" label-width="95px">
                <el-form-item class="form-label" label="原密码" prop="originPassword" >
                    <el-input show-password v-model="userPassword.originPassword"></el-input>
                </el-form-item>
                <el-form-item class="form-label" label="新密码" prop="newPassword">
                    <el-input show-password v-model="userPassword.newPassword"></el-input>
                </el-form-item>
                <el-form-item class="form-label" label="重复新密码" prop="repeatNewPassword">
                    <el-input show-password v-model="userPassword.repeatNewPassword"></el-input>
                </el-form-item>
                <el-form-item class="form-label tip-container" label="">
                    <span class="tip"><span class="red">*</span> 新密码需为6至16位，至少包含英文字母、数字和符号中的两种</span>
                </el-form-item>
            </el-form>
        </div>
        <div class="footer-part">
            <el-button :loading="loading" class="btn" type="primary" @click="commit">确定</el-button>
            <el-button class="btn" @click="cancel">取消</el-button>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        var validatePass = (rule, value, callback) => {
            var reg = /^(?![0-9]+$)(?![A-Za-z]+$)(?!([^(0-9a-zA-Z)])+$)/;
            if(reg.test(value)){
                callback();
            }else{
                callback(new Error('不符合格式要求'));
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value !== this.userPassword.newPassword) {
                callback(new Error('两次输入密码不一致'));
            } else {
                callback();
            }
        };
        return{
            loading: false,
            labelPosition: 'left',
            userPassword: {
                originPassword: '',
                newPassword: '',
                repeatNewPassword: '',
                userId: this.$store.getters.loginUserId,
            },
            rules: {
                originPassword:[
                    { required: true, message: '请输入原密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '最大长度6-20字符', trigger: 'blur' }
                ],
                newPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '最大长度6-20字符', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' }
                ],
                repeatNewPassword: [
                    { required: true, message: '请再次输入新密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '最大长度6-20字符', trigger: 'blur' },
                    { validator: validatePass2, trigger: 'blur' }
                ]
            },
        }
    },
    methods:{
        cancel(){
            this.$emit('cancel');
        },
        commit(){
            this.$refs['changePasswordDialog'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.$http.post(this.$apiPath.adminChangePassword, this.userPassword).then(res => {
                        if(res.status === 200 && res.data.status === 200){
                            this.$message.success(res.data.message);
                            this.$emit('handleCommitSuccess');
                            this.$emit('cancel');
                        }else{
                            this.$message.error(res.data.message);
                        }
                        this.loading = false;
                    });
                }else {
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .change-password{
        width: 560px;
        height: 330px;
        background-color: #FFFFFF;

        .head-part{
            position: relative;
            .title{
                display: block;
                font-size: 18px;
                margin-left: 18px;
                padding-top: 14px;
                color: #101010;
            }

            .close{
                position: absolute;
                color: #CECECE;
                height: 20px;
                width: 20px;
                font-size: 20px;
                right: 14px;
                top: 12px;
                cursor: pointer;
            }
        }

        .user-detail-form{
            margin: 30px 150px 0 30px;
        }

        .footer-part{
            position: absolute;
            height: 54px;
            width: 100%;
            border-top: 1px solid #EEE;
            bottom: 0;
            left: 0;

            .btn{
                height: 32px;
                width: 80px;
                line-height: 30px;
                font-size: 14px;
                padding: 0 20px;
                margin: 10px 12px 0 0;
                float: right;

                span{
                    display: block;
                    height: 30px;
                    line-height: 30px;
                }
            }
        }

        .red{
            color: #F00;
        }

        .tip{
            font-size: 12px;
        }

        .tip-container{
            width: 445px;
            color: rgba(#000, 0.46);
        }
    }
</style>


<style lang="scss">
  .form-label {
    margin-bottom: 10px !important;
    // overflow: hidden;
    label{
      color: #000 !important;
      position: relative;
      
    }

    label:before{
      position: absolute;
      left: -10px;
      top: 0;
    }

    .date-pick{
      width: 300px !important;
    }
  }
.user-detail-form{
    .el-form-item__content{
        position: relative;

        .el-form-item__error{
            height: 32px;
            line-height: 32px;
            position: absolute;
            left: 310px;
            top: 0;
            width: 300px;
            padding-top: 0 !important;
        }
    }
}
</style>
