<template>
  <div class="user-detail" v-loading="loading">
    <CloseHeader :title="getDialogType()" @cancel="cancel"></CloseHeader>
    <el-form size="small" :hide-required-asterisk="hideStar" :inline-message="inlineErrMsg" :rules="rules"
      :label-position="labelPosition" class="user-detail-form " ref="userEditDialogForm" :model="userEditDialogForm"
      label-width="95px">
      <el-form-item class="form-label" label="手机号" prop="phone">
        <el-input v-model="userEditDialogForm.phone"></el-input>
      </el-form-item>
      <el-form-item class="form-label" label="" prop="projectAdmin">
        <el-checkbox v-model="userEditDialogForm.projectAdmin">管理员账户</el-checkbox>
      </el-form-item>
      <el-form-item class="form-label" label="姓名" prop="name">
        <el-input v-model="userEditDialogForm.name"></el-input>
      </el-form-item>
      <el-form-item class="form-label" label="工作单位" prop="companyId">
        <el-select clearable filterable v-model="userEditDialogForm.companyId" placeholder="工作单位">
          <el-option v-for="item in userCompanyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-label" label="单位职务" prop="companyPosition">
        <el-input v-model="userEditDialogForm.companyPosition"></el-input>
      </el-form-item>
      <el-form-item class="form-label" label="邮箱" prop="email">
        <el-input v-model="userEditDialogForm.email"></el-input>
      </el-form-item>
      <el-form-item class="form-label select" label="可用系统">
        <div style="display: flex; flex-direction: column">
          <div style="display: flex; flex-direction: row; margin-bottom: 10px;">
            <el-checkbox style="width: 130px;" v-model="userLicense[0].has">SolarPV-Plus</el-checkbox>
            <span v-if="userLicense[0].has" style="width: 65px;">有效期至</span>
            <el-date-picker v-if="userLicense[0].has" :disabled="userLicense[0].permanent"
              style="width: 130px !important;" v-model="userLicense[0].expiredDate" type="date"
              value-format="yyyy-MM-dd" placeholder="选择日期">
            </el-date-picker>
            <el-checkbox v-if="userLicense[0].has" style="margin-left: 20px"
              v-model="userLicense[0].permanent">长期</el-checkbox>
          </div>
          <div style="display: flex; flex-direction: row; margin-bottom: 10px;">
            <el-checkbox style="width: 130px;" v-model="userLicense[1].has">SolarPV-Plus 3D</el-checkbox>
            <span v-if="userLicense[1].has" style="width: 65px;">有效期至</span>
            <el-date-picker v-if="userLicense[1].has" :disabled="userLicense[1].permanent"
              style="width: 130px !important;" v-model="userLicense[1].expiredDate" type="date"
              value-format="yyyy-MM-dd" placeholder="选择日期">
            </el-date-picker>
            <el-checkbox v-if="userLicense[1].has" style="margin-left: 20px"
              v-model="userLicense[1].permanent">长期</el-checkbox>
          </div>
          <div style="display: flex; flex-direction: row">
            <el-checkbox style="width: 130px;" v-model="userLicense[2].has">SolarPV-Lite</el-checkbox>
            <span v-if="userLicense[2].has" style="width: 65px;">有效期至</span>
            <el-date-picker v-if="userLicense[2].has" :disabled="userLicense[2].permanent"
              style="width: 130px !important;" v-model="userLicense[2].expiredDate" type="date"
              value-format="yyyy-MM-dd" placeholder="选择日期">
            </el-date-picker>
            <el-checkbox v-if="userLicense[2].has" style="margin-left: 20px"
              v-model="userLicense[2].permanent">长期</el-checkbox>
          </div>
          <div style="display: flex; flex-direction: row">
            <el-checkbox style="width: 130px;" v-model="userLicense[3].has">SolarPV-AIOM</el-checkbox>
            <span v-if="userLicense[3].has" style="width: 65px;">有效期至</span>
            <el-date-picker v-if="userLicense[3].has" :disabled="userLicense[3].permanent"
              style="width: 130px !important;" v-model="userLicense[3].expiredDate" type="date"
              value-format="yyyy-MM-dd" placeholder="选择日期">
            </el-date-picker>
            <el-checkbox v-if="userLicense[3].has" style="margin-left: 20px"
              v-model="userLicense[3].permanent">长期</el-checkbox>
          </div>
          <div style="display: flex; flex-direction: row">
            <el-checkbox style="width: 130px;" v-model="userLicense[4].has">SolarPV-UAV</el-checkbox>
            <span v-if="userLicense[4].has" style="width: 65px;">有效期至</span>
            <el-date-picker v-if="userLicense[4].has" :disabled="userLicense[4].permanent"
              style="width: 130px !important;" v-model="userLicense[4].expiredDate" type="date"
              value-format="yyyy-MM-dd" placeholder="选择日期">
            </el-date-picker>
            <el-checkbox v-if="userLicense[4].has" style="margin-left: 20px"
              v-model="userLicense[4].permanent">长期</el-checkbox>
          </div>
          <!-- 无人机区域 -->
          <div style="display: flex; flex-direction: row">
            <el-checkbox style="width: 130px;" v-model="userLicense[5].has">无人机区域</el-checkbox>
            <div v-if="userLicense[5].has" class="drone-zone">
              <el-input v-model="userLicense.allCapacity" style="width: 130px;"></el-input><span class="unit">m²</span>,
              剩余<span class="residual-area">{{ userLicense.surplusCapacity }}</span>m²
              <span class="record-btn">履历</span>
            </div>
          </div>
        </div>


      </el-form-item>

      <el-form-item class="form-label-remark" label="备注" prop="remark">
        <el-input :rows="5" type="textarea" maxlength="300" show-word-limit
          v-model="userEditDialogForm.remark"></el-input>
      </el-form-item>
    </el-form>
    <div class="footer-part">
      <el-button :loading="loading" class="btn" type="primary" @click="commit">确定</el-button>
      <el-button class="btn" @click="cancel">取消</el-button>
    </div>
  </div>
</template>

<script>
import CloseHeader from './CloseHeader'
export default {
  props: {
    dialogType: {
      type: String,
      required: true
    },
    userId: {
      type: Number,
      required: false
    }
  },
  created() {
    this.userLicense = this.originLicenses()
  },
  mounted() {

    this.loading = true
    if (this.userId != null) {
      this.$http.get(this.$apiPath.adminUserInfo, { params: { userId: this.userId } }).then(res => {
        let data = res.data;
        if (data.status !== 200) {
          this.loading = false;
          this.$message.error(data.message)
          return;
        }
        this.userEditDialogForm = data.data;
        console.log("this.userEditDialogForm----", this.userEditDialogForm);
        for (let i = 0; i < this.userEditDialogForm.licenses.length; i++) {
          let license = this.userEditDialogForm.licenses[i]
          switch (license.license) {
            case 'SOLARPV_PLUS':
              this.userLicense[0].has = true
              this.userLicense[0].permanent = license.licensePermanent
              this.userLicense[0].expiredDate = license.expiredDate
              break
            case 'SOLARPV_PLUS_3D':
              this.userLicense[1].has = true
              this.userLicense[1].permanent = license.licensePermanent
              this.userLicense[1].expiredDate = license.expiredDate
              break
            case 'SOLARPV_LITE':
              this.userLicense[2].has = true
              this.userLicense[2].permanent = license.licensePermanent
              this.userLicense[2].expiredDate = license.expiredDate
              break
            case 'SOLARPV_AIOM':
              this.userLicense[3].has = true
              this.userLicense[3].permanent = license.licensePermanent
              this.userLicense[3].expiredDate = license.expiredDate
              break
            case 'SOLARPV_UAV':
              this.userLicense[4].has = true
              this.userLicense[4].permanent = license.licensePermanent
              this.userLicense[4].expiredDate = license.expiredDate
              break
              // 无人机区域 设置
            case 'droneCapacity':
              this.userLicense[5].has = true
              this.userLicense[5].allCapacity = license.allCapacity
              this.userLicense[5].surplusCapacity = license.surplusCapacity
              break
          }
        }
        this.oriPhone = this.userEditDialogForm.phone;
        this.loading = false;
      });
    }
    this.$http.get(this.$apiPath.serviceServerTime).then(res => {
      if (res.status === 200) {
        this.serverTime = res.data.data;
        if (this.userId == null) {
          this.loading = false
        }
      }
    });
    this.getUserCompanyList()
  },
  data() {
    let validatePhone = (rule, value, callback) => {
      if (this.userId != null && this.oriPhone === this.userEditDialogForm.phone) {
        callback();
      }
      this.$http.get(this.$apiPath.adminCheckPhoneExists,
        {
          params: { phone: this.userEditDialogForm.phone }
        }).then(res => {
          let data = res.data;
          if (res.status === 200 && data.status === 200) {
            if (data.data.exists) {
              callback(new Error('手机号已经存在'));
            }
          }
          callback();
        });
    };
    return {
      userLicense: [],
      userCompanyList: [],
      loading: false,
      serverTime: null,
      oriPhone: '',
      inlineErrMsg: false,
      hideStar: false,
      labelPosition: 'left',
      userEditDialogForm: {
        phone: '',
        name: '',
        companyId: '',
        companyPosition: '',
        email: '',
        remark: '',
        licenses: []
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { len: 11, message: '手机号长度为11位', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号格式错误', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { max: 20, message: '最大长度为20个字符', trigger: 'blur' }
        ],
        companyId: [
          { required: true, message: '请选择所属单位', trigger: 'blur' }
        ],
        companyPosition: [
          { max: 20, message: '最大长度为20个字符', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确格式邮箱', trigger: 'blur' },
          { max: 50, message: '最大长度为50个字符', trigger: 'blur' }
        ],
        accountType: [
          { required: true, message: '请选择账户类型', trigger: 'change' }
        ],
        expiredAt: [
          { required: true, message: '请选择有效期', trigger: 'blur' }
        ],
        remark: [
          { max: 300, message: '最大长度为300个字符', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    disablePhone() {
      return this.userId != null;
    }
  },
  methods: {
    originLicenses() {
      return [
        {
          key: 'SOLARPV_PLUS',
          name: 'SolarPV-Plus',
          has: false,
          permanent: false,
          expiredDate: ''
        },
        {
          key: 'SOLARPV_PLUS_3D',
          name: 'SolarPV-Plus 3D',
          has: false,
          permanent: false,
          expiredDate: ''
        },
        {
          key: 'SOLARPV_LITE',
          name: 'SolarPV-Lite',
          has: false,
          permanent: false,
          expiredDate: ''
        },
        {
          key: 'SOLARPV_AIOM',
          name: 'SolarPV-AIOM',
          has: false,
          permanent: false,
          expiredDate: ''
        },
        {
          key: 'SOLARPV_UAV',
          name: 'SolarPV-UAV',
          has: false,
          permanent: false,
          expiredDate: ''
        },
        {
          key: '无人机区域',
          name: '无人机区域',
          has: false,
          allCapacity: 300000,
          surplusCapacity: 300000
        }
      ]
    },
    getUserCompanyList() {
      this.loading = true
      this.$http.get(this.$apiPath.adminUserCompanyList).then(res => {
        window.console.log(res)
        if (res.status === 200 && res.data.status === 200) {
          this.userCompanyList = res.data.data.list
        }
        this.loading = false
      })
    },
    getDialogType() {
      return this.dialogType === 'ADD' ? '添加用户' : '修改用户';
    },
    cancel() {
      this.$emit('cancel');
    },
    commit() {
      this.$refs['userEditDialogForm'].validate((valid) => {
        if (valid) {
          for (let i = 0; i < this.userLicense.length; i++) {
            if (this.userLicense[i].has && !this.userLicense[i].permanent) {
              if (this.userLicense[i].expiredDate === '' || this.userLicense[i].expiredDate == null) {
                this.$message.error('可用系统' + this.userLicense[i].name + '过期时间未设置');
                return
              }
            }
          }
          this.userEditDialogForm.licenses = []
          for (let i = 0; i < this.userLicense.length; i++) {
            if (!this.userLicense[i].has) {
              continue
            }
            this.userEditDialogForm.licenses.push({
              licensePermanent: this.userLicense[i].permanent,
              license: this.userLicense[i].key,
              expiredDate: this.userLicense[i].permanent ? '' : this.userLicense[i].expiredDate
            })
          }

          this.loading = true;
          if (this.dialogType === 'ADD') {
            this.$http.post(this.$apiPath.adminAddUser, this.userEditDialogForm).then(res => {
              let data = res.data;
              if (data.status !== 200) {
                this.$message.error(data.message);
              } else {
                this.$message.success('添加用户成功');
                this.$emit('commitSuccess');
              }
              this.loading = false;
            });
          } else if (this.dialogType === 'EDIT') {
            this.$http.post(this.$apiPath.adminUpdateUser, this.userEditDialogForm).then(res => {
              let data = res.data;
              if (data.status !== 200) {
                this.$message.error(data.message);
              } else {
                this.$message.success('修改用户成功');
                this.$emit('commitSuccess');
              }
              this.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    handleTrial(value) {
      // let servTime = new Date(this.serverTime);
      if (value === 'TRIAL') {
        this.userEditDialogForm.expiredAt = new Date(this.serverTime).getTime() + 7 * 24 * 60 * 60 * 1000
      } else if (value === 'ANNUALLY') {
        this.userEditDialogForm.expiredAt = new Date(this.serverTime).getTime() + 365 * 24 * 60 * 60 * 1000
      }
    },
  },
  components: {
    CloseHeader
  }
}
</script>

<style lang="scss" scoped>
.user-detail {
  width: 590px;
  height: 710px;
  border-radius: 3px;
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  border: 1px solid rgba(208, 208, 208, 1);
  background-color: #fff;
  position: relative;

  .user-detail-form {
    margin: 20px 0 0 30px;
  }

  .footer-part {
    position: absolute;
    height: 54px;
    width: 100%;
    border-top: 1px solid #EEE;
    bottom: 0;
    left: 0;

    .btn {
      height: 32px;
      width: 80px;
      line-height: 30px;
      font-size: 14px;
      padding: 0 20px;
      margin: 10px 12px 0 0;
      float: right;

      span {
        display: block;
        height: 30px;
        line-height: 30px;
      }
    }
  }
}
</style>

<style lang="scss">
.form-label,
.form-label-remark {
  margin-bottom: 10px !important;

  // overflow: hidden;
  label {
    color: #000 !important;
    position: relative;
  }

  label:before {
    position: absolute;
    left: -10px;
    top: 0;
  }

  .date-pick {
    width: 285px !important;
  }
}

.form-label-remark {
  width: 500px;

  textarea {
    max-height: 160px;
    min-height: 38px !important;
  }

}

.select {}

.user-detail-form{
.el-form-item__content {
  position: relative;

  .el-input {
    width: 285px !important;
  }

  .drone-zone {
    .el-input {
      width: 130px !important;
    }

    .unit {
      margin-left: 5px;
    }

    .residual-area {
      color: red;
    }

    .record-btn {
      margin-left: 5px;
      color: #0E3288;
      border-bottom: 1px solid #0E3288;
    }
  }

  .el-radio {
    margin-right: 22px !important;
  }

  .el-form-item__error {
    height: 32px;
    line-height: 32px;
    position: absolute;
    left: 310px;
    top: 0;
    width: 300px;
    padding-top: 0 !important;
  }
}
}
</style>
