<template>
  <div class="inverter-detail">
      <CloseHeader :title="getDialogType()" @cancel="cancel"></CloseHeader>
      <div class="container">
        <el-form size="small" :rules="rules" :label-position="labelPosition" class="inverter-detail-form" ref="dialogForm" :model="dialogForm" label-width="115px">
          <el-form-item class="form-label" label="厂商名称" prop="companyId">
            <el-select clearable filterable v-model="dialogForm.companyId" placeholder="厂商名称">
              <el-option v-for="item in companyList" :key="item.id" :label="item.companyNameEn" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
            <el-form-item class="form-label" label="直流交流种类" prop="acDcType">
                <el-select clearable v-model="dialogForm.acDcType" placeholder="直流交流种类">
                    <el-option label="直流" value="DC"></el-option>
                    <el-option label="交流" value="AC"></el-option>
                </el-select>
            </el-form-item>
          <el-form-item class="form-label" label="汇流箱型号" prop="comboboxType">
              <el-input v-model="dialogForm.comboboxType" clearable placeholder="汇流箱型号"></el-input>
          </el-form-item>
            <el-form-item class="form-label" label="最大输入路数" prop="numMaxLine">
                <el-input v-model="dialogForm.numMaxLine" clearable placeholder="最大输入路数"></el-input>
            </el-form-item>
            <el-form-item class="form-label" label="连接组串数" prop="stringNum">
                <el-input v-model="dialogForm.stringNum" clearable placeholder="连接组串数"></el-input>
            </el-form-item>
            <el-form-item class="form-label" label="最大输入电流" prop="iMax">
                <el-input v-model="dialogForm.iMax" clearable placeholder="最大输入电流">
                    <template slot="append">A</template>
                </el-input>
            </el-form-item>
        </el-form>
      </div>
      <div class="footer-part">
        <el-button :loading="loading" class="btn" type="primary" @click="commit">确定</el-button>
        <el-button class="btn" @click="cancel">取消</el-button>
      </div>
  </div>
</template>

<script>
import CloseHeader from './CloseHeader'
export default {
  props: {
    dialogType:{
      type: String,
      required: true
    },
    comboboxId:{
      type: Number,
      required: false
    },
    companyList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dialogForm: {
          companyId: null,
          id: null,
          acDcType: '',
          comboboxType: '',
          numMaxLine: null,
          stringNum: null,
          iMax: null
      },
      loading: false,
      labelPosition: 'left',
      rules: {
        companyId: [
          { required: true, message: '请选择汇流箱厂商', trigger: ['blur', 'change']}
        ],
      }
    }
  },
  methods: {
    getDialogType(){
      return this.dialogType === 'ADD' ? '添加逆变器' : '修改逆变器';
    },
    cancel(){
      this.$emit('cancel');
    },
    commit() {
        this.$refs['dialogForm'].validate((valid) => {
            if (valid) {
                this.loading = true;
                if(this.dialogType === 'ADD'){
                    this.$http.post(this.$apiPath.adminAddCombobox, this.dialogForm).then(res => {
                        var data = res.data;
                        if(data.status !== 200){
                            this.$message.error(data.message);
                        }else{
                            this.$message.success('添加汇流箱成功');
                            this.$emit('commitSuccess');
                        }
                        this.loading = false;
                    });
                }else if(this.dialogType === 'EDIT'){
                    this.$http.post(this.$apiPath.adminUpdateCombobox, this.dialogForm).then(res => {
                        var data = res.data;
                        if(data.status !== 200){
                            this.$message.error(data.message);
                        }else{
                            this.$message.success('修改汇流箱成功');
                            this.$emit('commitSuccess');
                        }
                        this.loading = false;
                    });
                }
            } else {
                return false;
            }
        });
    }
  },
  mounted() {
    if(this.comboboxId != null){
      this.loading = true;
      this.$http.get(this.$apiPath.adminComboboxInfo, {params: {comboboxId: this.comboboxId}}).then(res => {
        var data = res.data;
        if(data.status !== 200){
          this.loading = false;
          this.$message.error(data.message);
          return;
        }
        this.dialogForm = data.data;
        if (this.dialogForm.flag != null) {
            this.dialogForm.flag += ''
        }

        // this.dialogForm.bool_bifacialpv = data.data.bool_bifacialpv + ''
        // this.dialogForm.bool_bipv = data.data.bool_bipv + ''
        this.loading = false;
      });
    }
  },
  components: {
    CloseHeader
  }
}
</script>

<style lang="scss" scoped>
.inverter-detail {
  width: 560px;
  height: 596px;
  background-color: #ffffff;

  .container {
    width: 100%;
    height: 473px;
    margin-top: 30px;
    overflow-x: hidden;
    // overflow-y: scroll;

    .inverter-detail-form {
      margin-left: 30px;
      width: 100%;
    }
  }

  .footer-part{
    position: absolute;
    height: 54px;
    width: 100%;
    border-top: 1px solid #EEE;
    bottom: 0;
    left: 0;

    .btn{
      height: 32px;
      width: 80px;
      line-height: 30px;
      font-size: 14px;
      padding: 0 20px;
      margin: 10px 12px 0 0;
      float: right;

      span{
        display: block;
        height: 30px;
        line-height: 30px;
      }
    }
  }

  .form-label, .form-label-remark{
    label{
      color: #000 !important;
      position: relative;
    }
  }

  .form-label::v-deep .el-input-group__append{
    min-width: 25px;
    text-align: center;
  }
}
</style>
