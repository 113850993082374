<template>
    <div class="close-header">
        <div class="head-part">
            <span class="title">{{title}}</span>
            <i class="el-icon-close close" @click="cancel" title="关闭"></i>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        title:{
            type: String,
            required: true
        }
    },
    methods: {
        cancel(){
            this.$emit('cancel');
        }
    }
}
</script>


<style lang="scss" scoped>
.close-header{
    .head-part{
        position: relative;
        .title{
            display: block;
            font-size: 18px;
            margin-left: 18px;
            padding-top: 14px;
            color: #101010;
        }

        .close{
            position: absolute;
            color: #CECECE;
            height: 20px;
            width: 20px;
            font-size: 20px;
            right: 14px;
            top: 12px;
            cursor: pointer;
        }
    }
}
</style>
