<template>
    <div class="company-detail" v-loading="loading">
        <CloseHeader :title="getDialogType()" @cancel="cancel"></CloseHeader>
        <div class="container">
            <el-form size="small" :rules="rules" :label-position="labelPosition" class="company-detail-form" ref="dialogForm" :model="dialogForm" label-width="115px">
                <el-form-item class="form-label" label="厂商中文名称" prop="companyNameCn">
                    <el-input v-model="dialogForm.companyNameCn" clearable></el-input>
                </el-form-item>
                <el-form-item class="form-label" label="厂商英文名称" prop="companyNameEn">
                    <el-input v-model="dialogForm.companyNameEn" clearable></el-input>
                </el-form-item>
                <el-form-item class="form-label" label="厂商类型" prop="companyType">
                    <el-select v-model="dialogForm.companyType" placeholder="厂商类型">
                        <el-option label="组件厂商" value="COMPONENT"></el-option>
                        <el-option label="逆变器厂商" value="INVERTER"></el-option>
                        <el-option label="汇流箱厂商" value="COMBBOX"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="form-label-remark" label="备注" prop="remark">
                    <el-input :rows="5" type="textarea" maxlength="300" show-word-limit v-model="dialogForm.remark"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="footer-part">
            <el-button :loading="loading" class="btn" type="primary" @click="commit">确定</el-button>
            <el-button class="btn" @click="cancel">取消</el-button>
        </div>
    </div>
</template>

<script>
import CloseHeader from './CloseHeader'
export default {
    props:{
        dialogType:{
            type: String,
            required: true
        },
        companyId:{
            type: Number,
            required: false
        }
    },
    data() {
        return{
            dialogForm: {
                companyNameEn: '',
                companyNameCn: '',
                companyType: 'COMPONENT',
                remark: '',
            },
            loading: false,
            inlineErrMsg: false,
            hideStar: false,
            labelPosition: 'left',
            rules: {
                companyNameCn: [
                    { required: true, message: '请输入厂商名', trigger: 'blur' }
                ],
                companyNameEn: [
                    { required: true, message: '请输入厂商名', trigger: 'blur' }
                ],
                companyType: [
                    { required: true}
                ],
                remark: [
                    { max: 300, message: '最大长度为300个字符', trigger: 'blur' }
                ]
            }
        }
    },
    mounted(){
        if(this.companyId != null){
            this.loading = true;
            this.$http.get(this.$apiPath.adminCompanyInfo, {params: {companyId: this.companyId}}).then(res => {
                var data = res.data;
                if(data.status !== 200){
                    this.loading = false;
                    this.$message.error(data.message);
                    return;
                }
                this.dialogForm = data.data;
                this.loading = false;
            });
        }
    },
    methods:{
        cancel(){
            this.$emit('cancel');
        },
        getDialogType(){
            return this.dialogType == 'ADD' ? '添加厂商' : '修改厂商';
        },
        commit(){
            this.$refs['dialogForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    if(this.dialogType == 'ADD'){
                        this.$http.post(this.$apiPath.adminAddCompany, this.dialogForm).then(res => {
                            var data = res.data;
                            if(data.status != 200){
                                this.$message.error(data.message);
                            }else{
                                this.$message.success('添加厂商成功');
                                this.$emit('commitSuccess');
                            }
                            this.loading = false;
                        });
                    }else if(this.dialogType == 'EDIT'){
                        this.$http.post(this.$apiPath.adminUpdateCompany, this.dialogForm).then(res => {
                            var data = res.data;
                            if(data.status != 200){
                                this.$message.error(data.message);
                            }else{
                                this.$message.success('修改厂商成功');
                                this.$emit('commitSuccess');
                            }
                            this.loading = false;
                        });
                    }
                } else {
                    return false;
                }
            });
        },
    },
    components: {
        CloseHeader
    }
}
</script>

<style lang="scss" scoped>
.company-detail{
    width: 560px;
    height: 490px;
    background-color: #ffffff;

    .company-detail-form{
        margin: 30px 0 0 30px;
    }

    .footer-part{
        position: absolute;
        height: 54px;
        width: 100%;
        border-top: 1px solid #EEE;
        bottom: 0;
        left: 0;

        .btn{
            height: 32px;
            width: 80px;
            line-height: 30px;
            font-size: 14px;
            padding: 0 20px;
            margin: 10px 12px 0 0;
            float: right;

            span{
                display: block;
                height: 30px;
                line-height: 30px;
            }
        }
    }

    .form-label, .form-label-remark{
         label{
            color: #000 !important;
            position: relative;
        }
    }
}
</style>
