<template>
    <div id="login">
        <div class="login-form">
            <div class="img">
                <img src="../assets/login-top.png" alt="">
            </div>
            <el-form :label-position="labelPosition" label-width="80px" :model="loginForm" class="form">
                <el-form-item label="登录名" class="form-item">
                    <el-input size="medium" v-model="loginForm.username" clearable></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input size="medium" v-model="loginForm.password" show-password></el-input>
                </el-form-item>
                <el-button @click="btnLoginClick" :loading="loadingFlag" class="btnLogin" type="primary" size="medium">{{loginButtonTitle}}</el-button>
            </el-form>
            <div class="copyright">
                &copy; 2019 河海安能 All rights reserved.
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    data(){
        return{
            loginButtonTitle: '登 录',
            labelPosition: 'left',
            loadingFlag: false,
            loginForm: {
                username: '',
                password: ''
            }
        }
    },
    computed:{
       
    },
    methods: {
        btnLoginClick:function(){
            console.log(this.loginForm);
            this.loadingFlag = true;
            this.loginButtonTitle = '正在登录...';
            var _this = this;
            this.$http.post(this.$apiPath.adminLogin, this.loginForm).then(data => {
                let resData = data.data;
                console.log(resData)
                if(resData.status !== 200){
                    this.$message.error(resData.message);
                    this.loadingFlag = false;
                    this.loginButtonTitle = '登 录';
                }else{
                    window.localStorage.setItem('Token', resData.data.token)
                    window.localStorage.setItem('TokenTimestamp', new Date().getTime().toString())
                    this.$message({
                        message: '登录成功',
                        type: 'success',
                        duration: 2000,
                        onClose: function(){
                            _this.$store.commit('login', resData.data)
                            _this.$router.replace('/admin')
                        }
                    });
                }
            })

        }
    }
}
</script>

<style lang="scss" scoped>
    #login{
        height: 100vh;
        width: 100vw;
        background-color: #eee;
        position: relative;
        background-image: url('../assets/login-bg.jpg')
    }

    .login-form{
        background-color: #fff;
        height: 445px;
        padding: 0 20px;
        width: 378px;
        position: absolute;
        left: 50%;
        top: 45%;
        margin-top: -220px;
        margin-left: -209px;
        /* border-radius: 5px; */
        img{     
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%; 
        }
        .img{
            width: 357px;
            height: 197px;
            margin: 14px auto 0;
        }

        .form{
            margin-top: 34px;
            .form-item{
                margin-bottom: 20px;
            }
        }

        .btnLogin{
            width: 100%;
            background-color: #1D2C4F;
            margin-top: -4px;
            height: 40px;
            line-height: 40px;
            padding: 0;
            font-size: 16px;
        }

        .copyright{
            padding-top: 12px;
            font-size: 12px;
            text-align: center;
            color: #101010;
            font-family: 'SourceHanSansSC-regular';
        }
    }
</style>

<style lang="scss">
    .form{
        label{
            font-size: 16px;
            font-family: 'Roboto';
            color: #404040;
        }

        input{
            height: 40px !important;
            line-height: 40px !important;
        }
    }
</style>
