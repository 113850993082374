/* eslint-disable */

import Vue from 'vue'
import VueRouter from 'vue-router'
const originPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
    return originPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

import login from './pages/Login'
import admin from './pages/Admin'
import user from './pages/admin/User'
import baseData from './pages/admin/BaseData'
import news from './pages/admin/News'
import lesson from './pages/admin/Lesson'
import SaasPort from './pages/admin/SaasPort'
import mailSend from './pages/admin/mailSend/Index'
import droneReproductionProject from './pages/admin/droneReproductionProject'
// import limit from './pages/admin/limitManagement.vue'

const routes = [
    {
        path: '/login',
        component: login
    },
    {
        path: '/admin',
        component: admin,
        children:[
            {
                path: 'user',
                name: 'user',
                component: user
            },
            {
                path: 'saas-port',
                component: SaasPort
                // children:[
                //     {
                //         path:'limit-management',
                //         component:limit,
                //     }
                // ]

            },
            {
                path: 'base-data',
                component: baseData
            },
            {
                path: 'news',
                component: news
            },
            {
                path: 'lesson',
                component: lesson
            },
            {
                path:'drone-reproduction-project',
                component: droneReproductionProject
            },
            {
                path: 'mail-send',
                component: mailSend
            },
        ]
    },
    {
        path: '/',
        redirect: '/login'
    }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router
