<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  
}
</script>

<style >
  html, body{
    margin: 0;
    padding: 0;
    font-family: 'Microsoft YaHei', sans-serif
  }
</style>
