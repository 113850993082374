<template>
  <div id="sidebar" class="sidebar">
    <div class="menu">
      <ul class="menu-container">
        <router-link :to="{ path: item.url }" class="menu-item" v-for="item in getMenuList"
          v-bind:class="{ 'active': item.id == activeMenuId }" v-bind:key="item.id" @click.native="handleClick(item.id)">
          {{ item.resourcesName }}
        </router-link>
        <router-link class="menu-item" to="/admin/saas-port" v-bind:class="{ 'active': '/admin/saas-port' == activeMenuId }"
          @click.native="handleClick('/admin/saas-port')">Saas用户接口管理
        </router-link>
        <router-link class="menu-item" to="/admin/drone-reproduction-project"
          v-bind:class="{ 'active': '/admin/drone-reproduction-project' == activeMenuId }"
          @click.native="handleClick('/admin/drone-reproduction-project')">无人机复现工程
        </router-link>
        <router-link class="menu-item"
            to="/admin/mail-send"
            v-bind:class="{'active': '/admin/mail-send'==activeMenuId}"
            @click.native="handleClick('/admin/mail-send')">邮件发送
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeMenuId: -1,
      menu: []
    }
  },
  created: function () {
    this.$store.dispatch('getMenu');
  },
  mounted: function () {
    setTimeout(() => {
      if (this.$store.getters.menuList.length > 0) {
        let path = window.location.pathname
        let inList = false
        for (let i = 0; i < this.$store.getters.menuList.length; i++) {
          if (this.$store.getters.menuList[i].url === path) {
            inList = true
            this.activeMenuId = this.$store.getters.menuList[i].id
            break
          }
        }
        if (!inList) {
          this.activeMenuId = this.$store.getters.menuList[0].id
          this.$router.replace(this.$store.getters.menuList[0].url)
        }
      }
    }, 200)

  },
  computed: {
    getMenuList() {
      return this.$store.getters.menuList
    }
  },
  methods: {
    handleClick: function (id) {
      this.activeMenuId = id
    }
  }
}
</script>


<style lang="scss" scoped>
.sidebar {
  height: 100vh;
  width: 200px;
  background-color: #001429;
  overflow-x: auto;
  overflow-y: hidden;

  .menu {
    color: #ffffff;
    padding-top: 11px;
  }

  ul.menu-container {
    padding: 0;
    margin: 0;

    .menu-item {
      color: #ffffff;
      text-decoration: none;
      font-size: 14px;
      background-color: #001529;
      display: block;
      height: 40px;
      line-height: 40px;
      padding-left: 30px;
      margin-bottom: 5px;
      cursor: pointer;
      transition: all .1s linear;
      -webkit-transition: all .1s linear;
      -moz-transition: all .1s linear;
    }

    .menu-item:hover,
    .active {
      background-color: #1890ff;
    }
  }
}
</style>
