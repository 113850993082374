<template>
    <div class="company-tab">
        <div class="search-area">
            <el-input @change="getTableData(true)" class="search-text" clearable placeholder="厂商名称" suffix-icon="el-icon-search" v-model="query.companyName"></el-input>
            <!-- <el-select class="search-company" filterable v-model="query.company" placeholder="厂商">
                <el-option v-for="item in companyList" :key="item.id" :label="item.companyNameEn" :value="item.id"></el-option>
            </el-select> -->
            <el-select @change="getTableData(true)" class="search-company-type" clearable v-model="query.companyType" placeholder="厂商类型">
                <el-option label="组件厂商" value="COMPONENT"></el-option>
                <el-option label="逆变器厂商" value="INVERTER"></el-option>
                <el-option label="汇流箱厂商" value="COMBBOX"></el-option>
            </el-select>
            <el-select @change="getTableData(true)" class="search-company-status" clearable v-model="query.status" placeholder="状态">
                <el-option label="正常" value="ENABLE"></el-option>
                <el-option label="禁用" value="DISABLE"></el-option>
            </el-select>
        </div>
        <div class="table-area" v-loading="loading">
            <el-table :data="companyData" stripe class="table" height="100" style="width: 100%">
                <el-table-column class-name="first-table-column" type="index" width="80" :index="computeIndex"></el-table-column>
                <el-table-column prop="companyNameCn" label="厂商中文名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="companyNameEn" label="厂商英文名称" show-overflow-tooltip></el-table-column>
                <el-table-column label="厂商类型" width="120">
                    <template slot-scope="scope">
                        {{getCompanyType(scope.row.companyType)}}
                    </template>
                </el-table-column>
                <el-table-column sortable label="更新时间" width="120">
                    <template slot-scope="scope">
                        {{scope.row.updatedAtStr == null ? scope.row.createdAtStr.substring(0, 10) : scope.row.updatedAtStr.substring(0, 10)}}
                    </template>
                </el-table-column>
                <el-table-column label="状态" width="80">
                    <template slot-scope="scope">
                        {{scope.row.status === 'ENABLE' ? '正常' : '禁用'}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center"  width="260">
                    <template slot-scope="scope">
                        <el-button class="btn" type="text" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button class="btn" type="text" @click="handleDelete(scope.row)">删除</el-button>
                        <el-button class="btn" type="text" @click="handleDisable(scope.row)">{{scope.row.status == 'ENABLE' ? '禁用' : '启用'}}</el-button>                        
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <el-button class="add-company" @click="handleAdd" size="small" type="primary">添加厂商</el-button>
            <!-- <el-button class="import-company" @click="importcompany" type="text">批量导入用户</el-button> -->
            <div class="page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="query.currentPage"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="query.pageSize"
                    layout="total, sizes, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <transition name="el-fade-in-linear">
            <div @click.self="handleCancel" v-show="showShadow" class="shadow">
                <div class="company-detail-dialog">
                    <transition name="el-fade-in-linear">
                        <CompanyDetail @commitSuccess="handleCommitSuccess" @cancel="handleCancel" v-if="showEditDialog" :dialogType="dialogType" :companyId="companyId"></CompanyDetail>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import CompanyDetail from './CompanyDetail'
export default {
    data(){
        return {
            query: {
                companyName: '',
                status: '',
                companyType: '',
                pageSize: 10,
                currentPage: 1
            },
            loading: false,
            companyData: [],
            total: 0,
            showShadow: false,
            showEditDialog: false,
            dialogType: 'ADD',
            companyId: null            
        }
    },
    mounted(){
        this.getTableData()
    },
    methods: {
        computeIndex(index) {
            return (this.query.currentPage - 1) * this.query.pageSize + index + 1
        },
        getCompanyType(companyType){
            switch(companyType){
                case 'COMPONENT': return '组件厂商'
                case 'INVERTER': return '逆变器厂商'
                case 'COMBBOX': return '汇流箱厂商'
            }
        },
        getTableData(reset){
            this.loading = true;
            this.$http.get(this.$apiPath.adminCompanyList,{params: this.query}).then(res => {
                var data = res.data;
                this.total = data.data.total;
                this.companyData = data.data.companyList;
                if(reset){
                    this.query.currentPage = 1;
                }
                this.loading = false;
            });
        },
        handleAdd(){
            this.showShadow = true
            this.showEditDialog = true
        },
        handleEdit(row){
            this.companyId = row.id;
            this.dialogType = 'EDIT';
            this.showEditDialog = true;
            this.showShadow = true;
        },
        handleDelete(row){
            this.$confirm('此操作将永久删除该厂商，是否继续？', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$apiPath.adminDeleteCompany, {companyId: row.id, userId: this.$store.getters.loginUserId}).then(res => {
                    if(res.status == 200 && res.data.status == 200){
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getTableData();
                    }else{
                        this.$message.error(res.data.message);
                    }
                    
                });
                
            }).catch(() => {
                //cancel
            });
        },
        handleDisable(row){
            var type = '', typeStr = '';
            if(row.status == 'ENABLE'){
                type = 'DISABLE';
                typeStr = '禁用';
            }else{
                type = 'ENABLE';
                typeStr = '启用';
            }
            this.$confirm('确认要' + typeStr +'当前厂商吗？', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(this.$apiPath.adminCompanyStatus, {companyId: row.id, type: type}).then(res => {
                    if(res.status == 200 && res.data.status == 200){
                        this.$message({
                            type: 'success',
                            message: typeStr + '成功!'
                        });
                        this.getTableData();
                    }
                });
            }).catch(() => {
                //cancel
            });
        },
        handleSizeChange(value){
            this.query.pageSize = value
            this.getTableData()
        },
        handleCurrentChange(value){
            this.query.currentPage = value
            this.getTableData()
        },
        handleCancel(){
            this.showEditDialog = false;
            this.showShadow = false;
        },
        handleCommitSuccess(){
            this.showEditDialog = false;
            this.showShadow = false;
            this.getTableData();
        },
    },
    components: {
        CompanyDetail
    }
}
</script>

<style lang="scss" scoped>
    .search-area{
        height: 32px;
        padding: 10px 14px;
        border-bottom: solid 1px #e8e8e8;

        .search-text{
            width: 285px;
        }

        .search-company-type{
            margin-left: 8px;
            width: 180px;
        }

        .search-company-status{
            margin-left: 8px;
            width: 112px;
        }
    }
    .btn{
        color: #3F51B5;
    }
    .footer{
        .add-company{
            font-size: 14px;
            line-height: 14px;
            height: 30px;
            padding: 7px 15px;
            background-color: #5C8EFF;
            margin: 14px 0 0 10px;
        }

        .import-company{
            color: #5C8EFF;
            height: 30px;
            line-height: 14px;
            padding: 7px 0;
            margin-left: 20px;
            text-decoration: underline;
        }

        .page{
            float: right;
            margin: 11px 6px 0 0;
        }
    }
    .table{
        height: calc(100vh - 270px) !important;
    } 
    .company-detail-dialog{
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -300px 0 0 -281px;
    }
</style>

<style lang="scss">
.search-area .el-input__inner{
    // height: 32px !important;
    // line-height: 30px;
}
.el-input__icon{
    line-height: 32px !important;
}
    .first-table-column{
        padding-left: 20px !important;
    }

.page{
    .el-pagination__sizes{
        height: 32px !important;;
    }
}
</style>
