<template>
  <div id="baseData" class="base-data">
    <div class="title">SaaS接口用户</div>
    <div class="container">
      <el-table
          :fit="true"
          class="table"
          ref="multipleTable"
          :data="users"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{ textAlign: 'center' }">
        <el-table-column
            type="selection"
            min-width="55">
        </el-table-column>
        <el-table-column
            label="#"
            min-width="55">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
            class="userName"
            label="用户"
            min-width="120">
          <template slot-scope="scope">{{ scope.row.companyName }}</template>
        </el-table-column>
        <el-table-column
            class="startPort"
            label="开启接口的数量"
            min-width="120">
          <template slot-scope="scope">{{ scope.row.apiNum }}</template>
        </el-table-column>
        <el-table-column
            label="已无额度接口"
            min-width="120"
        >
          <template slot-scope="scope">
            <div :style="scope.row.unApiNum === 0 ?'':'color: red' ">{{ scope.row.unApiNum }}</div>
          </template>
        </el-table-column>
        <el-table-column
            prop="address"
            label="最后调用接口的时间"
            min-width="200"
            show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.updateTime }}</template>
        </el-table-column>
        <el-table-column
            prop="address"
            label="接口可用期间"
            min-width="360"
            show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="timeRange"
                 :style="new Date(scope.row.expireTime).getTime()>new Date().getTime()?'':'color:red'">
              <span v-if="scope.row.expireTime">
              {{ scope.row.startTime.split(' ')[0] }}至{{scope.row.expireTime==='null'? `${scope.row.expireTime.split(' ')[0]}`:''}}{{ new Date(scope.row.expireTime).getTime() > new Date().getTime() ? '' : '(已过期)' }}
                </span>
              <span v-else>

              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            width="400"
            label="操作">
          <template slot-scope="scope">
            <div id="opItems">
              <el-button class="btn" type="text" @click="showTimeRange(scope.row)">可用期间管理</el-button>
              <el-button class="btn" type="text" @click="showLimit(scope.row)">额度管理</el-button>
              <el-button class="btn" type="text" @click="showBindUser(scope.row)">绑定用户</el-button>
            </div>
          </template>


        </el-table-column>

      </el-table>
      <div class="footer home_page">
        <div class="page">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next"
              :total="total">
          </el-pagination>
        </div>
      </div>

    </div>


    <el-dialog :title="`${description}`+'可用期间'" :close-on-click-modal="false" :visible.sync="showRange" width="40%">
      <el-form :model="form">
        <el-form-item style="display: flex; justify-content: space-between;align-items: center" label="接口可用期间"
                      label-width="300">
          <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              align="right"
              size="large"
              unlink-panels="true"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd ">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showRange = false">取 消</el-button>
        <el-button type="primary" @click="submitMask">确 定</el-button>
      </div>
    </el-dialog>
    <!--    //额度管理-->
    <el-dialog title="额度管理" width="80%" :close-on-click-modal="false" :visible.sync="limitDialog">
      <el-table
          :data="tableData"
          border
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{ textAlign: 'center' }"
          height="400"
      >
        <el-table-column
            label="#"
            width="55"
        >
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
            width="120"
            label="接口ID">
          <template slot-scope="scope">{{ scope.row.apiUid }}</template>
        </el-table-column>
        <el-table-column
            label="接口名称"
            width="200">
          <template slot-scope="scope">{{ scope.row.description }}</template>
        </el-table-column>
        <el-table-column
            width="100"
            label="是否可用">
          <template slot-scope="scope">{{ scope.row.state == 0 ? "禁用" : "可用" }}</template>
        </el-table-column>
        <el-table-column
            width="160"
            label="期间总额度">
          <template slot-scope="scope">{{ scope.row.state === 0 ? '-' : scope.row.totalAmount }}</template>

        </el-table-column>
        <el-table-column
            label="期间已使用"
            width="160"
        >
          <template slot-scope="scope">
            <div class="editorBtn">
              <el-button class="btn" @click="UsedHistory(scope.row)" type="text">
                {{ scope.row.state === 0 ? '-' : scope.row.periodUse }}
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="#"
            width="160"
            label="期间剩余额度">
          <template slot-scope="scope">
            <div class="residualCredit" :style="scope.row.residualCredit === 0 ? 'color:red':''">
              {{ scope.row.state === 0 ? '-' : scope.row.residualCredit }}
            </div>
          </template>

        </el-table-column>
        <el-table-column
            prop="#"
            width="160"
            label="期间调用失败次数">
          <template slot-scope="scope">
            <div class="editorBtn">
              <el-button @click="FailedHistory(scope.row)"
                         type="text">
                <span :style="scope.row.useFail === 0 ? 'color:#606266;':'color:red' ">
                {{ scope.row.state === 0 ? '-' : scope.row.useFail }}
                </span>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            width="160"
            label="累计已使用">
          <template slot-scope="scope">
            <div class="editorBtn">
              <el-button class="btn" @click="totalHistory(scope.row)" type="text">{{ scope.row.usedTotal }}</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            width="300"
            label="操作">
          <template slot-scope="scope">
            <div id="opItems">
              <el-button class="btn" v-if="scope.row.state === 0? false:true" type="text"
                         @click="showEditor(scope.row)">编辑
              </el-button>
              <el-button class="btn" type="text" @click="showPortLog(scope.row)">调整履历</el-button>
              <el-button class="btn" @click="changeState(scope.row)" v-if="scope.row.state === 1 ? 'true':'false'"
                         type="text">{{ scope.row.state === 0 ? "启用" : "禁用" }}
              </el-button>

            </div>
          </template>

        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="limitDialog = false">取 消</el-button>
      </div>
    </el-dialog>
    <!--编辑弹窗-->
    <el-dialog
        :title="`${limitForm.apiUid}${limitForm.description}`"
        :visible.sync="editPort"
        :close-on-click-modal="false"
        :append-to-body="true"
        width="50%">
      <el-form ref="form" :model="limitForm" label-width="120px">
        <el-form-item label="期间总额度">
          <el-input type="number" v-model="limitForm.totalAmount" controls-position="right" min="0"></el-input>
        </el-form-item>
        <el-form-item label="期间已使用">
          {{ limitForm.usedTotal }}
        </el-form-item>
        <el-form-item label="期间剩余额度">
          {{ limitForm.residualCredit }}
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="limitForm.remark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="shutPort">取消</el-button>
        <el-button @click="submitPort" type="primary">确定</el-button>
      </span>
    </el-dialog>
    <!--    调整履历-->
    <el-dialog
        width="80%"
        :close-on-click-modal="false"
        :append-to-body="true"
        :title="description"
        :visible.sync="portLog">
      <el-table
          :data="tablePort"
          border
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{ textAlign: 'center' }"
          max-height="300px"
      >
        <el-table-column
            width="55"
            label="#">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
            label="调整时间">
          <template slot-scope="scope">{{ scope.row.time }}</template>
        </el-table-column>
        <el-table-column
            label="调整详情">
          <template slot-scope="scope">{{ scope.row.content }}</template>
        </el-table-column>
        <el-table-column
            label="备注">
          <template slot-scope="scope">{{ scope.row.remark }}</template>
        </el-table-column>
      </el-table>
      <div class="home_page">
        <el-pagination
            @size-change="handlePortSize"
            @current-change="handlePortCurrent"
            :current-page="portCurrent"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="portPageSize"
            :total="portTotal"
            layout="total,sizes, prev, pager, next"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <!--    调用履历-->
    <el-dialog
        :close-on-click-modal="false"
        width="80%"
        :title="'【'+`${description}`+'】'+'调用履历'"
        :append-to-body="true"
        :visible.sync="portManagement">
      <div class="search" style="display: flex;justify-content: space-around;margin-bottom: 10px;
    align-items: center;">
        <div class="block">
          <span class="demonstration">调用时间</span>
          <el-date-picker

              size="mini"
              v-model="value1"
              type="daterange"
              unlink-panels="true"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="dw_1"><span class="demonstration">调用结果</span>
          <el-select v-model="valueResult" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="dw-2">
          <span class="demonstration" style="margin-left: 10px">错误类型</span>
          <el-select v-model="valueErr" placeholder="请选择">
            <el-option
                v-for="item in errType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="dw_btn">
          <el-button type="primary" @click="searchP" size="mini">确定</el-button>

        </div>
      </div>
      <el-table
          :data="tableCall"
          border
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{ textAlign: 'center' }"
          max-height="300px"
      >
        <el-table-column
            label="#"
            max-height="300px">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>

        </el-table-column>
        <el-table-column
            label="调用时间">
          <template slot-scope="scope">{{ scope.row.callTime }}</template>
        </el-table-column>
        <el-table-column
            label="调用人">
          <template slot-scope="scope">{{ scope.row.companyName }}</template>
        </el-table-column>
        <el-table-column
            label="调用结果">
          <template slot-scope="scope">
            <div :style="scope.row.statusCode ===200?'':'color:red'">{{
                scope.row.statusCode === 200 ? "成功" : "失败"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="错误类型">
          <template slot-scope="scope">{{ scope.row.errMsg }}</template>
        </el-table-column>
        <el-table-column
            label="错误详情">
          <template slot-scope="scope">{{ scope.row.errDetail }}</template>
        </el-table-column>
      </el-table>
      <div class="page  home_page">
        <!--        <div class="left"><el-button type="text" @click="importExcel" >导出为Excel</el-button></div>-->
        <div class="right">
          <el-pagination
              @size-change="handleLogPortSize"
              @current-change="handleLogPortCurrent"
              :page-sizes="[10, 20, 30, 40]"
              :current-page="logCurrent"
              :page-size="logPageSize"
              layout="total,sizes, prev, pager, next"
              :total="logTotal">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :append-to-body="true" title="绑定用户" :visible.sync="bindUser">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="绑定用户">
          <el-input v-model="textarea" rows="10" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="">
          <span><span style="color: red">*</span>通过绑定账户(手机号，多个时用逗号隔开)，决定谁可以通过SolarPV门户，在C端看到接口调用履历</span>
        </el-form-item>
        <el-form-item label="">
          <template slot-scope="scope">
            <div class="btn">
              <el-button @click="bindUser=false">取消</el-button>
              <el-button type="primary" @click="submitPhones">确认</el-button>
            </div>
          </template>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>

</template>

<script>

import axios from 'axios';
import LimitManagement from "@/pages/admin/limitManagement.vue";
// import XLSX from 'xlsx'

export default {
  components: {LimitManagement},
  data() {
    return {
      showRange: false,
      axios,
      users: [],
      userId: null,
      startTime: null,
      endTime: null,
      value1: '',
      bindUser: false,
      form: {},
      phones: [],
      textarea: '',
      limit: false,
      limitDialog: false,
      id: null,
      editPort: false,
      limitForm: {},
      tableData: [],
      portLog: false,
      tablePort: [],
      index: 0,
      description: '',
      portManagement: false,
      tableCall: [],
      pageSize: 1,
      currentPage: 1,
      portPageSize: 10,
      portCurrent: 1,
      portTotal: null,
      total: null,
      logCurrent: 1,
      logPageSize: 10,
      logTotal: null,
      valueResult: null,
      valueErr: null,
      options: [
        {
          value: '',
          label: '所有'
        },
        {
          value: "success",
          label: "成功"
        },
        {
          value: "fail",
          label: "失败"
        },
      ],
      errType:[
        {
          value: '',
          label: '所有'
        },
        {
          value: 0,
          label: '请求成功'
        },
        {
          value: 500,
          label: '未知异常'
        },
        {
          value: 100001,
          label: 'Appid或AppSecret错误'
        },
        {
          value: 100002,
          label: 'Appid已被禁用'
        },
        {
          value: 100003,
          label: 'Appid已过期'
        },
        {
          value: 403,
          label: '该接口不可用'
        },
        {
          value: 200001,
          label: '无效的参数'
        },
        {
          value: 300001,
          label: '计算失败'
        }
      ],
      // importExcle: []


    }

  },
  computed: {},


  methods: {
    // closeLimit() {
    //   this.limit = false;
    // },

    showTimeRange(row) {
      this.showRange = true
      this.userId = row.id
      this.description = row.companyName

    },

    //修改可用期间
    submitMask() {
      this.startTime = new Date(this.value1[0])
      this.endTime = new Date(this.value1[1])
      if (this.endTime.getTime() - this.startTime.getTime() <= 0) {
        this.$message({
          message: '开始时间不能大于结束时间呦！',
          type: 'warning'
        });
      } else {
        this.$message({
          message: '修改成功！',
          type: 'success'
        });
        this.$http.post(this.$apiPath.updateTimeRange,
            {
              id:this.userId,
              startTime: new Date(this.startTime.getTime() + 8 * 3600 * 1000),
              endTime: new Date(this.endTime.getTime() + 8 * 3600 * 1000)
            }
        ).then(res => {
          this.value1 = '';
          this.endTime = null;
          this.startTime = null;
          this.showRange = false
          this.$http.get(this.$apiPath.saasPort, {
            params: {
              pageIndex: this.currentPage,
              pageSize: this.pageSize
            }
          }) // '/api/data'为后端API地址
              .then(res => {
                this.users = res.data.data.list;
                this.currentPage = res.data.data.pageIndex;
                this.pageSize = res.data.data.pageSize;
                this.total = res.data.data.count
                // 处理返回的数据
              })
              .catch(error => {
                console.error('失败', error);
                // 错误处理逻辑
              });
          this.$store.commit('shutMask')
        })

      }
    },
    //显示绑定用户的弹窗
    showBindUser(row) {
      this.bindUser = true
      this.userId = row.id
      this.textarea = JSON.parse(row.phones).join(',')
    },
    submitPhones() {
      if (this.textarea === '') {
      } else {
        this.phones = this.textarea.split(',')
        const regex = /^1[3-9]\d{9}$/;
        const isTrue = this.phones.every((item) => {
          return regex.test(item);
        });

        if (isTrue) {
          this.phones = this.phones.map(Number)
          this.$http.post(this.$apiPath.bindUser, {id: this.userId, phones: this.phones}).then(res => {
            this.$message({
              type: 'success',
              message: "绑定成功"
            })
            this.initData()
          })
        } else {
          this.$message({
            type: "error",
            message: '手机号输入错误,请重新检查！'
          })
        }
        this.textarea = null
        this.bindUser = false
      }
    },
    showLimit(row) {
      this.limitDialog = true
      this.id = row.id
      this.$http.get('/api/admin/saasUser/getApiQuota', {
        params: {
          id: row.id
        }
      }) // '/api/data'为后端API地址
          .then(res => {
            this.tableData = res.data.data

            // 处理返回的数据
          })
          .catch(error => {
            console.error('失败', error);
            // 错误处理逻辑
          });
      this.$store.commit('rangeTime', {startTime: row.startTime, endTime: row.expireTime})
    },


    handleSizeChange(val) {
      this.pageSize = val
      this.$http.get(this.$apiPath.saasPort, {
        params: {
          pageIndex: this.currentPage,
          pageSize: this.pageSize
        }
      }) // '/api/data'为后端API地址
          .then(res => {

            this.users = res.data.data.list
            this.currentPage = res.data.data.pageIndex;
            this.pageSize = res.data.data.pageSize;
            this.total = res.data.data.count
            // 处理返回的数据
          })
          .catch(error => {
            console.error('失败', error);
            // 错误处理逻辑
          });
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.$http.get(this.$apiPath.saasPort, {
        params: {
          pageIndex: val,
          pageSize: this.pageSize
        }
      }) // '/api/data'为后端API地址
          .then(res => {
            this.users = res.data.data.list
            this.currentPage = res.data.data.pageIndex;
            this.pageSize = res.data.data.pageSize;
            this.total = res.data.data.count
            // 处理返回的数据
          })
          .catch(error => {
            // 错误处理逻辑
          });
    },
    //履历调用分页
    handleLogPortSize(val) {
      this.logPageSize = val
      const params = {
        apiId: this.id,
        pageIndex: this.logCurrent,
        pageSize: this.logPageSize,
        apiClientId: this.apiClientId
      }
      this.$http.get(this.$apiPath.callHistory, {params: params}).then(res => {
        this.tableCall = res.data.data.list
        this.logTotal = res.data.data.count
      })
    },
    handleLogPortCurrent(val) {
      this.logCurrent = val;
      const params = {
        apiId: this.id,
        pageIndex: this.logCurrent,
        pageSize: this.logPageSize,
        apiClientId: this.apiClientId
      }
      this.$http.get(this.$apiPath.callHistory, {params: params}).then(res => {
        this.tableCall = res.data.data.list
        this.logTotal = res.data.data.count
      })
    },

    //查看接口履历
    handlePortSize(val) {
      this.portPageSize = val
      const params = {
        apiQuotaId: this.id,
        pageIndex: this.portCurrent,
        pageSize: this.portPageSize
      }
      this.$http.get(this.$apiPath.checkPort, {params: params}).then(res => {
        this.tablePort = res.data.data.list
        this.portTotal = res.data.data.count
      })
    },
    handlePortCurrent(val) {
      this.portCurrent = val
      const params = {
        apiQuotaId: this.id,
        pageIndex: this.portCurrent,
        pageSize: this.portPageSize
      }
      this.$http.get(this.$apiPath.checkPort, {params: params}).then(res => {
        this.tablePort = res.data.data.list
        this.portTotal = res.data.data.count


      })
    },

    changeState(row) {
      if (row.state === 0) {
        row.periodUse = 0;
        row.residualCredit = 0;
        row.useFail = 0;
        row.usedTotal = 0
        this.limitForm = row
        this.editPort = true


      } else if (row.state === 1) {
        this.$confirm('确认禁用当前接口吗？')
            .then(res => {
              this.limitForm = row
              this.limitForm.totalAmount = 0
              this.limitForm.state = 1
              this.limitForm.remark = '禁用'
              this.limitForm.residualCredit = 0
              this.limitForm.useFail = 0
              this.limitForm.usedTotal = 0
              this.limitForm.periodUse = 0
              this.$http.post(this.$apiPath.editorPort, this.limitForm)
            })
            .then(_ => {
              row.state = 0
              row.periodUse = '-';
              row.residualCredit = '-';
              row.useFail = '-';
              row.usedTotal = 0
              this.$http.post(this.$apiPath.editorPort, {
                    apiClientId: row.apiClientId,
                    state: row.state,
                    id: row.id,
                    periodUse: 0,
                    totalAmount: row.totalAmount,
                    remark: '禁用',
                    usedTotal: row.usedTotal
                  },
              ).then(res => {
                this.limitForm = {}
                this.editPort = false
                this.$message({
                  type: 'success',
                  message: '已禁用'
                })
                this.$store.commit('shutEditor')
                this.$http.get(this.$apiPath.limitManagement, {params: {id: row.apiClientId}}) // '/api/data'为后端API地址
                    .then(res => {
                      this.tableData = res.data.data
                      // 处理返回的数据
                    })
                    .catch(error => {
                      // 错误处理逻辑
                    });
              })

            })
            .catch(_ => {
            });


      }
    },
    shutPort() {
      this.editPort = false
    },
    //编辑弹窗
    showEditor(item) {
      this.description = item.description
      this.limitForm = item
      this.editPort = true
    },
    submitPort() {
      if (this.limitForm.state === 0){
        this.limitForm.remark = "启用"
      }
      this.limitForm.state = 1
      this.$http.post(this.$apiPath.editorPort, {...this.limitForm, totalAmount: Number(this.limitForm.totalAmount)},
      ).then(res => {
        this.editPort = false
        this.$message({
          type: 'success',
          message: '修改成功'
        })
        this.$store.commit('shutEditor')
        this.$http.get(this.$apiPath.limitManagement, {params: {id: this.limitForm.apiClientId}}) // '/api/data'为后端API地址
            .then(res => {
              this.tableData = res.data.data
              this.limitForm = {};
              this.textarea = ''

              // 处理返回的数据
            })
            .catch(error => {
              console.error('失败', error);
              // 错误处理逻辑
            });
      })


    },
    //查看履历接口
    showPortLog(row) {
      this.portLog = true;
      this.description = row.description
      this.id = row.id
      const params = {
        apiQuotaId: row.id,
        pageIndex: this.logCurrent,
        pageSize: this.logPageSize
      }
      this.$http.get(this.$apiPath.checkPort, {params: params}).then(res => {
        this.tablePort = res.data.data.list
        this.portTotal = res.data.data.count
      })


    },
    //调用履历
    UsedHistory(row_1) {
      this.description = row_1.description
      this.id = row_1.id
      this.apiClientId = row_1.apiClientId
      const params = {
        pageIndex: 1,
        pageSize: 10,
        startTime: this.$store.state.startTime,
        endTime: this.$store.state.endTime,
        apiClientId: row_1.apiClientId,
        apiId:this.id

      }
      this.portManagement = true;
      this.$http.get(this.$apiPath.callHistory, {params: params}).then(res => {
        this.tableCall = res.data.data.list;
        this.logTotal = res.data.data.count
      })

    },
    //失败次数履历
    FailedHistory(row) {
      this.description = row.description
      this.id = row.id
      this.apiClientId = row.apiClientId
      const params = {
        pageIndex: 1,
        pageSize: 10,
        startTime: this.$store.state.startTime,
        endTime: this.$store.state.endTime,
        apiClientId: row.apiClientId,
        resultType: 'fail',
        apiId:row.id
      }
      this.portManagement = true;
      this.$http.get(this.$apiPath.callHistory, {params: params}).then(res => {
        this.tableCall = res.data.data.list
        this.logTotal = res.data.data.count
      })
    },
    //累计已使用调用履历
    totalHistory(row) {
      this.description = row.description
      this.id = row.id
      this.apiClientId = row.apiClientId
      const params = {
        apiId: row.id,
        pageIndex: 1,
        pageSize: 10,
        apiClientId: row.apiClientId,
      }
      this.portManagement = true;
      this.$http.get(this.$apiPath.callHistory, {params: params}).then(res => {
        this.tableCall = res.data.data.list
        this.logTotal = res.data.data.count
      })
    },
    //搜索
    searchP() {
      const params = {
        apiId: this.id,
        pageIndex: this.logCurrent,
        pageSize: this.logPageSize,
        apiClientId: this.apiClientId,
        startTime: `${this.value1[0].getFullYear()}-${this.value1[0].getMonth()+1}-${this.value1[0].getDate()}`,
        endTime: `${this.value1[1].getFullYear()}-${this.value1[1].getMonth()+1}-${this.value1[1].getDate()}`,
        resultType: this.valueResult,
        errType:this.valueErr,


      }
      this.$http.get(this.$apiPath.callHistory, {params: params}).then(res => {
        this.tableCall = res.data.data.list
        this.logTotal = res.data.data.count
        this.value1 = ''
        this.valueResult = null
        this.valueErr=null
      }).catch(res=>{
        this.value1 = ''
        this.valueResult = null
        this.valueErr=null
      })

    },
    //导出为excle
    // importExcel(){
    //     const headers = ['调用时间', '调用人', '调用结果','错误类型','错误详情']
    //     const data = this.tableCall.map(item => [item.name, item.age, item.gender])
    //     const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data])
    //     const workbook = XLSX.utils.book_new()
    //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    //     XLSX.writeFile(workbook, 'data.xlsx')
    //
    // }
    initData() {
      this.$http.get(this.$apiPath.saasPort, {
        params: {
          pageIndex: '1',
          pageSize: '10'
        }
      }) // '/api/data'为后端API地址
          .then(res => {
            this.users = res.data.data.list
            this.currentPage = res.data.data.pageIndex;
            this.pageSize = res.data.data.pageSize;
            this.total = res.data.data.count
            // 处理返回的数据
          })
          .catch(error => {
            console.error('失败', error);
            // 错误处理逻辑
          });
    }
  },
  created() {
    this.initData()
  },
  mounted() {
  },
}
</script>
<style lang="scss" scoped>

.base-data {
  position: relative;

  .title {
    padding: 10px 20px;
    font-size: 16px;
    height: 26px;
    line-height: 26px;
    color: #404040;
    font-weight: bold;
  }

  .container {
    position: relative;
    margin-left: 10px;
    height: calc(100% - 56px);
    background-color: #ffffff;

    .tab-header {
      height: 50px;
    }

  }
}

.bindUser {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .head {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    align-items: center;
  }

  .container {
    display: flex;
    position: relative;

    .title {
      padding: 0 10px;
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;

  }
}

.table {
  width: 100%;
  position: absolute;
  height: calc(100% - 44px)
  //margin-bottom: 54px;
}

.footer {
  border-top: 2px solid #ccc;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 44px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  padding: 10px 0;

  .add-user {
    font-size: 14px;
    line-height: 14px;
    height: 30px;
    padding: 7px 15px;
    background-color: #5C8EFF;
    margin: 14px 0 0 10px;
  }

  .import-user {
    color: #5C8EFF;
    height: 30px;
    line-height: 14px;
    padding: 7px 0;
    margin-left: 20px;
    text-decoration: underline;
  }

  .page {
    float: right;
    margin: 11px 6px 0 0;
  }
}


#opItems {
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    color: #3F51B5;

  }
}

.mask {
  width: 500px;
  height: 250px;
  background: #FFFFFF;
  position: absolute;
  top: calc(50% - 150px);
  left: calc(50% - 250px);
  border: 1px solid #606266;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .maskTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0;
    padding: 15px;
  }

  .maskData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }

  .maskBtn {
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 10px;
    border-top: 1px solid gray;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    //padding-left: 15px;
    //padding-right:15px ;
  }
}

.limitShow {

  position: absolute;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  top: 0;
  left: 0;
  border: 1px solid black;
  z-index: 100;
  box-sizing: border-box;
}

.bindUser {
  padding: 20px;
  box-sizing: border-box;
  width: 600px;
  height: 500px;
  background: #FFFFFF;
  position: absolute;
  top: calc(50% - 250px);
  left: calc(50% - 250px);
  border: 1px solid #606266;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.editorBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home_page {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
