<template>
    <div class="login-log-dialog" :v-loading="loading">
        <div class="head-part">
            <span class="title">Plus工程</span>
            <i class="el-icon-close close" @click="cancel" title="关闭"></i>
        </div>
        <div class="main-content">
            <div class="search">
                <el-input class="date" clearable size="small" v-model="queryData.keyword" placeholder="工程号或工程名称"></el-input>
                <el-button class="btn-search" @click="getListData(true)" size="small" type="primary">检索</el-button>
            </div>
            <div class="content">
                <el-table stripe cell-class-name="cell-p-20" header-cell-class-name="cell-p-20"
                    :data="tableData" height="100" class="table"
                    style="width: 100%">
                    <el-table-column type="index" width="80" label="#" :index="computeIndex"></el-table-column>
                  <el-table-column width="120" align="center" prop="id" label="工程号"></el-table-column>
                    <el-table-column align="center"
                                     prop="name"
                                     label="工程名称">
                    </el-table-column>
                  <el-table-column width="120" align="center" label="3D建模">
                    <template v-slot="scope">
                      {{scope.row['3DProject'] ? '是' : '否'}}
                    </template>
                  </el-table-column>
                    <el-table-column align="center"
                        prop="updateTime"
                        label="更新时间">
                    </el-table-column>
                </el-table>
            </div>
            <div class="page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryData.currentPage"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="queryData.pageSize"
                    layout="total, sizes, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <div class="footer-part">
            <el-button class="btn" size="small" @click="cancel" type="info">关闭</el-button>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        userId: {
            type: Number,
            required: true
        }
    },
    data(){
        return {
            tableData: [],
            queryData: {
                keyword: '',
                currentPage: 1,
                pageSize: 10,
            },
            total: 0,
            loading: false,
        }
    },
    mounted() {
        this.getListData(true)
    },
    methods: {
        computeIndex(index) {
            return (this.queryData.currentPage - 1) * this.queryData.pageSize + index + 1
        },
        cancel(){
            this.$emit('cancel');
        },
        handleSizeChange(value){
            this.queryData.pageSize = value
            this.getListData()
        },
        handleCurrentChange(value){
            this.queryData.currentPage = value
            this.getListData()
        },
        getListData(reset) {
            if (reset) {
                this.queryData.currentPage = 1
            }
            this.queryData.userId = this.userId
            this.loading = true;
            this.$http.get(this.$apiPath.adminUserProjectLog,{params: this.queryData}).then(res => {
                let data = res.data;
                this.total = data.data.total;
                this.tableData = data.data.list;
                if(reset){
                    this.queryData.currentPage = 1;
                }
                this.loading = false;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .login-log-dialog{
        width: 750px;
        height: 530px;
        background-color: #FFFFFF;

        .head-part{
            position: relative;
            .title{
                display: block;
                font-size: 18px;
                margin-left: 18px;
                padding-top: 14px;
                color: #101010;
            }

            .close{
                position: absolute;
                color: #CECECE;
                height: 20px;
                width: 20px;
                font-size: 20px;
                right: 14px;
                top: 12px;
                cursor: pointer;
            }

            
        }

        .main-content{
            margin-top: 12px;
            height: 425px;
            position: relative;

            .search{
                padding: 0 20px 12px;
                border-bottom: #EEE 1px solid;

                .to{
                    display: inline-block;
                    height: 32px;
                    line-height: 32px;
                    width: 26px;
                    text-align: center;
                }

                .btn-search{
                    margin-left: 14px;
                    width: 80px;
                    height: 30px;
                    padding: 0;
                    font-size: 14px;
                    line-height: 30px;
                    background-color: #5C8EFF;
                }
            }

            .page{
                float: right;
                margin: 11px 6px 0 0;
            }
            
        }


        .footer-part{
            position: absolute;
            height: 54px;
            width: 100%;
            border-top: 1px solid #EEE;
            bottom: 0;
            left: 0;

            .btn{
                width: 80px;
                font-size: 14px;
                padding: 7px 15px;
                margin: 10px 12px 0 0;
                float: right;
                background-color: #686868;
                border: 1px solid #686868;
            }
        }
    }
</style>

<style lang="scss">
.cell-p-20{
    padding-left: 20px !important;
}
.date{
    width: 400px !important;
}
.content{
    .table{
        height: 324px !important;
    }
}

</style>
