<template>
  <div id="admin" class="admin">
    <Header class="header"></Header>
    <Sidebar class="sidebar"></Sidebar>
    <router-view class="main-container"></router-view>
  </div>
</template>

<script>
import Sidebar from '../components/Sidebar.vue'
import Header from '../components/Header.vue'
export default {
  components: {
    Sidebar,
    Header,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  #admin{
    height: 100vh;
    width: 100vw;
    background-color: #e4e4e6;
    position: relative;
  }
  .sidebar{
    float: left;
    height: calc(100vh - 56px);
  }
  .main-container{
    float: left;
    height: calc(100vh - 56px);
    width: calc(100vw - 200px);
  }
</style>
