import Vue from 'vue'
import App from './App.vue'
Vue.config.productionTip = false

import router from './router'
import ElementUI from 'element-ui';
Vue.use(ElementUI)
import 'element-ui/lib/theme-chalk/index.css';
import './style/index.scss'

import axios from './config/http'
import axios2 from './config/http2'
import VueAxios from 'vue-axios'
import dayjs from "dayjs"
Vue.prototype.$dayjs = dayjs;
Vue.use(VueAxios, axios)
// Vue.prototype.$http = axios2
import apiConfig from './config/api'
Vue.prototype.$apiPath = apiConfig
//axios.defaults.baseURL = 'http://127.0.0.1:8088/api/';

import store from './store'


router.beforeEach((to, from, next) => {
  if(to.path.startsWith('/admin')){
    if(!store.getters.isLogin){
      next('/login')
    }
  }
  next()
})


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
