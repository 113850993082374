<template>
  <div id="baseData" class="base-data">
    <div class="title">基础数据管理</div>
    <div class="container">
      <el-tabs class="tab-header" v-model="activeTabName" @tab-click="handleTabClick">
        <el-tab-pane stretch="true" label="组件" name="component">
          <ComponentTab v-if="activeTabName === 'component'"></ComponentTab>
        </el-tab-pane>
        <el-tab-pane label="逆变器" name="inverter">
          <InverterTab v-if="activeTabName === 'inverter'"></InverterTab>
        </el-tab-pane>
        <el-tab-pane label="汇流箱" name="combobox">
          <ComboboxTab v-if="activeTabName === 'combobox'"></ComboboxTab>
        </el-tab-pane>
        <el-tab-pane label="厂商" name="company">
          <CompanyTab v-if="activeTabName === 'company'"></CompanyTab>
        </el-tab-pane>
<!--        <el-tab-pane label="气象数据" name="weather">-->

<!--        </el-tab-pane>-->
        <el-tab-pane label="单位" name="userCompany">
          <UserCompanyTab v-if="activeTabName === 'userCompany'"></UserCompanyTab>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import ComponentTab from '../../components/ComponentTab'
import CompanyTab from '../../components/CompanyTab'
import InverterTab from '../../components/InverterTab'
import ComboboxTab from "../../components/ComboboxTab"
import UserCompanyTab from "../../components/UserCompanyTab"
export default {
  data(){
    return {
      activeTabName: 'component',
    }
  },
  methods: {
    handleTabClick(tab){
      this.activeTabName = tab.name
    }
  },
  components: {
    ComponentTab,
    CompanyTab,
    InverterTab,
    ComboboxTab,
    UserCompanyTab
  }
}
</script>

<style lang="scss" scoped>
.base-data{
  position: relative;

  .title{
      padding: 10px 20px;
      font-size: 16px;
      height: 26px;
      line-height: 26px;
      color: #404040;
      font-weight: bold;
  }

  .container{
    margin-left: 10px;
    height: calc( 100% - 56px);
    background-color: #ffffff;

    .tab-header{
      height: 50px;
    }

  }
}

</style>

<style lang="scss">
.el-tabs__header, .el-tabs__nav, .el-tabs__item{
  height: 50px !important;
  line-height: 50px !important;
  font-weight: bold !important;
}
.el-tabs__item{
  padding: 0 40px !important;
}
.el-tabs__item.is-active{
  color: #5C8EFF !important;
  border-bottom: #5C8EFF solid 2px;
}
.el-tabs__item:hover{
  color: #5C8EFF !important;
}
.el-tabs__active-bar{
  // background-color: #5C8EFF !important;
  display: none;
}
.el-tabs__header{
  margin-bottom: 0 !important;
}
.el-tabs__nav-wrap::after{
      background-color: #e8e8e8;
}
.el-tabs__content{
  overflow: visible !important;
}
    .shadow{
        position: absolute;
        top: -152px;
        left: -210px;
        height: 100vh;
        width: 100vw;
        z-index: 99;
        background-color: rgba(0, 0, 0, 0.5);
    }
</style>
