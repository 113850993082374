import { DomEditor, IDomEditor } from '@wangeditor/editor'
// import { IExtendConfig } from './interface'

function getMentionConfig (editor) {
  const { EXTEND_CONF } = editor.getConfig()
  const { mentionConfig } = EXTEND_CONF 
  return mentionConfig
}

function withMention (editor) {
  const { insertText, isInline, isVoid } = editor
  const newEditor = editor
  // console.log('hhhhhhhhh')

  // 重写 insertText
  newEditor.insertText = t => {
    // console.log(t)
    // 选过选中了 void 元素
    const elems = DomEditor.getSelectedElems(newEditor)
    const isSelectedVoidElem = elems.some(elem => newEditor.isVoid(elem))
    if (isSelectedVoidElem) {
      insertText(t)
      return
    }

    // 非 '@' 则执行默认行为
    insertText(t)
  }

  // 重写 isInline
  newEditor.isInline = elem => {
    const type = DomEditor.getNodeType(elem)
    if (type === 'mention') {
      return true
    }

    return isInline(elem)
  }

  // 重写 isVoid
  newEditor.isVoid = elem => {
    const type = DomEditor.getNodeType(elem)
    if (type === 'mention') {
      return true
    }

    return isVoid(elem)
  }

  return newEditor
}

export default withMention
