<template>
  <el-drawer title="提醒模板" size="700px" :modal="false" :visible.sync="drawer" :direction="direction" :show-close="false"
    :before-close="handleClose">
    <!-- <template slot="title">
      <div class="flex-end">
        <el-button class="top-btn" type="primary" size="default" @click="addTemplete">新增邮件模板</el-button>
      </div>
    </template> -->
    <div class="context">
      <el-table :data="tableData" style="width: 100%" class="table"  stripe>
        <el-table-column prop="title" label="模板类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="发送条件" show-overflow-tooltip>
          <template slot-scope="scope">
            <template v-if="scope.row.templateValue == null || scope.row.templateValue == 0" >
              到期前{{ scope.row.mold }}天发送
            </template>
            <template v-else >
              额度不足{{ scope.row.mold }}%发送
            </template>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="temEditHandle(scope.row)">编辑</el-button>
            <el-button type="text" v-if="scope.row.status == 1" size="small" @click="temStatusChange(scope.row.id)">启动</el-button>
            <el-button type="text" v-else size="small" @click="temStatusChange(scope.row.id)">停用</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <!-- <el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page.sync="currentPage"
        :page-sizes="[10, 20, 30]" :page-size="pageSize" layout="total, sizes, prev, pager, next" :total="totalNum">
      </el-pagination> -->
      <div class="btn">
        <el-button size="small" type="primary" @click="handleClose">取消</el-button>
      </div>
    </div>
    <el-drawer :title="innerTitle" :append-to-body="true" size="700px" :before-close="handleClose2"
      :visible.sync="innerDrawer">
      <div style="padding: 0px 20px 100px;">
        <el-form :model="dataForm" ref="dataForm" label-width="100px" size="small"  :rules="rules" label-position="left">
          <el-form-item label="发送系统" prop="systemValue">
            <el-checkbox-group v-model="dataForm.systemValue" @change="">
              <template v-if="dataForm.templateValue==0">
                <el-checkbox  label="1" >SolarPV-Plus</el-checkbox>
                <el-checkbox label="2">SolarPV-Plus 3D</el-checkbox>
                <el-checkbox label="3">SolarPV-lite</el-checkbox>
                <el-checkbox label="4">SolarPV-AIOM</el-checkbox>
              </template>
              <el-checkbox label="5">SaaS用户接口</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- {{ dataForm.mold }} -->
          <el-form-item v-if="dataForm.templateValue == 0" label="发送条件" prop="day">
            <span class="label">到期前每天发1次</span>
            <el-select v-model="dataForm.mold"  clearable collapse-tags  placeholder="请选择">
              <el-option label="3天" :value="3"></el-option>
              <el-option label="7天" :value="7"></el-option>
              <el-option label="15天" :value="15"></el-option>
              <el-option label="20天" :value="20"></el-option>
              <el-option label="30天" :value="30"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="dataForm.templateValue == 1" label="发送条件" prop="percent">
            <span class="label">额度不足时每天1次</span>
            <el-select v-model="dataForm.mold" clearable collapse-tags  placeholder="请选择">
              <el-option label="不足5%" :value="5"></el-option>
              <el-option label="不足10%" :value="10"></el-option>
              <el-option label="不足15%" :value="15"></el-option>
              <el-option label="不足20%" :value="20"></el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item label="标题" prop="title">
            <el-input v-model="dataForm.title" placeholder="请输入标题"></el-input>
          </el-form-item>
          
          
          <el-form-item label="正文" prop="content">
            <div style="margin-bottom: 20px;">
              <el-button type="primary" size="small" @click="insertVar('【用户名】','username')" >用户名</el-button>
              <el-button type="primary" size="small" @click="insertVar('系统到期/额度不足','system')" >系统到期/额度不足</el-button>
              <el-button type="primary" size="small" @click="insertVar('【公司名】','company')" >公司名</el-button>
            </div>
            <!-- {{ dataForm.content }} -->
            <div style="border: 1px solid #ccc;">
              <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
                :mode="mode" />
              <Editor style="height: 500px; overflow-y: hidden;" v-model="dataForm.content" :defaultConfig="editorConfig"
                :mode="mode" @onCreated="onEditorCreated" />
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="inner_drawer__footer">
        <el-button size="small" @click="handleClose2">取消</el-button>
        <el-button type="primary" size="small" @click="submitForm">保存</el-button>
      </div>
    </el-drawer>
  </el-drawer>
</template>
<script>
import { Boot,DomEditor } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { variableConf } from '../../../plugins/variableButton/baseModalMenu'
import module from '../../../plugins/module'

Boot.registerMenu(variableConf)
Boot.registerModule(module)
// 自定义函数，用来展示插入变量弹框
export default {
  name: 'templateDrawer',
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },
  components: { Editor, Toolbar },
  data() {
    return {
      direction: 'rtl',
      tableData: [
        {
          temType:'到期提醒',
          condition:'到期前 1天/2天/3天提醒',
          system:'SolarPV-Plus',
          status:'1',
          id:0
        },
        {
          temType:'额度不足提醒',
          condition:'额度不足提醒 5%/10%/15%/20%',
          system:'SolarPV-Plus',
          status:'0',
          id:1
        }
      ],
      // 分页
      currentPage: 1,
      pageSize: 10,
      totalNum: 0,
      innerDrawer: false,
      innerTitle: '', // 标题
      // 编辑器实例，必须用 `ref` 获取
      editor: null,
      content: '',
      toolbarConfig: {
        excludeKeys: [
          'group-image', 'group-more-style', 'group-video', 'emotion', 'insertTable', 'todo', 'codeBlock', 'blockquote'
        ],
        // insertKeys: {
        //   index: 0,
        //   keys: ['variable']
        // },
      },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {}
      },
      mode: 'default', // 或 'simple'
      // 表单
      dataForm: {
        id:'',
        title: '',
        systemValue:[],
        content: '',
        templateValue:'',
        mold: '',
        
      },
      rules: {
        mold: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        systemValue:[
          { required: true, type: 'array', message: '请选择发送系统', trigger: 'change'}
        ],
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入正文', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    let _that = this
    this.editorConfig.MENU_CONF['uploadImage'] = {
      server: this.$apiPath.adminNewsImgUpload,
      // 小于该值就插入 base64 格式（而不上传），默认为 0
      base64LimitSize: 5 * 1024, // 5kb
      async customUpload(file, insertFn) {
        let formData = new FormData()
        formData.append('file', file)
        let ret = await _that.$http.post(_that.$apiPath.adminNewsImgUpload, formData)
        if (ret.status === 200 && ret.data.errno === 0) {
          insertFn(ret.data.data.url, '', '')
        }
      }
    }
    this.editorConfig.MENU_CONF['insertImage'] = {
      onInsertedImage(imageNode) {
        if (imageNode == null) return
        const { src } = imageNode
        _that.insertedImg.push(src)
        console.log('inserted image', src)
      }
    }
    this.editorConfig.MENU_CONF['uploadVideo'] = {
      server: this.$apiPath.adminNewsVideoUpload,
      async customUpload(file, insertFn) {
        let formData = new FormData()
        formData.append('file', file)
        let ret = await _that.$http.post(_that.$apiPath.adminNewsVideoUpload, formData)
        if (ret.status === 200 && ret.data.errno === 0) {
          insertFn(ret.data.data.url, '', '')
        }
      }
    }
    this.editorConfig.MENU_CONF['insertVideo'] = {
      onInsertedVideo(videoNode) {
        if (videoNode == null) return

        const { src } = videoNode
        console.log('inserted video', src)
      }
    }
  },
  computed: {

  },
  methods: {
    // 获取模板列表
    getTemplateList(){
      this.$http.get(this.$apiPath.adminEmailTemplateList).then(res=>{
        if(res.status==200){
          this.tableData = res.data.data
        }
      })
    },
    // 修改模板状态
    temStatusChange(status){
      this.$confirm(`是否${status==1?'启用':'停用'}该模板?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.get(this.$apiPath.adminEmailTemplateStatus+`/${status}`).then(res=>{
          if(res.status==200){
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.getTemplateList()
          }
        })
      })
      .catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });          
      });
    },
    // 关闭外部抽屉
    handleClose() {
      this.$emit("update:drawer", false)
    },
    // 关闭内部抽屉
    handleClose2() {
      this.innerDrawer = false
    },
    onEditorCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    //编辑模板
    temEditHandle(data) {
      this.innerDrawer = true
      console.log(data,data.templateValue==0);
      let content = data.content.replace('.{username}','<span data-var-islimit="0" data-w-e-type="mention" data-w-e-is-void="" data-w-e-is-inline="" data-value="【用户名】">【用户名】</span>')
        .replace('.{systems}','<span data-var-isLimit="1" data-w-e-type="mention" data-w-e-is-void data-w-e-is-inline data-value="1、【系统名/接口名】将于【x天后】到期；（有多个系统则自动循环多条）">1、【系统名/接口名】将于【x天后】到期；（有多个系统则自动循环多条）</span>')
        .replace('.{company}','<span data-var-islimit="0" data-w-e-type="mention" data-w-e-is-void="" data-w-e-is-inline="" data-value="【公司名】">【公司名】</span>')
      if(data.templateValue == null || data.templateValue == 0){
        this.innerTitle = '到期提醒邮件模板'
        this.dataForm = {
          mold: data.mold,
          templateValue:0,
          title: data.title,
          systemValue:data.systemValue.split(','),
          content:content,
          id:data.id
        }
      }else{
        this.innerTitle = '额度不足提醒邮件模板'
        this.dataForm = {
          mold: data.mold,
          templateValue:1,
          title: data.title,
          // system:[],
          content: data.content
        }
      }
      
    },
    // 提交模板
    submitForm(){
      let doc = document.createElement('p')
      doc.innerHTML = this.editor.getHtml()
      let system = document.createElement('div')
      system.innerHTML ='.{systems}'
      if(doc.querySelector('span[data-var-islimit="1"]')){
        doc.querySelector('span[data-var-islimit="1"]').parentElement.innerHTML = system.innerHTML
      }
      let users = doc.querySelectorAll('span[data-value="【用户名】"]')
      console.log(users);
      let companies = doc.querySelectorAll('span[data-value="【公司名】"]')
      for(let i=0;i<users.length;i++){
        let temp = users[i].parentElement.innerHTML
        users[i].parentElement.innerHTML = temp.replace('<span data-var-islimit="0" data-w-e-type="mention" data-w-e-is-void="" data-w-e-is-inline="" data-value="【用户名】">【用户名】</span>','.{username}')
      }
      for(let i=0;i<companies.length;i++){
        let company = companies[i].parentElement.innerHTML
        companies[i].parentElement.innerHTML = company.replace('<span data-var-islimit="0" data-w-e-type="mention" data-w-e-is-void="" data-w-e-is-inline="" data-value="【公司名】">【公司名】</span>','.{company}')
      }
      
      console.log(doc.innerHTML);
      // return
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http.post(this.$apiPath.adminEmailTemplate, {
            title:this.dataForm.title,
            mold:this.dataForm.mold,
            systemValues:this.dataForm.systemValue,
            content:doc.innerHTML,
            id:this.dataForm.id
          }).then(res => {
            if (res.status === 200) {
              this.$refs.dataForm.resetFields()
              this.$message.success('操作成功')
              this.innerDrawer = false
              this.getTemplateList()
            }
          })
        } else {
          return false
        }
      })
    },
    //分页
    sizeChange(val) {
      this.pageSize = val
    },
    currentChange(val) {
      this.currentPage = val
    },
    // 插入文字
    insertVar(val,thText){
      if(this.editor.getHtml().indexOf('data-var-islimit="1"')!=-1&&thText == 'system'){
        this.$message.error('系统额度不足模板只能插入一次')
        return
      }
      if(thText == 'system'){
        val = '1、【系统名/接口名】将于【x天后】到期；（有多个系统则自动循环多条）'
      }
      let variableElem = {
        type: 'mention',
        thText:thText,
        isLimit:thText == 'system' ? 1 : 0,
        value: `${val}`,
        children: [{ text: '' }]
      }
      if (this.editor == null) return
      this.editor.restoreSelection()
      this.editor.insertNode(variableElem)
      // 光标移动一位
      this.editor.move(1)
      
    }
  },
}
</script>
<style lang="scss" scoped>
.flex-end {
  display: flex;
  justify-content: flex-end;

  .top-btn {
    width: 140px;
  }
}

.context {
  padding: 0 20px;
  // height: calc(100% - 115px);
  overflow-y: auto;

  .table {
    border: 1px solid #EBEEF5;
    border-bottom: none;
    // height: 100% !important;
  }
}

.footer {
  background: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 700px;
  border-top: 1px solid #EBEEF5;
  height: 60px;
  text-align: right;
  padding: 15px;
  box-sizing: border-box;

  .el-pagination {
    text-align: right;
  }
  .btn {
    // margin-top: 20px;
    text-align: right;
    margin-right: 20px;
    .el-button {
      width: 88px;
    }
  }
}
.label{
  margin-right: 10px;
  font-size: 14px;
  color: #606266;
}
.inner_drawer__footer {
  background: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 700px;
  border-top: 1px solid #EBEEF5;
  height: 60px;
  text-align: right;
  padding: 15px;
  box-sizing: border-box;
  .el-button {
    width: 88px;
  }
}

</style>
