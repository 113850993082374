import { IDomEditor, IButtonMenu } from '@wangeditor/editor'

export default class MyButtonMenu {
    constructor () {
      this.title = '用户' // 自定义菜单标题
      this.iconSvg = '<svg t="1689149013419" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1444" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="200"><path d="M863.328262 481.340895l-317.344013 0.099772L545.984249 162.816826c0-17.664722-14.336138-32.00086-32.00086-32.00086s-31.99914 14.336138-31.99914 32.00086l0 318.400215-322.368714-0.17718c-0.032684 0-0.063647 0-0.096331 0-17.632039 0-31.935493 14.239806-32.00086 31.904529-0.096331 17.664722 14.208843 32.031824 31.871845 32.095471l322.59234 0.17718 0 319.167424c0 17.695686 14.336138 32.00086 31.99914 32.00086s32.00086-14.303454 32.00086-32.00086L545.982529 545.440667l317.087703-0.099772c0.063647 0 0.096331 0 0.127295 0 17.632039 0 31.935493-14.239806 32.00086-31.904529S880.960301 481.404542 863.328262 481.340895z" fill="#575B66" p-id="1445"></path></svg>' // 可选
      this.tag = 'button'

      this.$ele = document.createElement('div')
    }

    // 点击菜单时触发的函数
    exec (editor, value) {
      console.log(11);
    }

    // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
    getValue (editor) {
      return false
    }

    // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
    isActive (editor) { 
      return false
    }

    // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
    isDisabled (editor) {
      return false
    }
}

export const variableConf = {
  key: 'variable',
  factory () {
    return new MyButtonMenu()
  }
}
