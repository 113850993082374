<template>
  <div id="header" class="header">
    <div class="logo">
      <img src="../assets/admin-logo.png" alt="">
    </div>
    <nav class="nav">
      <span class="user">{{userName}}</span>
      <div class="nav-menu-container">
        <el-dropdown class="nav-menu" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <i class="el-icon-user-solid user-icon"></i>
            <i class="el-icon-caret-bottom el-icon-right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="changePassword">修改密码</el-dropdown-item>
            <el-dropdown-item divided command="logout">退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </nav>
    <transition name="el-fade-in-linear">
        <div @click.self="handleCancel" v-if="showChangePasswordDialog" class="shadow">
            <transition name="el-fade-in-linear">
                <changePasswordDialog class="change-password-dialog" @commitSuccess="handleCommitSuccess" @cancel="handleCancel" v-if="showChangePasswordDialog"></changePasswordDialog>
            </transition>
        </div>
    </transition>
  </div>
</template>

<script>
  import changePasswordDialog from './ChangePassword'

  export default {
    data(){
        return {
          showChangePasswordDialog: false,
        }
    },
    computed: {
      userName(){
        return this.$store.getters.loginName
      },
      userId(){
        return this.$store.getters.loginUserId
      }
    },
    methods: {
      handleCommand(command){
        if(command === 'logout'){
          this.$store.commit('logout');
          this.$router.replace('/login');
        }else if(command === 'changePassword'){
          this.showChangePasswordDialog = true;
        }
      },
      handleCancel(){
        this.showChangePasswordDialog = false;
      },
      handleCommitSuccess(){
        this.showChangePasswordDialog = false;
      },
    },
    components: {
      changePasswordDialog
    }
  }
</script>

<style lang="scss" scoped>
  $headerHeight: 56px;
  $logoWidth: 200px;

  .header {
    height: $headerHeight;
    width: 100%;
    background-color: #ffffff;
    position: relative;
    // width: 100%;
  }

  .logo{
    width: $logoWidth;
    background-color: #002140;
    img {
      width: 172px;
      height: 30px;
      margin: 13px 0 0 15px;
    }
  }

  .logo {
    height: $headerHeight;
    float: left;
  }

  .nav {
    float: right;
    height: $headerHeight;

    .user {
      float: left;
      height: $headerHeight;
      line-height: $headerHeight;
      font-size: 14px;
      padding: 0 12px;
      color: #606266;
    }

    .nav-menu-container {
      float: left;
      position: relative;
      cursor: pointer;
      height: $headerHeight;
      line-height: $headerHeight;
      margin-right: 29px;
      .nav-menu span ,.nav-menu span i{
        float: left;
        height: $headerHeight;
        line-height: $headerHeight;
      }

      .user-icon{
        font-size: 24px;
      }
    }
  }

  .shadow{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .change-password-dialog{
    position: absolute;
    top: 50%;
    margin-top: -165px;
    left: 50%;
    margin-left: -280px;
  }
</style>