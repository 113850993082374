
//用于配置API接口地址
const apiConfig = {
  adminLogin: '/api/auth/admin/login',
  adminMenuList: '/api/admin/resources/menu',
  adminUserInfo: '/api/admin/user/info',
  adminUserList: '/api/admin/user/list',
  adminDeleteUser: '/api/admin/user/delete',
  adminAddUser: '/api/admin/user/add',
  adminUpdateUser: '/api/admin/user/update',
  adminCheckPhoneExists: '/api/admin/user/checkPhoneExists',
  adminUserStatus: '/api/admin/user/status',
  adminResetUserPassword: '/api/admin/user/resetPassword',
  adminUserImportUpload: '/api/admin/user/upload',
  adminUserLoginLog: '/api/admin/user/login-log',
  adminUserProjectLog: '/api/admin/user/project-log',
  adminChangePassword: '/api/admin/sysUser/changePassword',
  adminCompanyList: '/api/admin/company/list',
  adminCompanyStatus: '/api/admin/company/status',
  adminDeleteCompany: '/api/admin/company/delete',
  adminAddCompany: '/api/admin/company/add',
  adminUpdateCompany: '/api/admin/company/update',
  adminCompanyInfo: '/api/admin/company/info',
  adminComponentList: '/api/admin/component/list',
  adminAddComponent: '/api/admin/component/add',
  adminComponentStatus: '/api/admin/component/status',
  adminComponentUpload: '/api/admin/component/upload',
  adminComponentInfo: '/api/admin/component/detail',
  adminUpdateComponent: '/api/admin/component/update',
  adminDeleteComponent: '/api/admin/component/delete',
  adminInverterList: '/api/admin/inverter/list',
  adminDownloadComponent: '/api/admin/component/download',
  adminInverterStatus: '/api/admin/inverter/status',
  adminDeleteInverter: '/api/admin/inverter/delete',
  adminInverterInfo: '/api/admin/inverter/detail',
  adminAddInverter: '/api/admin/inverter/add',
  adminUpdateInverter: '/api/admin/inverter/update',
  adminDownloadInverter: '/api/admin/inverter/download',
  adminInverterUpload: '/api/admin/inverter/upload',
  adminComboboxList: '/api/admin/combobox/list',
  adminComboboxStatus: '/api/admin/combobox/status',
  adminDeleteCombobox: '/api/admin/combobox/delete',
  adminComboboxInfo: '/api/admin/combobox/detail',
  adminAddCombobox: '/api/admin/combobox/add',
  adminUpdateCombobox: '/api/admin/combobox/update',
  adminDownloadCombobox: '/api/admin/combobox/download',
  adminUserCompanyList: '/api/admin/user-company/list',
  adminDeleteUserCompany: '/api/admin/user-company/delete',
  adminUserCompanyDetail: '/api/admin/user-company/detail',
  adminSaveUserCompany: '/api/admin/user-company/save',
  adminDownloadUserCompany: '/api/admin/user-company/download',
  adminUserCompanyStatus: '/api/admin/user-company/status',
  adminNews: '/api/admin/news',
  adminNewsImgUpload: '/api/admin/news/img/upload',
  adminNewsVideoUpload: '/api/admin/news/video/upload',
  adminNewsResDelete: '/api/admin/news/res',
  adminLesson: '/api/admin/lesson',
  adminLessonImgUpload: '/api/admin/lesson/img/upload',

  serviceServerTime: '/api/service/common/dateTime',
  serviceDownloadResource: '/api/service/common/download',
  serviceDownloadCheck: '/api/service/common/download/file/check',

  clientDictList: '/api/client/common/dict',
  //saas接口用户管理
  saasPort: '/api/admin/saasUser/getSaasUser',

  //编辑接口弹窗
  editorPort: '/api/admin/saasUser/apiEnable',
  //查看履历接口
  portLog: '/api/admin/saasUser/callHistory',
  //额度管理
  limitManagement: '/api/admin/saasUser/getApiQuota',
  //查看履历
  checkPort: '/api/admin/saasUser/getUpdateLog',
  //绑定用户
  bindUser: '/api/admin/saasUser/bindingUser',
  //更新期间
  updateTimeRange: '/api/admin/saasUser/updateUsedTime',
  //调用履历
  callHistory: '/api/admin/saasUser/callHistory',
  //错误类型
  errType: '/api/web/saasUser/getErrTypeList',

  adminExportUser: '/api/admin/user/exportUser',

  // 系统用户列表
  mailUserList: '/api/mail/user/list',
  // 邮件编辑定时发送
  adminEmailAdd: '/api/mail/sendMailWithTime',
  // 邮件编辑立即发送
  adminEmailSend: '/api/mail/sendMailRight',
  // 邮件列表
  adminEmailList: '/api/mail/mailList',
  // 邮件草稿箱列表
  adminEmailDraftList: '/api/mail/draftsList',
  // 删除邮件
  adminEmailDelete: '/api/mail',
  // 加入草稿箱
  adminEmailDraft: '/api/mail/addDrafts',
  // 定时提醒模板列表
  adminEmailTemplateList: '/api/mail/templateList',
  // 修改定时提醒模板状态
  adminEmailTemplateStatus: '/api/mail/changeMailStatus',
  // 定期模板邮件接口
  // adminEmailTemplate: '/mail/scheduledSendMail',
  adminEmailTemplate: '/api/mail/editTemplateMail',
  // 草稿箱编辑
  adminEmailDraftEdit: '/api/mail/editDrafts',
  // 邮件编辑立即发送 草稿箱
  adminDaftEmailSend:'/api/mail/sendDraftsRight',
  // 邮件编辑定时发送 草稿箱
  adminDaftEmailAdd:'/api/mail/sendDraftsWithTime',

  // 查询无人机复现工程列表
  getDroneProjectList: '/api/drone-project/list',
  // 上传3D工程文件
  upload3DProject: '/api/drone-project/upload/3DProject'
}

export default apiConfig;
