<template>
    <div id="user" class="user">
        <div class="title">
            {{title}}
        </div>
      <div class="container" v-loading="loading">
            <div class="query-header">
                <el-input placeholder="姓名/手机号/单位" @change="getTableData(true)" clearable suffix-icon="el-icon-search" size="small" class="query-input" v-model="queryData.queryString"></el-input>
                 <el-select v-model="queryData.accountTypeValue" @change="getTableData(true)" size="small" clearable placeholder="账户类型" class="account-type">
                    <el-option
                      v-for="item in accountTypes" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="queryData.userStatusValue" @change="getTableData(true)" size="small" clearable placeholder="用户状态" class="account-type">
                    <el-option
                      v-for="item in userStatus" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <el-table stripe :data="tableData" class="table" height="100"
                style="width: 100%">
                <el-table-column
                    type="selection"
                    width="50">
                </el-table-column>
                <el-table-column
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="姓名"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="phone"
                    label="手机号"
                    >
                </el-table-column>
                <el-table-column
                    prop="company"
                    label="单位"
                    >
                </el-table-column>
                <el-table-column
                    label="可用系统">
                  <template slot-scope="scope">
                    <div v-for="item in scope.row.licenses" :key="item.id"
                         :style="{color: item.expiredDate != null && new Date(item.expiredDate).getTime() < new Date().getTime() ? 'red' : ''}">
                      {{item.license}}({{item.licensePermanent ? '长期' : '至' + item.expiredDate}})
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="registerDateString"
                    label="申请/注册日期"
                    width="130">
                </el-table-column>
                <el-table-column
                    prop="loginTime"
                    label="登录次数"
                    align="center"
                    width="95">
                    <template slot-scope="scope">
                        <span class="showUserLoginLog" @click="showUserLoginLog(scope.row.id)">{{scope.row.loginTime}}</span>
                    </template>
                </el-table-column>
              <el-table-column
                  prop="projectCount"
                  label="Plus工程数"
                  align="center"
                  width="120">
                <template slot-scope="scope">
                  <span class="showUserLoginLog" @click="showUserProjectLog(scope.row.id)">{{scope.row.projectCount}}</span>
                </template>
              </el-table-column>
                <el-table-column
                    prop="projectAdmin"
                    label="管理员"
                    align="center">
                  <template v-slot="scope">
                    {{scope.row.projectAdmin ? '是' : '否'}}
                  </template>
                </el-table-column>
                <el-table-column
                    prop="accountStatusString"
                    label="用户状态"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注"
                    show-overflow-tooltip
                   >
                </el-table-column>
                <el-table-column label="操作" header-align="center" width="200">
                    <template slot-scope="scope">
                        <el-button class="btn" type="text" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button class="btn" type="text" @click="handleDelete(scope.row)">删除</el-button>
                        <el-button class="btn" type="text" @click="handleDisable(scope.row)">{{scope.row.accountStatus === 'ENABLE' ? '禁用' : '启用'}}</el-button>
                        <el-button class="btn" type="text" @click="resetPassword(scope.row)">重置密码</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="footer">
                <el-button class="add-user" @click="showUserDetailDialog" size="small" type="primary">添加用户</el-button>
                <el-button class="import-user" @click="importUser" type="text">批量导入用户</el-button>
                <el-button class="export-user" @click="exportUser" type="text">导出用户数据</el-button>
              <div class="page">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="queryData.currentPage"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="queryData.pageSize"
                        layout="total, sizes, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>

        <transition name="el-fade-in-linear">
            <div @click.self="handleCancel" v-show="showShadow" class="shadow">
                <div class="user-detail-dialog">
                    <transition name="el-fade-in-linear">
                        <userDetail @commitSuccess="handleCommitSuccess" @cancel="handleCancel" v-if="showEditDialog" :dialogType="dialogType" :userId="userId"></userDetail>
                    </transition>
                </div>
                <div class="import-user-dialog">
                    <transition name="el-fade-in-linear">
                        <importUser @importSuccess="handleImportSuccess" @cancel="handleCancel" v-if="showImportUserDialog"></importUser>
                    </transition>
                </div>
                <div class="user-login-info-dialog">
                    <transition name="el-fade-in-linear">
                        <userLoginLog @cancel="handleCancel" v-if="showUserLoginLogDialog" :userId="userId"></userLoginLog>
                    </transition>
                </div>
              <div class="user-login-info-dialog">
                <transition name="el-fade-in-linear">
                  <userProjectLog @cancel="handleCancel" v-if="showUserProjectLogDialog" :userId="userId"></userProjectLog>
                </transition>
              </div>
            </div>
        </transition>
    </div>
</template>

<script>
import userDetail from '../../components/UserDetail.vue'
import importUser from '../../components/ImportUser'
import changePassword from '../../components/ChangePassword'
import userLoginLog from '../../components/UserLoginLog'
import userProjectLog from "@/components/UserProjectLog";
export default {
  data() {
    return {
      loading: false,
      queryData: {
        queryString: '',
        accountTypeValue: '',
        userStatusValue: '',
        currentPage: 1,
        pageSize: 10,
      },
      accountTypes: [
        {
          value: 'TRIAL',
          label: '试用用户'
        },
        {
          value: 'ANNUALLY',
          label: '年租用户'
        },
        {
          value: 'PERMANENT',
          label: '长期用户'
        }
      ],
      userStatus: [
        {
          value: 'ENABLE',
          label: '启用'
        },
        {
          value: 'DISABLE',
          label: '禁用'
        }
      ],
      tableData: [],
      total: 0,
      showShadow: false,
      showEditDialog: false,
      showImportUserDialog: false,
      showUserLoginLogDialog: false,
      showUserProjectLogDialog: false,
      dialogType: 'ADD',
      userId: null
    }
  },
  computed: {
    title: function () {
      return '用户管理'
    }
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    handleEdit(row) {
      this.userId = row.id;
      this.dialogType = 'EDIT';
      this.showEditDialog = true;
      this.showShadow = true;
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该用户，是否继续？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(this.$apiPath.adminDeleteUser, {userId: row.id}).then(res => {
          if (res.status === 200 && res.data.status === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getTableData();
          }

        });

      }).catch(() => {
        //cancel
      });
    },
    handleDisable(row) {
      let type = '', typeStr = ''
      if (row.accountStatus === 'ENABLE') {
        type = 'DISABLE';
        typeStr = '禁用';
      } else {
        type = 'ENABLE';
        typeStr = '启用';
      }
      this.$confirm('确认要' + typeStr + '当前用户吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(this.$apiPath.adminUserStatus, {userId: row.id, type: type}).then(res => {
          if (res.status === 200 && res.data.status === 200) {
            this.$message({
              type: 'success',
              message: typeStr + '成功!'
            });
            this.getTableData();
          }
        });
      }).catch(() => {
        //cancel
      });
    },
    resetPassword(row) {
      this.$confirm('确认要重置当前用户的密码吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(this.$apiPath.adminResetUserPassword, {userId: row.id}).then(res => {
          if (res.status === 200 && res.data.status === 200) {
            this.$message({
              type: 'success',
              message: '已将用户' + row.phone + '的密码重置为' + row.phone.substring(row.phone.length - 6)
            });
            //this.getTableData();
          } else {
            this.$message.error(res.data.message);
          }
        });
      }).catch(() => {
        //cancel
      });
    },
    handleSizeChange(size) {
      this.queryData.pageSize = size;
      this.getTableData()
    },
    handleCurrentChange(currentPage) {
      this.queryData.currentPage = currentPage;
      this.getTableData()
    },
    handleCancel() {
      this.showEditDialog = false;
      this.showShadow = false;
      this.showImportUserDialog = false;
      this.showUserLoginLogDialog = false;
      this.showUserProjectLogDialog = false;
    },
    handleCommitSuccess() {
      this.showEditDialog = false;
      this.showShadow = false;
      this.getTableData();
    },
    handleImportSuccess() {
      this.showImportUserDialog = false;
      this.showShadow = false;
      this.getTableData();
    },
    showUserDetailDialog() {
      this.dialogType = 'ADD';
      this.userId = null;
      this.showEditDialog = true;
      this.showShadow = true;
    },
    getTableData(reset) {
      this.loading = true;
      if (this.$route.query.companyId != null) {
        this.queryData.userCompanyId = this.$route.query.companyId
      }
      this.$http.get(this.$apiPath.adminUserList, {params: this.queryData}).then(res => {
        let data = res.data;
        this.total = data.data.total;
        this.tableData = data.data.userList;
        if (reset) {
          this.queryData.currentPage = 1;
        }
        this.loading = false;
      });
    },

    //导出用户数据
    exportUser() {
      var _this = this
      this.$message({
        message: '即将开始下载',
        duration: 2000,
        onClose: function(){
          _this.$http({
            method: 'get',
            url: _this.$apiPath.adminExportUser,
            responseType: 'blob'
          }).then(res => {
            var blob = new Blob([res.data], {type: 'application/force-download'})
            var aTag = document.createElement('a')
            aTag.download = 'userInfos.xlsx'// 下载的文件名
            aTag.href = URL.createObjectURL(blob)
            aTag.click()
            URL.revokeObjectURL(blob)
          });
        }
      });
        },

        importUser(){
            this.showShadow = true;
            this.showImportUserDialog = true;
        },
        showUserLoginLog(userId){
            this.showShadow = true;
            this.showUserLoginLogDialog = true;
            this.userId = userId;
        },
        showUserProjectLog(userId){
            this.showShadow = true;
            this.showUserProjectLogDialog = true;
            this.userId = userId;
        },
    },
    components:{
        userDetail,
        importUser,
        changePassword,
        userLoginLog,
        userProjectLog
    }
}
</script>

<style lang="scss" scoped>

    .user{

        position: relative;
        .title{
            padding: 10px 20px;
            font-size: 16px;
            height: 26px;
            line-height: 26px;
            color: #404040;
            font-weight: bold;
        }

        .container{
            margin-left: 10px;
            height: calc( 100% - 56px);
            background-color: #ffffff;

            .query-header{
                padding: 12px 15px;
                border-bottom: 1px solid #ddd;
                .query-input{
                    width: 285px;
                }

                .account-type{
                    margin-left: 8px;
                    width: 180px;
                }
            }

            .footer{
                .add-user{
                    font-size: 14px;
                    line-height: 14px;
                    height: 30px;
                    padding: 7px 15px;
                    background-color: #5C8EFF;
                    margin: 14px 0 0 10px;
                }

                .import-user{
                    color: #5C8EFF;
                    height: 30px;
                    line-height: 14px;
                    padding: 7px 0;
                    margin-left: 20px;
                    text-decoration: underline;
                }

                .page{
                    float: right;
                    margin: 11px 6px 0 0;
                }
            }

        }

        .user-detail-dialog{
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -300px 0 0 -281px;
        }

        .user-login-info-dialog{
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -265px 0 0 -375px;
        }

        .import-user-dialog{
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -161px 0 0 -280px;
        }
    }
    .shadow{
        position: absolute;
        top: -56px;
        left: -200px;
        height: 100vh;
        width: 100vw;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .btn{
        color: #3F51B5;
    }
    .showUserLoginLog{
        text-decoration: underline;
        color: #3F51B5;
        cursor: pointer;
        padding: 4px 10px;
    }
.table{
    height: calc(100vh - 225px) !important;
}
</style>

<style lang="scss">
.el-table{
    color: #404040;

    thead {
        color: #686868 !important;
    }
}
.el-table .caret-wrapper{
        width: 16px !important;
}
.el-table .sort-caret {
    left: 3px !important;
}

</style>
