<template>
	<div id="baseData" class="base-data">
		<div class="title">邮件发送</div>
		<div class="container" v-loading="loading">
			<div class="query-header">
				<!-- <el-input placeholder="标题" @change="getTableData(true)" clearable suffix-icon="el-icon-search" size="small"
					class="query-input" v-model="queryData.title"></el-input> -->
			</div>
			<el-table :data="tableData" class="table" style="width: 100%" @sort-change='sortTableFun' height="100"  stripe >
				<el-table-column label="#" type="index" width="50">
				</el-table-column>
				<el-table-column prop="userName" label="收件人" align="center" show-overflow-tooltip min-width="200">
					<template slot-scope="scope">
						{{ getUserName(scope.row.userList) }}
					</template>
				</el-table-column>
				<el-table-column prop="title" label="标题" align="center" show-overflow-tooltip min-width="300">
				</el-table-column>
				<el-table-column prop="time" label="发送时间" sortable="sendTime" align="center" min-width="200">
					<template slot-scope="scope">
						{{ scope.row.sendTime && scope.row.sendTime.slice(0,16) }}
					</template>
				</el-table-column>
				<el-table-column prop="status" label="发送状态" align="center" min-width="200">
					<template slot-scope="scope">
						<span v-if="scope.row.allUserSendStatus == 3" class="status" >部分发送</span>
						<span v-if="scope.row.allUserSendStatus == 0" class="status" >发送成功</span>
						<span v-if="scope.row.allUserSendStatus == 1" style="color: red;" >发送失败</span>
						<span v-if="scope.row.allUserSendStatus == 2" style="color: #909399;" >发送中</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" min-width="200">
					<template slot-scope="scope">
						<el-button class="btn" type="text" @click="handle('edit', scope.row)">查看</el-button>
						<el-button class="btn" type="text" @click="handle('delete', scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="footer">
				<el-button class="add-news" @click="handle('add')" size="small" type="primary">添加邮件</el-button>
				<el-button class="add-news" @click="handle('daft')" size="small" type="primary">草稿箱</el-button>
				<el-button size="small" type="text" @click="handle('template')">定时邮件模板</el-button>
				<div class="page">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="queryData.currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="queryData.pageSize"
						layout="total, sizes, prev, pager, next" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		<!-- 编辑邮件 -->
		<EditDrawer ref="editDrawer" :drawer.sync="drawer" @finishHandle="onFinishHandle"></EditDrawer>
		<!-- 草稿 -->
		<DaftDrawer ref="daftDrawer" :drawer.sync="daftDrawer" @editDrawerHandle="editDrawerHandle" ></DaftDrawer>
		<!-- 模板邮件 -->
		<TemplateDrawer ref="templateDrawer" :drawer.sync="temDrawer"></TemplateDrawer>
		<!-- 查看邮件 -->
		<CheckDrawer ref="checkDrawer" :drawer.sync="checkDrawer" @checkHandle="checkHandle"></CheckDrawer>
	</div>
</template>
<script>
import CheckDrawer from './checkDrawer.vue';
import DaftDrawer from './daftDrawer.vue';
import EditDrawer from './editDrawer.vue';
import TemplateDrawer from './templateDrawer.vue';
export default {
	data() {
		return {
			loading:false,
			queryData: {
				pageSize: 10,
				currentPage: 1,
				// title: ''
			},
			tableData: [], //邮件列表
			total: 0,
			drawer: false, // 编辑邮件
			temDrawer:false, // 模板邮件
			checkDrawer:false, // 查看邮件
			daftDrawer:false, // 草稿箱
		}
	},
	components: {
		EditDrawer,
		TemplateDrawer,
		CheckDrawer,
		DaftDrawer
	},
	created() {
		this.getTableData()
	},
	computed: {

	},
	methods: {
		getUserName(userList){
			// console.log(userList);
			return userList.map(item=>item.username).join(',')
		},
		// 邮件列表
		getTableData(arg) {
			console.log(this.$http);
			// return
			this.loading = true
			this.$http.get(this.$apiPath.adminEmailList, {params:{...this.queryData,...arg}}).then(res => {
				this.loading = false
				this.tableData = res.data.data.list
				this.total = res.data.data.total
			})
		},
		// 排序
		sortTableFun(column){
			console.log(column);
			let orderByDate
			if (column) {
        if (column.prop === 'time' && column.order === 'ascending') {//这里双重判断：该字段为升序时向后端传递升序参数，反之降序
          orderByDate = 1
        } else if (column.prop === 'time' && column.order === 'descending') {
          orderByDate = 0
        }
        this.getTableData({orderByDate})
      }
		},
		// 编辑 删除
		handle(type, params) {
			switch (type) {
				case 'edit':
					console.log(params);
					if(params.allUserSendStatus != 2){
						this.checkDrawer = true
						this.$refs.checkDrawer.initMailData(params)
					}else{
						this.drawer = true
						this.$refs.editDrawer.initPreData(params)
					}

					break;
				case 'delete':
					this.$confirm('确定删除该数据吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$http.delete(this.$apiPath.adminEmailDelete+`/${params.id}`)
							.then(()=>{
								this.$message({
									type: 'success',
									message: '删除成功!'
								});
								this.getTableData();
							})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
					break;
				case 'add':
					this.drawer = true;
					break;
				case 'template':
					this.temDrawer = true;
					this.$refs.templateDrawer.getTemplateList()
					break;
				case 'daft':
					this.daftDrawer = true;
					this.$refs.daftDrawer.getDaftList()
					break;
			}
		},	
		// 添加或编辑邮件回调
		onFinishHandle(){
			this.getTableData()
			if(this.daftDrawer){
				this.$refs.daftDrawer.getDaftList()
			}
		},
		// 编辑草稿箱邮件 
		editDrawerHandle(data){
			this.drawer = true
			this.$refs.editDrawer.initPreData(data,false)
		},
		// 查看回调
		checkHandle(){
			this.getTableData()
		},
		// 分页
		handleSizeChange(pageSize) {
			this.queryData.pageSize = pageSize;
			this.queryData.currentPage = 1;
			this.getTableData();

		},	
		handleCurrentChange(page) {
			this.queryData.currentPage = page;
			this.getTableData();
		},
	},
}
</script>
<style lang="scss" scoped>
.base-data {
	position: relative;

	.title {
		padding: 10px 20px;
		font-size: 16px;
		height: 26px;
		line-height: 26px;
		color: #404040;
		font-weight: bold;
	}

	.container {
		position: relative;
		margin-left: 10px;
		height: calc(100% - 56px);
		background-color: #ffffff;

		.query-header {
			padding: 12px 15px;
			border-bottom: 1px solid #ddd;

			.query-input {
				width: 285px;
			}
		}

		.tab-header {
			height: 50px;
		}

		.table {
			height: calc(100% - 80px) !important;

			.btn {
				margin-left: 10px;
  			color: #3F51B5;
			}
		}

		.footer {
			.add-news {
				font-size: 14px;
				line-height: 14px;
				height: 30px;
				padding: 7px 15px;
				background-color: #5C8EFF;
				margin: 14px 0 0 10px;
			}

			.import-user {
				color: #5C8EFF;
				height: 30px;
				line-height: 14px;
				padding: 7px 0;
				margin-left: 20px;
				text-decoration: underline;
			}

			.page {
				float: right;
				margin: 11px 6px 0 0;
			}
		}

	}
}
</style>
