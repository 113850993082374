import { render, staticRenderFns } from "./UserCompanyDetail.vue?vue&type=template&id=20f950a6&scoped=true"
import script from "./UserCompanyDetail.vue?vue&type=script&lang=js"
export * from "./UserCompanyDetail.vue?vue&type=script&lang=js"
import style0 from "./UserCompanyDetail.vue?vue&type=style&index=0&id=20f950a6&prod&lang=scss&scoped=true"
import style1 from "./UserCompanyDetail.vue?vue&type=style&index=1&id=20f950a6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f950a6",
  null
  
)

export default component.exports