<template>
  <div id="user" class="user">
    <div class="title">
      {{ title }}
    </div>
    <div class="container" :v-loading="loading">
      <div class="query-header">
        <el-input placeholder="标题" @change="getTableData(true)" clearable suffix-icon="el-icon-search" size="small"
                  class="query-input" v-model="queryData.title"></el-input>
        <el-select v-model="queryData.placingTop" @change="getTableData(true)" size="small" clearable
                   placeholder="是否置顶" class="account-type">
          <el-option
              v-for="item in isTops" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-table stripe :data="tableData" class="table" height="100"
                style="width: 100%">
        <el-table-column
            label="#"
            type="index"
            width="50">
        </el-table-column>
        <el-table-column
            prop="title"
            label="标题"
            align="left"
            min-width="200">
        </el-table-column>
        <el-table-column
            prop="title"
            label="类型"
            align="left">
          <template v-slot="scope">
            {{scope.row.type === 'CONTENT' ? '内容' : '外链'}}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            min-width="100"
            prop="createTime"
            label="发表时间">
          <template v-slot="scope">
            {{scope.row.createTime ? scope.row.createTime.split(' ')[0] : ''}}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            min-width="100"
            label="状态" >
          <template v-slot="scope">
            {{ scope.row.status === 'ENABLE' ? '启用' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            min-width="100"
            label="是否置顶">
          <template v-slot="scope">
            {{ scope.row.placingTop ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            min-width="100"
            prop="updateTime"
            label="更新时间">
          <template v-slot="scope">
            {{scope.row.updateTime ? scope.row.updateTime.split(' ')[0] : ''}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template v-slot="scope">
            <el-button class="btn" type="text" @click="showDrawer('edit',scope.row)">编辑</el-button>
            <el-button class="btn" type="text" @click="handleDelete(scope.row)">删除</el-button>
            <el-button class="btn" type="text" @click="handleDisable(scope.row)">
              {{ scope.row.status === 'ENABLE' ? '禁用' : '启用' }}
            </el-button>
            <el-button class="btn" type="text" @click="changeTop(scope.row)">
              {{ scope.row.placingTop ? '取消置顶' : '置顶' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-button class="add-user" @click="showDrawer('add','')" size="small" type="primary">添加新闻</el-button>
        <div class="page">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryData.pageNum"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="queryData.pageSize"
              layout="total, sizes, prev, pager, next"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>

    <!--新增编辑新闻-->
    <el-drawer

        size="900px"
        :title="drawerTitle"
        :modal="false"
        :visible.sync="drawer"
        direction="rtl"
        :before-close="handleClose">
      <div style="padding:20px 20px 100px 20px;" v-loading="dialogLoading">
        <el-form :model="dataForm" ref="dataForm" label-width="100px" size="small" :rules="rules" label-position="left">
          <el-form-item label="标题" prop="title">
            <el-input v-model="dataForm.title"></el-input>
          </el-form-item>
          <el-form-item label="发表时间" prop="newsTime">
            <el-date-picker
                v-model="dataForm.createTime"
                style="width:200px"
                type="date"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="首图" prop="pic">
            <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="imgUploadSuccess">
              <el-image v-if="dataForm.img"
                        fit="cover" :src="dataForm.img" class="avatar-uploader" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <span class="ps">首图分辩率700*300</span>
          </el-form-item>

          <el-form-item label="简介">
            <el-input type="textarea" v-model="dataForm.intro"></el-input>
          </el-form-item>

          <el-form-item label="内容种类">
            <el-radio-group v-model="dataForm.type">
              <el-radio label="CONTENT">内容</el-radio>
              <el-radio label="URL">外链</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="内容" ref="newsContent">
            <!--                <Tinymce :width="750" :height="300" v-model="dataForm.newsContent"></Tinymce>-->
            <div v-show="dataForm.type === 'CONTENT'" style="border: 1px solid #ccc;">
              <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editor"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
              />
              <Editor
                  style="height: 500px; overflow-y: hidden;"
                  v-model="content"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="onEditorCreated"
              />
            </div>
            <el-input v-model="urlContent" v-if="dataForm.type === 'URL'"></el-input>

          </el-form-item>
        </el-form>
        <div
            style="background:#fff;position: fixed;bottom:0;right:0;width:900px;border-top:1px solid #EBEEF5;height:60px;text-align: right;padding:15px;box-sizing: border-box">
          <el-button class="add-user" @click="handleClose" size="small" style="width:88px;">取 消</el-button>
          <el-button class="add-user" @click="handleConfirm" size="small" type="primary" style="width:88px;"
                     :loading="btnLoading">提 交
          </el-button>
        </div>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dataForm.img" alt="">
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
// import Tinymce from '../../components/Tinymce/index'
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'

export default {
  components: {Editor, Toolbar},
  data() {
    return {
      editor: null,
      toolbarConfig: {},
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {}
      },
      mode: 'default', // or 'simple'
      loading: false,
      queryData: {
        title: '',
        placingTop: '',
        pageNum: 1,
        pageSize: 10,
      },
      dialogVisible: false,
      uploadUrl: this.$apiPath.adminNewsImgUpload,
      isTops: [{value: 0, label: '否'}, {value: 1, label: '是'},],
      tableData: [],
      total: 0,
      drawer: false,
      dialogType: '',
      userId: null,
      drawerTitle: '',
      content: '',
      urlContent: '',
      dataForm: {
        id: 0,
        title: '',
        img: "",
        content: "",
        createTime: "",
        updateTime: '',
        placingTop: false,
        type: 'CONTENT',
        intro: '',
      },
      rules: {
        title: [{required: true, message: '此项必填', trigger: 'blur'},],
        createTime: [{required: true, message: '此项必填', trigger: 'change'},],
      },
      btnLoading: false,
      insertedImg: [],
      dialogLoading: false,
    }
  },
  computed: {
    title: function () {
      return '新闻动态'
    }
  },
  mounted() {
    let _that = this
    this.getTableData()
    this.editorConfig.MENU_CONF['uploadImage'] = {
      server: this.$apiPath.adminNewsImgUpload,
      // 小于该值就插入 base64 格式（而不上传），默认为 0
      base64LimitSize: 5 * 1024, // 5kb
      async customUpload(file, insertFn) {
        let formData = new FormData()
        formData.append('file', file)
        let ret = await _that.$http.post(_that.$apiPath.adminNewsImgUpload, formData)
        if (ret.status === 200 && ret.data.errno === 0) {
          insertFn(ret.data.data.url, '', '')
        }
      }
    }
    this.editorConfig.MENU_CONF['insertImage'] = {
      onInsertedImage(imageNode) {
        if (imageNode == null) return
        const {src} = imageNode
        _that.insertedImg.push(src)
        console.log('inserted image', src)
      }
    }
    this.editorConfig.MENU_CONF['uploadVideo'] = {
      server: this.$apiPath.adminNewsVideoUpload,
      async customUpload(file, insertFn) {
        let formData = new FormData()
        formData.append('file', file)
        let ret = await _that.$http.post(_that.$apiPath.adminNewsVideoUpload, formData)
        if (ret.status === 200 && ret.data.errno === 0) {
          insertFn(ret.data.data.url, '', '')
        }
      }
    }
    this.editorConfig.MENU_CONF['insertVideo'] = {
      onInsertedVideo(videoNode) {
        if (videoNode == null) return

        const { src } = videoNode
        console.log('inserted video', src)
      }
    }
  },
  methods: {
    imgUploadSuccess(res, file, fileLists) {
      this.dataForm.img = res.data.url
      console.log(res, file, fileLists)
    },
    onEditorCreated(editor) {
      this.editor = Object.seal(editor)
    },
    handleRemove(file, fileList) {
      this.dataForm.img = ''
    },
    handlePictureCardPreview(file) {
      this.dataForm.img = file.url
      this.dialogVisible = true
    },
    showDrawer(type, item) {
      this.dialogType = type
      if (type === 'add') {
        this.drawerTitle = '添加新闻'
      } else {
        this.drawerTitle = '编辑新闻'
        this.dialogLoading = true
        this.getNewsDetail(item.id)
      }
      this.drawer = true;

    },
    getNewsDetail(id) {
      this.$http.get(this.$apiPath.adminNews + `/${id}`).then(res => {
        this.dialogLoading = false
        if (res.data.status === 200) {
          this.dataForm = {
            ...res.data.data
          }
          this.dataForm.content = ''
          if (res.data.data.type === 'URL') {
            this.urlContent = res.data.data.content
          } else {
            this.content = res.data.data.content
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    handleClose() {
      this.$refs.dataForm.clearValidate()
      this.drawer = false;
      if (this.insertedImg.length > 0) {
        this.$http.delete(this.$apiPath.adminNewsResDelete, {
          data: {objects: this.insertedImg}
        }).then(res => {
          console.log(res)
        })
      }
      this.insertedImg = []
      this.dataForm.content = ''
      this.content = ''
      this.urlContent = ''
    },

    handleConfirm() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          let data = {
            ...this.dataForm
          }
          if (data.type === 'CONTENT') {
            data.content = this.content
          } else {
            data.content = this.urlContent
          }
          if (this.dialogType === 'add') {
            this.$http.post(this.$apiPath.adminNews, data).then((res) => {
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
              this.btnLoading = false;
              this.insertedImg = []
              this.handleClose()
              this.getTableData(true)
            }).catch(() => {
              this.btnLoading = false;
            })
          } else {
            this.$http.put(this.$apiPath.adminNews, data).then((res) => {
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
              this.btnLoading = false;
              this.insertedImg = []
              this.handleClose()
              this.getTableData(true)
            }).catch(() => {
              this.btnLoading = false;
            })
          }
        }
      })
    },


    handleEdit(row) {
      this.userId = row.id;
      this.dialogType = 'EDIT';
      this.showEditDialog = true;
      this.showShadow = true;
    },
    handleDelete(row) {
      this.$confirm('确定要删除本新闻吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete(this.$apiPath.adminNews + `/${row.id}`).then(res => {
          if (res.status === 200 && res.data.status === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getTableData();
          }
        })
      }).catch(() => {
        //cancel
      });
    },
    handleDisable(row) {
      let data = {
        ...row
      }
      if (data.status === 'ENABLE') {
        data.status = 'DISABLE'
      } else {
        data.status = 'ENABLE'
      }
      this.toggleNewsStatusOrTop(data)
    },

    changeTop(row) {
      let data = {
        ...row
      }
      data.placingTop = !data.placingTop
      this.toggleNewsStatusOrTop(data)
    },
    toggleNewsStatusOrTop(data) {
      this.$http.patch(this.$apiPath.adminNews, data).then(res => {
        if (res.status === 200 && res.data.status === 200) {
          this.$message.success('修改成功')
          this.getTableData()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    handleSizeChange(size) {
      this.queryData.pageSize = size;
      this.getTableData()
    },
    handleCurrentChange(currentPage) {
      this.queryData.pageNum = currentPage;
      this.getTableData()
    },
    getTableData(reset) {
      this.loading = true;
      if (reset) {
        this.queryData.pageNum = 1;
      }
      this.$http.get(this.$apiPath.adminNews, {params: this.queryData}).then(res => {
        let data = res.data;
        this.total = data.data.count;
        this.tableData = data.data.rows;
        this.loading = false;
      });
    },

  },

}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before {
  content: '';
  display: none;
}

.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after, .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:after {
  content: '*';
  color: #F56C6C;
  margin-left: 4px;
}

.user {

  position: relative;

  .title {
    padding: 10px 20px;
    font-size: 16px;
    height: 26px;
    line-height: 26px;
    color: #404040;
    font-weight: bold;
  }

  .container {
    margin-left: 10px;
    height: calc(100% - 56px);
    background-color: #ffffff;

    .query-header {
      padding: 12px 15px;
      border-bottom: 1px solid #ddd;

      .query-input {
        width: 285px;
      }

      .account-type {
        margin-left: 8px;
        width: 180px;
      }
    }

    .footer {
      .add-user {
        font-size: 14px;
        line-height: 14px;
        height: 30px;
        padding: 7px 15px;
        background-color: #5C8EFF;
        margin: 14px 0 0 10px;
      }

      .import-user {
        color: #5C8EFF;
        height: 30px;
        line-height: 14px;
        padding: 7px 0;
        margin-left: 20px;
        text-decoration: underline;
      }

      .page {
        float: right;
        margin: 11px 6px 0 0;
      }
    }

  }

  .user-detail-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -300px 0 0 -281px;
  }

  .user-login-info-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -265px 0 0 -375px;
  }

  .import-user-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -161px 0 0 -280px;
  }
}

.shadow {
  position: absolute;
  top: -56px;
  left: -200px;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

.btn {
  color: #3F51B5;
}

.avatar-uploader {
  width: 120px;
  height: 120px;
  background: #fafafa;
  border: 1px dashed #eee;
  display: inline-block;
  vertical-align: bottom;
}

.ps {
  padding-left: 10px;
  color: #cccccc;
  vertical-align: bottom;
  font-size: 12px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.table {
  height: calc(100vh - 225px) !important;
}
</style>

<style lang="scss">
.el-table {
  color: #404040;

  thead {
    color: #686868 !important;
  }
}

.el-table .caret-wrapper {
  width: 16px !important;
}

.el-table .sort-caret {
  left: 3px !important;
}

</style>
