import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import apiConfig from './config/api'

Vue.use(Vuex)

import createPersistedState from "vuex-persistedstate"
import {fastKey} from "core-js/internals/internal-metadata";

 const store = new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage
    })],
    state: {
        isLoginFlag: false,
        loginUser: {
            loginUsername: '',
            loginName: '',
            loginUserId: '',
        },
        loginToken: '',
        menuList: [],
        maskItem:false,
        // limit:false,
        //用户绑定显示
        bindUser:false,
        userId:null,
        //接口启用/编辑
        editPort:false,
        //履历接口弹出
        portLog:true,
        //开始时间
        startTime:null,
        //结束时间
        endTime:null

    },
    getters: {
        isLogin: state => {
            return state.isLoginFlag
        },
        loginName: state => {
            return state.loginUser.loginName
        },
        loginUsername: state => {
            return state.loginUser.loginUsername
        },
        loginToken: state => {
            return state.loginToken
        },
        loginUserId:  state => {
            return state.loginUser.loginUserId
        },
        menuList: state => {
            return state.menuList
        },
    },
     mutations: {
         login: (state, data) => {
            state.isLoginFlag = true
            state.loginUser.loginUsername = data.user.username
            state.loginUser.loginName = data.user.name
            state.loginToken = data.token
            state.loginUser.loginUserId = data.user.userId
         },
         logout: state => {
             state.isLoginFlag = false
             state.loginUser.loginUsername = ''
             state.loginUser.loginName = ''
             state.loginToken = ''
             state.loginUser.loginUserId = ''
             window.localStorage.removeItem('Token')
         },
         setMenu: (state, menu) => {
            state.menuList = menu
         },
         showMask:(state)=>{
             state.maskItem = true
         },
         shutMask:(state)=>{
             state.maskItem = false
         },
         // showLimit:(state)=>{
         //     state.limit = true
         // },
         // shutLimit:(state)=>{
         //     state.limit = false
         // },
         //控制绑定用户显示
         showBindUser:(state)=>{
             state.bindUser = true
         },
         shutBIndUser:(state)=>{
             state.bindUser = false
         },

         //获取用户ID
         getUserId:(state,userId)=>{
             state.userId = userId
         },
         //开启接口编辑/开启的弹窗
         showEditor:(state)=>{
             state.editPort = true
         },
         shutEditor:(state)=>{
             state.editPort = false
         },

         //履历接口调用
         showPortLog:(state)=>{
            state.portLog = true
         },
         shutPortLog:(state)=>{
             state.portLog = false
         },
         //期间范围
         rangeTime:(state,time)=>{
             state.startTime = time.startTime;
             state.endTime = time.endTime
         }

     },
    actions: {
        getMenu: context => {
            axios.get(apiConfig.adminMenuList + '?sysUserId=' + context.state.loginUser.loginUserId).then(res => {
                var menuList = res.data.data
                if(menuList != null){
                    context.commit('setMenu', menuList)
                }
            })
         }
    }
  });
  
  export default store