import { SlateElement } from '@wangeditor/editor'
// import { MentionElement } from './custom-types'

// 生成 html 的函数
function mentionToHtml (elem, childrenHtml) {
  // console.log(elem);
  const { value = '', isLimit = '' } = elem
  return `<span data-var-isLimit="${isLimit}" data-w-e-type="mention" data-w-e-is-void data-w-e-is-inline    data-value="${value}">${value}</span>`
}

// 配置
const conf = {
  type: 'mention', // 节点 type ，重要！！！
  elemToHtml: mentionToHtml
}

export default conf
