import axios from 'axios'
import { Message } from 'element-ui'

const service = axios.create({
})
// axios.defaults.baseURL = env === 'development' ? '/api' : window.location.protocol + '//' + window.location.host; // 配置axios请求的地址
service.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'
service.defaults.crossDomain = true
service.defaults.withCredentials = true
if (process.env.NODE_ENV === 'development') {
    // axios.defaults.baseURL = 'http://110.42.158.84:8089'
    // axios.defaults.baseURL = 'http://127.0.0.1:8089'
    // axios.defaults.baseURL = 'http://192.168.2.24:8089'

    service.defaults.baseURL = 'http://192.168.2.150:8032' //开发机
    // axios.defaults.baseURL = 'http://192.168.2.15:8089'
    // axios.defaults.baseURL = 'http://admin.test.solarpv.top'
    // axios.defaults.baseURL = ''

} else if (process.env.NODE_ENV === 'production') {
  service.defaults.baseURL = 'http://192.168.2.150:8032'
}
//配置发送请求前的拦截器 可以设置token信息
service.interceptors.request.use(
    config => {
        config.headers['Token'] = window.localStorage.getItem('Token')
        return config
    }, error => {
        return Promise.reject(error)
    }
)


// 配置响应拦截器
service.interceptors.response.use(
    res => {
        return Promise.resolve(res) // 这里直接返回res, 即接口直接返回
    }, error => {
        // 判断是否登录失效，按照实际项目的接口返回状态来判断
        if (error.toString().includes('403')) {
            Message.error('403: 拒绝访问，请重新登录')
        } else {
            Message.error(error.toString())
        }
        return Promise.reject(error);
    }
)
export default service
