<template>
    <div class="user-company-detail" v-loading="loading">
        <close-header :title="dialogType === 'ADD' ? '添加单位' : '修改单位'" @cancel="cancel"></close-header>
        <el-form size="small" :hide-required-asterisk="hideStar" :inline-message="inlineErrMsg" :rules="rules" :label-position="labelPosition" class="user-detail-form " ref="userEditDialogForm" :model="userCompany" label-width="95px">
            <el-form-item class="form-label" label="单位名" prop="name" >
                <el-input  v-model="userCompany.name"></el-input>
            </el-form-item>
            <el-form-item class="form-label" label="单位地址" prop="address">
                <el-input  v-model="userCompany.address"></el-input>
            </el-form-item>
<!--            <el-form-item class="form-label" label="单位法人" prop="legalPerson">-->
<!--                <el-input  v-model="userCompany.legalPerson"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item class="form-label" label="联系人" prop="contactPerson">
                <el-input  v-model="userCompany.contactPerson"></el-input>
            </el-form-item>
            <el-form-item class="form-label" label="联系电话" prop="phone">
                <el-input  v-model="userCompany.phone"></el-input>
            </el-form-item>
            <el-form-item class="form-label remark" label="备注" prop="remark">
                <el-input maxlength="300" show-word-limit type="textarea" :rows="5" v-model="userCompany.remark"></el-input>
            </el-form-item>
<!--            <el-form-item class="form-label" label="联系邮箱" prop="email">-->
<!--                <el-input  v-model="userCompany.email"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item class="form-label" label="传真" prop="fax">-->
<!--                <el-input  v-model="userCompany.fax"></el-input>-->
<!--            </el-form-item>-->
        </el-form>
        <div class="footer-part">
            <el-button :loading="loading" class="btn" type="primary" @click="commit">确定</el-button>
            <el-button class="btn" @click="cancel">取消</el-button>
        </div>
    </div>
</template>

<script>
import CloseHeader from './CloseHeader'
export default {
    props: {
        userCompanyId: {
            required: false,
            type: Number
        },
        dialogType: {
            type: String,
            required: true
        }
    },
    components: {
        CloseHeader
    },
    data() {
        let validatePhone = (rule, value, callback) => {
            let reg = /^1[3456789]\d{9}$/
            if(reg.test(value)){
                callback();
            }else{
                callback(new Error('请输入正确的手机号'));
            }
        }
        return {
            loading: false,
            hideStar: false,
            inlineErrMsg: false,
            labelPosition: 'left',
            rules: {
                name: [
                    { required: true, message: '请输入所在单位', trigger: 'blur' },
                    { min: 2, message: '长度最小2个字符', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { validator: validatePhone, trigger: 'blur' }
                ],
                contactPerson: [
                    { required: true, message: '请输入联系人姓名', trigger: 'blur' },
                    { min: 2, max: 20, message: '长度在2-20字符', trigger: 'blur' }
                ],
                remark: [
                    { max: 300, message: '长度不大于300字符', trigger: 'blur' }
                ]
            },
            userCompany: {
                name: '',
                address: '',
                contactPerson: '',
                phone: '',
                remark: ''
            }
        }
    },
    mounted() {
        if (this.dialogType === 'EDIT') {
            this.getUserCompanyDetail()
        }
    },
    methods: {
        cancel(){
            this.$emit('cancel');
        },
        commit() {
            this.$refs['userEditDialogForm'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.$http.post(this.$apiPath.adminSaveUserCompany, this.userCompany).then(res => {
                        if (res.status === 200 && res.data.status === 200) {
                            if (this.dialogType === 'EDIT') {
                                this.$message.success('修改单位成功')
                            } else {
                                this.$message.success('添加单位成功')
                            }
                            this.$emit('commitSuccess')
                        } else if (res.status === 200) {
                            this.$message.error(res.data.message)
                        } else  {
                            if (this.dialogType === 'EDIT') {
                                this.$message.error('修改单位失败')
                            } else {
                                this.$message.error('添加单位失败')
                            }
                        }
                        this.loading = false
                    })
                } else {
                    return false
                }
            })
        },
        getUserCompanyDetail() {
            this.loading = true
            this.$http.get(this.$apiPath.adminUserCompanyDetail, {params: {userCompanyId: this.userCompanyId}}).then(res => {
                if (res.status === 200 && res.data.status === 200) {
                    this.loading = false
                    this.userCompany = res.data.data
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.user-company-detail {
    width: 560px;
    height: 596px;
    border-radius: 3px;
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    border: 1px solid rgba(208, 208, 208, 1);
    background-color: #fff;
    position: relative;

    .user-detail-form{
        margin: 30px 0 0 30px;
    }

    .footer-part{
        position: absolute;
        height: 54px;
        width: 100%;
        border-top: 1px solid #EEE;
        bottom: 0;
        left: 0;

        .btn{
            height: 32px;
            width: 80px;
            line-height: 30px;
            font-size: 14px;
            padding: 0 20px;
            margin: 10px 12px 0 0;
            float: right;

            span{
                display: block;
                height: 30px;
                line-height: 30px;
            }
        }
    }
}
</style>


<style lang="scss">
    .remark {
        width: 500px;
    }
    .form-label, .form-label-remark{
        margin-bottom: 10px !important;
        // overflow: hidden;
        label{
            color: #000 !important;
            position: relative;
        }

        label:before{
            position: absolute;
            left: -10px;
            top: 0;
        }

        .date-pick{
            width: 285px !important;
        }
    }

    .form-label-remark{
        width: 500px;

        textarea{
            max-height: 160px;
            min-height: 38px !important;
        }

    }
    .select{

    }
    .user-detail-form{
        .el-form-item__content{
            position: relative;
    
            .el-input{
                width: 285px !important;
            }
            .el-radio{
                margin-right: 22px !important;
            }
            .el-form-item__error{
                height: 32px;
                line-height: 32px;
                position: absolute;
                left: 310px;
                top: 0;
                width: 300px;
                padding-top: 0 !important;
            }
        }
    }
</style>