<template>
  <div class="inverter-detail">
      <CloseHeader :title="getDialogType()" @cancel="cancel"></CloseHeader>
      <div class="container">
        <el-form size="small" :rules="rules" :label-position="labelPosition" class="inverter-detail-form" ref="dialogForm" :model="dialogForm" label-width="115px">
          <el-form-item class="form-label" label="厂商名称" prop="companyId">
            <el-select clearable filterable v-model="dialogForm.companyId" placeholder="厂商名称">
              <el-option v-for="item in companyList" :key="item.id" :label="item.companyNameEn" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="form-label" label="逆变器型号" prop="inverterModule">
              <el-input v-model="dialogForm.inverterModule" clearable placeholder="逆变器型号"></el-input>
          </el-form-item>
          <el-form-item class="form-label" label="逆变器种类" prop="inverterType">
            <el-select clearable v-model="dialogForm.inverterType" placeholder="逆变器种类">
                <el-option label="组串式" value="GROUP_STRING"></el-option>
                <el-option label="集中式" value="CENTRALIZED"></el-option>
                <el-option label="集散式" value="DISTRIBUTED"></el-option>
            </el-select>
          </el-form-item>
            <el-form-item class="form-label" label="额定输出电压" prop="vac">
                <el-input v-model="dialogForm.vac" clearable placeholder="额定输出电压">
                    <template slot="append">V</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="逆变器启动功率" prop="vso">
                <el-input v-model="dialogForm.pso" clearable placeholder="逆变器启动功率">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="逆变器启动电压" prop="vso">
                <el-input v-model="dialogForm.vso" clearable placeholder="逆变器启动电压">
                    <template slot="append">V</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="额定输出功率" prop="paco">
                <el-input v-model="dialogForm.paco" clearable placeholder="额定输出功率">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="最大直流输入功率" prop="pdco">
                <el-input v-model="dialogForm.pdco" clearable placeholder="最大直流输入功率">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="最大直流输入功率时电压值" prop="vdco">
                <el-input v-model="dialogForm.vdco" clearable placeholder="最大直流输入功率时电压值">
                    <template slot="append">V</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="逆变器模型参数C0" prop="c0">
                <el-input v-model="dialogForm.c0" clearable placeholder="逆变器模型参数C0">
                    <template slot="append">1 / W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="逆变器模型参数C1" prop="c1">
                <el-input v-model="dialogForm.c1" clearable placeholder="逆变器模型参数C1">
                    <template slot="append">1 / W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="逆变器模型参数C2" prop="c2">
                <el-input v-model="dialogForm.c2" clearable placeholder="逆变器模型参数C2">
                    <template slot="append">1 / V</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="逆变器模型参数C3" prop="c3">
                <el-input v-model="dialogForm.c3" clearable placeholder="逆变器模型参数C3">
                    <template slot="append">1 / V</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="夜晚逆变器消耗功率" prop="pnt">
                <el-input v-model="dialogForm.pnt" clearable placeholder="夜晚逆变器消耗功率">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>

            <el-form-item class="form-label" label="MPPT跟踪电压下限" prop="mppt_low">
                <el-input v-model="dialogForm.mppt_low" clearable placeholder="MPPT跟踪电压下限">
                    <template slot="append">V</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="MPPT跟踪电压上限" prop="mppt_high">
                <el-input v-model="dialogForm.mppt_high" clearable placeholder="MPPT跟踪电压上限">
                    <template slot="append">V</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="逆变器可以链接的最大串数" prop="num_total_line">
                <el-input v-model="dialogForm.num_total_line" clearable placeholder="逆变器可以链接的最大串数"></el-input>
            </el-form-item>
            <el-form-item class="form-label" label="MPPT端口数" prop="num_mppt">
                <el-input v-model="dialogForm.num_mppt" clearable placeholder="MPPT端口数"></el-input>
            </el-form-item>
            <el-form-item class="form-label" label="每个MPPT端口可以连接串数" prop="num_line_of_mppt">
                <el-input v-model="dialogForm.num_line_of_mppt" clearable placeholder="每个MPPT端口可以连接串数"></el-input>
            </el-form-item>
            <el-form-item class="form-label" label="是否包含拟合数据" prop="flag">
                <el-select clearable v-model="dialogForm.flag" placeholder="是否包含拟合数据">
                    <el-option label="否" value="0"></el-option>
                    <el-option label="是" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="form-label" label="直流最小输入电压" prop="vdc_min">
                <el-input v-model="dialogForm.vdc_min" clearable placeholder="直流最小输入电压">
                    <template slot="append">V</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="直流额定输入电压" prop="vdc_norm">
                <el-input v-model="dialogForm.vdc_norm" clearable placeholder="直流额定输入电压">
                    <template slot="append">V</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="最大直流输入电压" prop="vdcmax">
                <el-input v-model="dialogForm.vdcmax" clearable placeholder="最大直流输入电压">
                    <template slot="append">V</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="最大直流输入电流" prop="idcmax">
                <el-input v-model="dialogForm.idcmax" clearable placeholder="最大直流输入电流">
                    <template slot="append">A</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmin交流输出效率1" prop="eff_p1_vmin">
                <el-input v-model="dialogForm.eff_p1_vmin" clearable placeholder="Vmin交流输出效率1">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmin交流输出效率2" prop="eff_p2_vmin">
                <el-input v-model="dialogForm.eff_p2_vmin" clearable placeholder="Vmin交流输出效率2">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmin交流输出效率3" prop="eff_p3_vmin">
                <el-input v-model="dialogForm.eff_p3_vmin" clearable placeholder="Vmin交流输出效率3">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmin交流输出效率4" prop="eff_p4_vmin">
                <el-input v-model="dialogForm.eff_p4_vmin" clearable placeholder="Vmin交流输出效率4">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmin交流输出效率5" prop="eff_p5_vmin">
                <el-input v-model="dialogForm.eff_p5_vmin" clearable placeholder="Vmin交流输出效率5">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmin交流输出效率6" prop="eff_p6_vmin">
                <el-input v-model="dialogForm.eff_p6_vmin" clearable placeholder="Vmin交流输出效率6">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmin交流输出效率7" prop="eff_p7_vmin">
                <el-input v-model="dialogForm.eff_p7_vmin" clearable placeholder="Vmin交流输出效率7">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vnorm交流输出效率1" prop="eff_p1_vnorm">
                <el-input v-model="dialogForm.eff_p1_vnorm" clearable placeholder="Vnorm交流输出效率1">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vnorm交流输出效率2" prop="eff_p2_vnorm">
                <el-input v-model="dialogForm.eff_p2_vnorm" clearable placeholder="Vnorm交流输出效率2">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vnorm交流输出效率3" prop="eff_p3_vnorm">
                <el-input v-model="dialogForm.eff_p3_vnorm" clearable placeholder="Vnorm交流输出效率3">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vnorm交流输出效率4" prop="eff_p4_vnorm">
                <el-input v-model="dialogForm.eff_p4_vnorm" clearable placeholder="Vnorm交流输出效率4">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vnorm交流输出效率5" prop="eff_p5_vnorm">
                <el-input v-model="dialogForm.eff_p5_vnorm" clearable placeholder="Vnorm交流输出效率5">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vnorm交流输出效率6" prop="eff_p6_vnorm">
                <el-input v-model="dialogForm.eff_p6_vnorm" clearable placeholder="Vnorm交流输出效率6">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vnorm交流输出效率7" prop="eff_p7_vnorm">
                <el-input v-model="dialogForm.eff_p7_vnorm" clearable placeholder="Vnorm交流输出效率7">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmax交流输出效率1" prop="eff_p1_vmax">
                <el-input v-model="dialogForm.eff_p1_vmax" clearable placeholder="Vmax交流输出效率1">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmax交流输出效率2" prop="eff_p2_vmax">
                <el-input v-model="dialogForm.eff_p2_vmax" clearable placeholder="Vmax交流输出效率2">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmax交流输出效率3" prop="eff_p3_vmax">
                <el-input v-model="dialogForm.eff_p3_vmax" clearable placeholder="Vmax交流输出效率3">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmax交流输出效率4" prop="eff_p4_vmax">
                <el-input v-model="dialogForm.eff_p4_vmax" clearable placeholder="Vmax交流输出效率4">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmax交流输出效率5" prop="eff_p5_vmax">
                <el-input v-model="dialogForm.eff_p5_vmax" clearable placeholder="Vmax交流输出效率5">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmax交流输出效率6" prop="eff_p6_vmax">
                <el-input v-model="dialogForm.eff_p6_vmax" clearable placeholder="Vmax交流输出效率6">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmax交流输出效率7" prop="eff_p17_vmax">
                <el-input v-model="dialogForm.eff_p7_vmax" clearable placeholder="Vmax交流输出效率7">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>

            <el-form-item class="form-label" label="Vmin交流输出功率1" prop="out_p1_vmin">
                <el-input v-model="dialogForm.out_p1_vmin" clearable placeholder="Vmin交流输出功率1">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmin交流输出功率2" prop="out_p2_vmin">
                <el-input v-model="dialogForm.out_p2_vmin" clearable placeholder="Vmin交流输出功率2">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmin交流输出功率3" prop="out_p3_vmin">
                <el-input v-model="dialogForm.out_p3_vmin" clearable placeholder="Vmin交流输出功率3">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmin交流输出功率4" prop="out_p4_vmin">
                <el-input v-model="dialogForm.out_p4_vmin" clearable placeholder="Vmin交流输出功率4">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmin交流输出功率5" prop="out_p5_vmin">
                <el-input v-model="dialogForm.out_p5_vmin" clearable placeholder="Vmin交流输出功率5">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmin交流输出功率6" prop="out_p6_vmin">
                <el-input v-model="dialogForm.out_p6_vmin" clearable placeholder="Vmin交流输出功率6">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmin交流输出功率7" prop="out_p7_vmin">
                <el-input v-model="dialogForm.out_p7_vmin" clearable placeholder="Vmin交流输出功率7">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vnorm交流输出功率1" prop="out_p1_vnorm">
                <el-input v-model="dialogForm.out_p1_vnorm" clearable placeholder="Vnorm交流输出功率1">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vnorm交流输出功率2" prop="out_p2_vnorm">
                <el-input v-model="dialogForm.out_p2_vnorm" clearable placeholder="Vnorm交流输出功率2">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vnorm交流输出功率3" prop="out_p3_vnorm">
                <el-input v-model="dialogForm.out_p3_vnorm" clearable placeholder="Vnorm交流输出功率3">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vnorm交流输出功率4" prop="out_p4_vnorm">
                <el-input v-model="dialogForm.out_p4_vnorm" clearable placeholder="Vnorm交流输出功率4">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vnorm交流输出功率5" prop="out_p5_vnorm">
                <el-input v-model="dialogForm.out_p5_vnorm" clearable placeholder="Vnorm交流输出功率5">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vnorm交流输出功率6" prop="out_p6_vnorm">
                <el-input v-model="dialogForm.out_p6_vnorm" clearable placeholder="Vnorm交流输出功率6">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vnorm交流输出功率7" prop="out_p7_vnorm">
                <el-input v-model="dialogForm.out_p7_vnorm" clearable placeholder="Vnorm交流输出功率7">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmax交流输出功率1" prop="out_p1_vmax">
                <el-input v-model="dialogForm.out_p1_vmax" clearable placeholder="Vmax交流输出功率1">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmax交流输出功率2" prop="out_p2_vmax">
                <el-input v-model="dialogForm.out_p2_vmax" clearable placeholder="Vmax交流输出功率2">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmax交流输出功率3" prop="out_p3_vmax">
                <el-input v-model="dialogForm.out_p3_vmax" clearable placeholder="Vmax交流输出功率3">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmax交流输出功率4" prop="out_p4_vmax">
                <el-input v-model="dialogForm.out_p4_vmax" clearable placeholder="Vmax交流输出功率4">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmax交流输出功率5" prop="out_p5_vmax">
                <el-input v-model="dialogForm.out_p5_vmax" clearable placeholder="Vmax交流输出功率5">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmax交流输出功率6" prop="out_p6_vmax">
                <el-input v-model="dialogForm.out_p6_vmax" clearable placeholder="Vmax交流输出功率6">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label" label="Vmax交流输出功率7" prop="out_p17_vmax">
                <el-input v-model="dialogForm.out_p7_vmax" clearable placeholder="Vmax交流输出功率7">
                    <template slot="append">W</template>
                </el-input>
            </el-form-item>
            <el-form-item class="form-label short" label="进入市场年份" prop="marketIn">
                <el-select clearable v-model="dialogForm.marketIn" placeholder="进入市场年份">
                    <el-option v-for="item in marketInTypeList" :key="item.id" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="form-label short" label="退出市场年份" prop="marketOut">
                <el-select clearable v-model="dialogForm.marketOut" placeholder="退出市场年份">
                    <el-option v-for="item in marketOutTypeList" :key="item.id" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
      </div>
      <div class="footer-part">
        <el-button :loading="loading" class="btn" type="primary" @click="commit">确定</el-button>
        <el-button class="btn" @click="cancel">取消</el-button>
      </div>
  </div>
</template>

<script>
import CloseHeader from './CloseHeader'
export default {
  props: {
    dialogType:{
      type: String,
      required: true
    },
    inverterId:{
      type: Number,
      required: false
    },
    companyList: {
      type: Array,
      required: true
    }
  },
  data() {
    var validateFloat = (rule, value, callback) => {
      if(value && value != ''){
        // 浮点
        var padden = /^([+-])?\d+(\.\d+)?$/g
        // 科学计数法
        var paddenEx = /^[+-]?[\d]+([\.][\d]+)?([Ee][+-]?[\d]+)?$/
        if(padden.test(value) || paddenEx.test(value)){
          callback();
        }
        callback(new Error('格式不正确'));
      }
      callback();
    };
    var validatePercentage = (rule, value, callback) => {
      if(value != ''){
        var padden = /^\d+(\.\d+)?$/g
        if(padden.test(value)){
          if(value >= 0 && value <= 100){
            callback();
          }
          callback(new Error('需在0-100之间'));
        }
        callback(new Error('格式不正确'));
      }
      callback();
    };
    return {
        marketInTypeList: [],
        marketOutTypeList: [],
      dialogForm: {
          c0: null,
          c1: null,
          c2: null,
          c3: null,
          companyId: null,
          eff_p1_vmin: null,
          eff_p2_vmin: null,
          eff_p3_vmin: null,
          eff_p4_vmin: null,
          eff_p5_vmin: null,
          eff_p6_vmin: null,
          eff_p7_vmin: null,
          eff_p1_vnorm: null,
          eff_p2_vnorm: null,
          eff_p3_vnorm: null,
          eff_p4_vnorm: null,
          eff_p5_vnorm: null,
          eff_p6_vnorm: null,
          eff_p7_vnorm: null,
          eff_p1_vmax: null,
          eff_p2_vmax: null,
          eff_p3_vmax: null,
          eff_p4_vmax: null,
          eff_p5_vmax: null,
          eff_p6_vmax: null,
          eff_p7_vmax: null,
          out_p1_vmin: null,
          out_p2_vmin: null,
          out_p3_vmin: null,
          out_p4_vmin: null,
          out_p5_vmin: null,
          out_p6_vmin: null,
          out_p7_vmin: null,
          out_p1_vnorm: null,
          out_p2_vnorm: null,
          out_p3_vnorm: null,
          out_p4_vnorm: null,
          out_p5_vnorm: null,
          out_p6_vnorm: null,
          out_p7_vnorm: null,
          out_p1_vmax: null,
          out_p2_vmax: null,
          out_p3_vmax: null,
          out_p4_vmax: null,
          out_p5_vmax: null,
          out_p6_vmax: null,
          out_p7_vmax: null,
          flag: null,
          id: null,
          idcmax: null,
          inverterModule: '',
          inverterType: '',
          mppt_high: null,
          mppt_low: null,
          num_line_of_mppt: null,
          num_mppt: null,
          num_total_line: null,
          paco: null,
          pdco: null,
          pnt: null,
          pso: null,
          vac: null,
          vdc_min: null,
          vdc_norm: null,
          vdcmax: null,
          vdco: null,
          vso: null
      },
      loading: false,
      labelPosition: 'left',
      rules: {
        companyId: [
          { required: true, message: '请选择逆变器厂商', trigger: ['blur', 'change']}
        ],
          marketIn: [
              { required: true, message: '必填', trigger: 'blur' },
          ],
          vdcmax: [
              { required: true, message: '必填', trigger: 'blur' },
              { validator: validateFloat, trigger: 'blur' },
          ],
          idcmax: [
              { required: true, message: '必填', trigger: 'blur' },
              { validator: validateFloat, trigger: 'blur' },
          ],
          mppt_low: [
              { required: true, message: '必填', trigger: 'blur' },
              { validator: validateFloat, trigger: 'blur' },
          ],
          mppt_high: [
              { required: true, message: '必填', trigger: 'blur' },
              { validator: validateFloat, trigger: 'blur' },
          ],
          vdco: [
              { required: true, message: '必填', trigger: 'blur' },
              { validator: validateFloat, trigger: 'blur' },
          ],
      }
    }
  },
  methods: {
    getDialogType(){
      return this.dialogType == 'ADD' ? '添加逆变器' : '修改逆变器';
    },
    cancel(){
      this.$emit('cancel');
    },
    commit() {
        this.$refs['dialogForm'].validate((valid) => {
            if (valid) {
                this.loading = true;
                if(this.dialogType == 'ADD'){
                    this.$http.post(this.$apiPath.adminAddInverter, this.dialogForm).then(res => {
                        var data = res.data;
                        if(data.status != 200){
                            this.$message.error(data.message);
                        }else{
                            this.$message.success('添加逆变器成功');
                            this.$emit('commitSuccess');
                        }
                        this.loading = false;
                    });
                }else if(this.dialogType == 'EDIT'){
                    this.$http.post(this.$apiPath.adminUpdateInverter, this.dialogForm).then(res => {
                        var data = res.data;
                        if(data.status != 200){
                            this.$message.error(data.message);
                        }else{
                            this.$message.success('修改逆变器成功');
                            this.$emit('commitSuccess');
                        }
                        this.loading = false;
                    });
                }
            } else {
                return false;
            }
        });
    }
  },
  mounted() {
    if(this.inverterId != null){
      this.loading = true;
      this.$http.get(this.$apiPath.adminInverterInfo, {params: {inverterId: this.inverterId}}).then(res => {
        var data = res.data;
        if(data.status !== 200){
          this.loading = false;
          this.$message.error(data.message);
          return;
        }
        this.dialogForm = data.data;
        if (this.dialogForm.flag != null) {
            this.dialogForm.flag += ''
        }

        // this.dialogForm.bool_bifacialpv = data.data.bool_bifacialpv + ''
        // this.dialogForm.bool_bipv = data.data.bool_bipv + ''
        this.loading = false;
      });
    }
      this.$http.get(this.$apiPath.clientDictList, {params: {dictType: 'marketInType'}}).then(res => {
          let data = res.data
          if(data.status !== 200){
              this.loading = false
              this.$message.error(data.message)
              return
          }
          this.marketInTypeList = data.data
      })
      this.$http.get(this.$apiPath.clientDictList, {params: {dictType: 'marketInType'}}).then(res => {
          let data = res.data
          if(data.status !== 200){
              this.loading = false
              this.$message.error(data.message)
              return
          }
          this.marketOutTypeList = data.data
      })
  },
  components: {
    CloseHeader
  }
}
</script>

<style lang="scss" scoped>
.inverter-detail {
  width: 560px;
  height: 596px;
  background-color: #ffffff;

  .container {
    width: 100%;
    height: 473px;
    margin-top: 30px;
    overflow-x: hidden;
    // overflow-y: scroll;

    .inverter-detail-form {
      margin-left: 30px;
      width: 100%;
    }
  }

  .footer-part{
    position: absolute;
    height: 54px;
    width: 100%;
    border-top: 1px solid #EEE;
    bottom: 0;
    left: 0;

    .btn{
      height: 32px;
      width: 80px;
      line-height: 30px;
      font-size: 14px;
      padding: 0 20px;
      margin: 10px 12px 0 0;
      float: right;

      span{
        display: block;
        height: 30px;
        line-height: 30px;
      }
    }
  }

  .form-label, .form-label-remark{
    label{
      color: #000 !important;
      position: relative;
    }
  }

  .form-label::v-deep .el-input-group__append{
    min-width: 25px;
    text-align: center;
  }
}
</style>
