import { render, staticRenderFns } from "./News.vue?vue&type=template&id=624b4947&scoped=true"
import script from "./News.vue?vue&type=script&lang=js"
export * from "./News.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./News.vue?vue&type=style&index=1&id=624b4947&prod&lang=scss&scoped=true"
import style2 from "./News.vue?vue&type=style&index=2&id=624b4947&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "624b4947",
  null
  
)

export default component.exports