<template>
  <div>
    <el-drawer title="添加/编辑邮件" size="700px" :modal="false" :visible.sync="drawer" :direction="direction"
      :before-close="handleClose">
      <div style="padding:20px 20px 100px 20px;">
        <el-form :model="dataForm" ref="dataForm" label-width="100px" size="small" :rules="rules" label-position="left">
          <el-form-item label="收件人" prop="ids">
            <el-cascader
              style="display: block;"
              ref="cascaderRef"
              :options="userList"
              :props="props"
              v-model="dataForm.ids"
              clearable
              filterable
              collapse-tags
              :disabled="disable"
              @visible-change="visibleChange"
              @change="selectHandle"
              :show-all-levels="false">
            </el-cascader>
          </el-form-item>
          <el-form-item label="标题" prop="title">
            <el-input v-model="dataForm.title" :disabled="disable" placeholder="请输入标题" ></el-input>
          </el-form-item>
          
          <el-form-item label="正文" prop="content">
            <!-- {{ dataForm.content }} -->
            <div style="border: 1px solid #ccc;">
              <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
              <Editor style="height: 500px; overflow-y: hidden;" v-model="dataForm.content" :defaultConfig="editorConfig"
              :mode="mode" :disabled="disable" @onCreated="onEditorCreated" />
            </div>
          </el-form-item>
        </el-form>
        <div class="drawer__footer">
          <template v-if="!disable">
            <el-button size="small" @click="handleClose">取消</el-button>
            <el-button type="primary" size="small" @click="submitTimingForm(true)">定时发送</el-button>
            <el-button type="primary" size="small" :loading="loading" @click="submitTimingForm(false)">发送</el-button>
          </template>
          <template v-else>
            <el-button type="text" size="small" >于{{ datetime.slice(0,16)}}发送</el-button>
            <el-button size="small" @click="handleClose">取消</el-button>
            <el-button type="primary" size="small" @click="delHandle">删除</el-button>
            <el-button type="primary" size="small" @click="cancelSend">存草稿</el-button>
            <el-button type="primary" size="small" :loading="loading" @click="submitTimingForm(true)">发送</el-button>
          </template>
        </div>
      </div>
      
    </el-drawer>
    <el-dialog title="选择日期和时间" :visible.sync="dialogVisible" width="30%" :before-close="handleCloseDialog">
      <el-form :model="dateForm" ref="dateForm" :rules="dateRules" label-width="120px" >
        <!-- <el-form-item label="日期">
          <el-date-picker v-model="dateForm.date" type="date"  placeholder="请选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="时间">
          <el-time-picker v-model="dateForm.time"  format='HH:mm' value-format="HH:mm:ss"  placeholder="请选择日期">
          </el-time-picker>
        </el-form-item> -->
        <el-form-item label="发送日期时间" prop="datetime">
          <el-date-picker
            v-model="dateForm.datetime"
            type="datetime"
            format='yyyy-MM-dd HH:mm'
            value-format='yyyy-MM-dd HH:mm:ss'
            placeholder="选择日期时间"
            :picker-options="pickerOptions"
            :default-time="defaultTime()"
            @blur="pickerBlur" >
          </el-date-picker>
          <!-- {{ dateForm.datetime }} -->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="submitDateForm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css' // 引入 css
export default {
  name: 'editDrawer',
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Editor,
    Toolbar
  },
  data() {
    var validateWangEditor = (rule, value, callback) => {
      let flag = this.getText(value)
      if (flag === '') {
        callback(new Error('请输入正文'));
      } else {
        callback();
      }
    };
    return {
      loading:false,
      disable: false,//是否查看
      datetime:'',
      direction: 'rtl',
      dataForm: {
        ids: [],
        title: '',
        content: '',
        id:''
      },
      props: { 
        multiple: true,
      },
      userList: [], //用户列表
      rules: {
        ids: [{ required: true,type:'array', message: '请选择收件人', trigger: 'change' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        content: [{ required: true,validator: validateWangEditor, trigger: 'blur' }]
      },
      editor: null,
      insertedImg: [],
      toolbarConfig: {
        excludeKeys: [
          'group-image', 'group-more-style', 'group-video', 'emotion', 'insertTable', 'todo', 'codeBlock', 'blockquote'
        ],
      },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {}
      },
      mode: 'default',
      dateForm:{
        date:'',
        time:'',
        datetime:''
      },
      // 限制发送时间
      pickerOptions: {
        disabledDate(time) {
          // const date = new Date();
          // const oneday = date.getTime();
          return time.getTime() < new Date().getTime() - 86400000;
        },
        selectableRange: (() => {
          let data = new Date(new Date().getTime() + 10 * 60 * 1000);
          let hour = data.getHours();
          let minute = data.getMinutes();
          let second = data.getSeconds();
          return [`${hour}:${minute}:${second} - 23:59:59`]
        })(),
      },
      dateRules:{
        datetime: [{ required: true, message: '请选择发送时间', trigger: 'change' }]
      },
      dialogVisible:false,

      preSelected: [], // 上次选中的数据
      originData: [], //  源数据平铺成一级节点
      defaultExpandedKeys:[]
    }
  },
  created(){
    this.getUserList()
  },  
  mounted() {
    let _that = this
    this.editorConfig.MENU_CONF['uploadImage'] = {
      server: this.$apiPath.adminNewsImgUpload,
      // 小于该值就插入 base64 格式（而不上传），默认为 0
      base64LimitSize: 5 * 1024, // 5kb
      async customUpload(file, insertFn) {
        let formData = new FormData()
        formData.append('file', file)
        let ret = await _that.$http.post(_that.$apiPath.adminNewsImgUpload, formData)
        if (ret.status === 200 && ret.data.errno === 0) {
          insertFn(ret.data.data.url, '', '')
        }
      }
    }
    this.editorConfig.MENU_CONF['insertImage'] = {
      onInsertedImage(imageNode) {
        if (imageNode == null) return
        const { src } = imageNode
        _that.insertedImg.push(src)
        console.log('inserted image', src)
      }
    }
    this.editorConfig.MENU_CONF['uploadVideo'] = {
      server: this.$apiPath.adminNewsVideoUpload,
      async customUpload(file, insertFn) {
        let formData = new FormData()
        formData.append('file', file)
        let ret = await _that.$http.post(_that.$apiPath.adminNewsVideoUpload, formData)
        if (ret.status === 200 && ret.data.errno === 0) {
          insertFn(ret.data.data.url, '', '')
        }
      }
    }
    this.editorConfig.MENU_CONF['insertVideo'] = {
      onInsertedVideo(videoNode) {
        if (videoNode == null) return

        const { src } = videoNode
        console.log('inserted video', src)
      }
    }
    
    //if(this.$refs.dataForm){
    //  this.$refs.dataForm.resetField()
    //}
  },
  watch:{
    drawer(){
      if(!this.drawer){
        this.dataForm = {
          ids: [],
          title: '',
          content: '',
          id:''
        }
        this.dateForm = {
          date:'',
          time:'',
          datetime:''
        }
      }
    }
  },
  computed: {
    
  },
  methods: {
    // 默认时间
    defaultTime(){
      let data = new Date(new Date().getTime() + 10 * 60 * 1000);
      let hour = data.getHours();
      let minute = data.getMinutes();
      let second = data.getSeconds();
      return `${hour}:${minute}:${second}`
    },
    pickerBlur(){
      this.pickerOptions = {
        disabledDate(time) {
          return time.getTime() < new Date().getTime() - 86400000;
        },
        selectableRange: (() => {
          let data = new Date(new Date().getTime() + 10 * 60 * 1000);
          let hour = data.getHours();
          let minute = data.getMinutes();
          let second = data.getSeconds();
          return [`${hour}:${minute}:${second} - 23:59:59`]
        })(),
      }
    },
    // 初始化之前的邮件
    initPreData(val,disable = true){
      console.log(val,disable);
      this.disable = disable;
      this.dataForm = {
        ids: val.userList.map((item) =>{
          return [item.type,`${item.userId},${item.email}`]
        }),
        id:val.id,
        title: val.title,
        content: val.content,
      }
      this.datetime = val.sendTime
      setTimeout(() => {
        if(this.editor && disable){
          this.editor.disable()
        }
      }, 800);
    },
    // 判断富文本编辑器输入是否为空或回车
    getText(str) {
      return str
        .replace(/<[^<p>]+>/g, '')  // 将所有<p>标签 replace ''
        .replace(/<[</p>$]+>/g, '')  // 将所有</p>标签 replace ''
        .replace(/&nbsp;/gi, '')  // 将所有 空格 replace ''
        .replace(/<[^<br/>]+>/g, '') // 将所有 换行符 replace ''
    },
    // 获取用户列表
    getUserList() {
      this.$http.get(this.$apiPath.mailUserList).then((res) => {
        if (res.status === 200) {
        // 0：SOLARPV_PLUS，1：SOLARPV_PLUS_3D，2:SOLARPV_LITE，3：SOLARPV_AIOM，4：SaaS用户
        
          this.userList = res.data.data.map(item=>{
            let label = item.type == 0 ? 'SOLARPV_PLUS' : item.type == 1 ? 'SOLARPV_PLUS_3D' : item.type == 2 ? 'SOLARPV_LITE' : item.type == 3 ? 'SOLARPV_AIOM' : 'SaaS用户'
            return {
              label:label,
              value:item.type,
              children:item.users.map(user=>{
                return {
                  label : `${user.name}<${user.email}>`,
                  value : `${user.id},${user.email}`,
                  email : user.email
                }
              })
            }
          })
          // this.userList.unshift({
          //   label:'全部',
          //   value:'all'
          // })
          console.log(this.userList);
        }
      })
    },
    
    // 关闭抽屉
    handleClose() {
      this.dataForm.id = ""
      this.$refs.dataForm.clearValidate()
      this.$refs.dataForm.resetFields()
      this.insertedImg = []
      this.disable = false
      this.editor.enable()
      this.$emit('update:drawer', false)
    },

    onEditorCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    handleCloseDialog(){
      this.$refs.dateForm.resetFields()
      this.dialogVisible = false
    },
    // 是否定时发送
    submitTimingForm(timing){
      if(timing){
        this.$refs.dataForm.validate((valid) => {
          if(valid){
            this.dialogVisible = true
          }
        })
      }else{
        this.submitForm(false)
      }
    },
    // 时间form
    submitDateForm(){
      this.$refs.dateForm.validate((valid) => {
        if(valid){
          this.submitForm(true)
        }
      })
    },
    // 提交
    submitForm(timing) {
      console.log(this.dataForm);
      
      // timing  定时 true   立即 false
      // disable == false && dataForm.id == '' 表示新增  adminEmailAdd  adminEmailSend
      // disable == true && dataForm.id != '' 表示查看   adminEmailAdd
      // disable == false && dataForm.id != '' 表示草稿箱编辑 adminEmailDraftEdit adminDaftEmailSend adminDaftEmailAdd
      // dataForm.ids 删除掉全选
      let userType = this.dataForm.ids[0][0] == '全选' ? this.dataForm.ids.slice(1) : this.dataForm.ids
      console.log(userType,this.dataForm.ids[0][0] == '全选');
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.loading = true
          if(this.disable == false && this.dataForm.id != ''){
            this.$http.post(this.$apiPath.adminEmailDraftEdit,{
              id:this.dataForm.id,
              userType:userType.map((item)=>{
                return {
                  id:item[1].split(',')[0],
                  email:item[1].split(',')[1],
                  type:item[0]
                }
              }),
              sendTime:timing?this.dateForm.datetime:'',
              title:this.dataForm.title,
              content:this.dataForm.content
            }).then((res) => {
              if (res.status === 200) {
                this.$http.post(timing ? this.$apiPath.adminDaftEmailAdd+`/${this.dataForm.id}?sendTime=${this.dateForm.datetime}` : this.$apiPath.adminDaftEmailSend+`/${this.dataForm.id}`,)
                .then((res2)=>{
                  if(res2.status == 200){
                    this.$message({
                      message: '提交成功',
                      type: 'success'
                    })
                    this.$emit("finishHandle")
                    this.$emit('update:drawer', false)
                    this.dialogVisible = false
                  }else{
                    this.$message({
                      message: res2.message,
                      type: 'error'
                    })
                  }

                }).finally(()=>{
                  this.loading = false
                })
              }
            }).catch((err)=>{
              this.loading = false
            })
          }else{
            let apiPath = timing ? this.$apiPath.adminEmailAdd :this.$apiPath.adminEmailSend
            this.$http.post(apiPath,{
              id:this.dataForm.id || '',
              userType:userType.map((item)=>{
                return {
                  id:item[1].split(',')[0],
                  email:item[1].split(',')[1],
                  type:item[0]
                }
              }),
              sendTime:timing?this.dateForm.datetime:'',
              sendLocalDateTime:timing?this.dateForm.datetime:'',
              title:this.dataForm.title,
              content:this.dataForm.content
            }).then((res) => {
              if (res.status === 200) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                this.$emit("finishHandle")
                this.$emit('update:drawer', false)
                this.dialogVisible = false
              }
            }).finally(()=>{
              this.loading = false
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 取消发送
    cancelSend(){
      this.$confirm('是否存入草稿', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.get(this.$apiPath.adminEmailDraft+`/${this.dataForm.id}`)
        .then((res) => {
          if (res.status === 200) {
            this.$message({
              message: '成功保存到草稿箱',
              type: 'success'
            })
            this.$emit("finishHandle")
            this.$emit('update:drawer', false)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
        
      })

    },
    // 删除
    delHandle(){
      this.$confirm('确定删除该邮件吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete(this.$apiPath.adminEmailDelete+`/${this.dataForm.id}`)
        .then(()=>{
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.$emit("finishHandle")
          this.$emit('update:drawer', false)
        })
      })
    },
    // 全选
    async selectHandle(node = []) {
      this.defaultExpandedKeys = []
      this.dataForm.ids = []
      // 选中的数据格式: [['全选'], ['0'], ['1', '11', '111'], ['2', '21'],...]
      let list = this.userList
      let current = []; // 获取当前选中的option, 因为element文档中没有获取当前选中的option的方法,所以我通过上一次和本地的选中数据进行对比来获取
      if(node.length >= this.preSelected.length) {
        let keys = this.preSelected.map(item => JSON.stringify(item))
        current = node.filter(item => !keys.includes(JSON.stringify(item)))
        console.log('选中某项', current);
      }else {
        // 取消选中
        let keys = node.map(item => JSON.stringify(item))
        current = this.preSelected.filter(item => !keys.includes(JSON.stringify(item)))
        console.log('取消选中', current);
        this.defaultExpandedKeys = current[0]
      }
      // 根据element的选中数据格式, 每一个选项都是一个列表, 列表第一项为父级value, 第二项为选中的子级value, ...以此类推
      const currentValue = current.length > 0 ? current[0][0] || '' : ''
      if(currentValue === '全选') {
        if(this.judgetAllSelected(node)) {
          this.loopSelectData(list)
        }else {
          this.dataForm.ids = []
        }
      }else {
        this.dataForm.ids = node
      }
      this.checkIsAddAllSelected(); // 主要是勾选或取消非“全选”项时，对于全选的逻辑处理
      this.preSelected = this.dataForm.ids; // 保存上一次的选择结果
      // this.changeHandle();
    },
    async checkIsAddAllSelected() {
      let list = this.userList; // 原始数据列表
      if (this.originData.length === 0) {
        this.loopFlatData(list) // 把所有的父子级平铺成一个一级列表
      }
      let origin = JSON.parse(JSON.stringify(this.originData)).filter(item => item.label !== '全选')
      let now = JSON.parse(JSON.stringify(this.dataForm.ids)).filter(item => item[0] !== '全选')
      // console.log('...xxx', origin.length, now.length);
      if (origin.length > now.length) {
        // 非全选时, 如果有之前选过全选,要把全选过滤掉
        this.dataForm.ids = this.dataForm.ids.filter(item => item[0] !== '全选')
        // console.log(this.$refs.cascaderRef);
        await this.$nextTick()
        const level = this.defaultExpandedKeys.length
        const l0 = this.userList.findIndex(e => e.code === this.defaultExpandedKeys[0])
        const menus = this.$refs.cascaderRef.panel.$refs.menu
        // console.log(menus);
        if (menus[0] && l0 >= 0) {
          const firstNodes = menus[0].$el.querySelectorAll('.el-cascader-node[tabindex="-1"]');
          // console.log(firstNodes);
          firstNodes[l0].click()
          await this.$nextTick()
          let l1 = 0
          if (level > 1) {
            const children = this.userList[l0]?.children || []
            l1 = children.findIndex(e => e.code === this.defaultExpandedKeys[1])
            // console.log(this.defaultExpandedKeys, level, l0, l1);
            if (menus[1] && l1 >= 0) {
              const firstNodes = menus[1].$el.querySelectorAll('.el-cascader-node[tabindex="-1"]');
              // console.log(firstNodes);
              firstNodes[l1].click()
            }
          }
        }
      } else {
        // 当所有的数据都选择时, 要自动把全选勾选上
        if (this.dataForm.ids[0] && this.dataForm.ids[0][0] !== '全选') {
          this.dataForm.ids = [['全选'], ...this.dataForm.ids]
        }
      }
    },
    judgetAllSelected(node) {
      // 判断是否是全选,也就是看已选择的选中中包不包含"全选"
      let isAllSelected = false
      for(let i = 0; i < node.length; i++) {
          if(node[i][0] === '全选') {
            isAllSelected = true
            break;
          }
      }
      return isAllSelected
    },
    loopFlatData(list = [], parentNode = []) {
      list.length > 0 && list.forEach(e => {
        let pNode = [...parentNode]; // 注意这里必须是深拷贝，否则会由于引用类型赋值的是地址（指针），导致parentNode在pNode更新时，同时被更新
        if(e.children && e.children.length > 0) {
          pNode.push(e.value)// 1 11
          this.loopFlatData(e.children, pNode)
        }else {
          if(e.label !== '全选') {
            if(parentNode.length > 0) {
              pNode.push(e.value)
              this.originData.push({ ...e, parentNode: pNode })
            }else {
              this.originData.push(e)
            }
          }
        }
      })
    },
    loopSelectData(list, parentNode = []) {
      list.length > 0 && list.forEach(e => {
        let pNode = [...parentNode]; // 注意这里必须是深拷贝，否则会由于引用类型赋值的是地址（指针），导致parentNode在pNode更新时，同时被更新
        if(e.children && e.children.length > 0) {
          pNode.push(e.value)// 1 11
          this.loopSelectData(e.children, pNode)
        }else {
          if(parentNode.length > 0) {
            this.dataForm.ids.push([...parentNode, e.value])
          }else {
            this.dataForm.ids.push([e.value])
          }
        }
      })
    },
    visibleChange(visible) {
      if(visible) {
        this.userList.unshift({ label: '全选', value: '全选' })
      }else {
        this.userList.shift()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.drawer__footer {
  background: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 700px;
  border-top: 1px solid #EBEEF5;
  height: 60px;
  text-align: right;
  padding: 15px;
  box-sizing: border-box;
  .el-button {
    min-width: 88px;
  }
}
// .el-select{
//   ::v-deep .el-input__inner{
//     height: auto !important;
//   } 
// }
 
</style>
