<template>
  <div>
    <el-drawer title="查看邮件" size="800px" :modal="false" :visible.sync="drawer" :direction="direction"
      :before-close="handleClose">
      <div style="padding:20px 20px 100px 20px;">
        <div class="title">{{ mailContent.title }}</div>
        <div class="row">
          <span class="label">发送时间：</span>
          <span class="value">{{ mailContent.sendTime }}</span>
        </div>
        <div class="row">
          <span class="label">收件人：</span>
          <span class="value singeLine">{{ mailContent.receiver }}</span>
        </div>
        <div class="row">
          <span class="label">发送状态：</span>
          <!-- <span class="value">{{ mailContent.status }}</span> -->
          <span v-if="mailContent.allUserSendStatus == 3" class="value">部分发送</span>
          <span v-if="mailContent.allUserSendStatus == 0" class="value" >发送成功</span>
          <span v-if="mailContent.allUserSendStatus == 1" class="value" >发送失败</span>
          <span v-if="mailContent.allUserSendStatus == 2" class="value" >发送中</span>
          <span class="check" @click="checkHandle">(查看)</span>
        </div>
        <div class="context">
          <span class="label">邮件内容：</span>
          <div class="value border" v-html="mailContent.content" ></div>
        </div>
      </div>
      <div class="drawer__footer">
        <el-button size="small" style="width: 88px;" @click="handleClose">取消</el-button>
        <el-button type="primary" style="width: 88px;" size="small" @click="delHandle">删除</el-button>
      </div>
    </el-drawer>

    <el-dialog title="发送状态" :visible.sync="dialogVisible" width="40%" :before-close="handleCloseDialog">
      <el-table :data="mailContent.userList" class="table" style="width: 100%" height="60vh" stripe>
        <el-table-column prop="username" label="收件人">
        </el-table-column>
        <el-table-column prop="status" label="发送状态">
          <template slot-scope="scope">
            <!-- 0：发送中，1：发送成功，2：发送失败 -->
            <template v-if="scope.row.status == 0">
              发送中
            </template>
            <template v-else-if="scope.row.status == 1">
              发送成功
            </template>
            <template v-else-if="scope.row.status == 2">
              发送失败
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="sendTime" label="发送时间">
        </el-table-column>
      </el-table>
      <!-- <el-pagination class="pagination" @current-change="handleCurrentChange" :page-size="10" :current-page="currentPage"
        layout="total, prev, pager, next" :total="total">
      </el-pagination> -->

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      direction: 'rtl',
      mailContent: {
        title: '',
        sendTime: '',
        receiver: '',
        status: '',
        content: ''
      },
      // 查看弹窗
      dialogVisible: false,
      
      // 弹窗分页
      currentPage: 1,
      total: 20,
    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {
    // 初始化
    initMailData(val){
      console.log(val);
      this.mailContent = {
        ...val,
        sendTime:val.sendTime.slice(0,16),
        receiver:val.userList.map(i=>{
            return `${i.username}<${i.email}>`
          }).join(','),
      }
    },
    // 关闭抽屉
    handleClose() {
      this.$emit('update:drawer', false)
    },
    delHandle() {
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete(this.$apiPath.adminEmailDelete+`/${this.mailContent.id}`)
        .then(()=>{
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.handleClose()
          this.$emit('checkHandle')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        }) 
      })
    },
    // 查看发送状态
    checkHandle() {
      this.dialogVisible = true
    },
    // 关闭对话框
    handleCloseDialog() {
      this.dialogVisible = false
    },
    // 分页
    handleCurrentChange(){

    }
  },
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 20px;
}

.row,.context {
  margin-bottom: 20px;

  .label {
    display: inline-block;
    font-size: 16px;
    color: #686868;
    margin-right: 10px;
    width: 80px;
  }

  .value {
    font-size: 16px;
    color: #494848;
    white-space: pre-wrap;
  }
  .singeLine{
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
    display: inline-block;
    margin-top: 5px;
    width: 400px;
  }
  .border{
    border: 1px solid #ccc;
    min-height: 600px;
    min-width: 600px;
    padding: 5px;
  }
  .check {
    color: #409EFF;
    margin-left: 10px;
    cursor: pointer;
    font-size: 14px;
  }
}

.context {
  display: flex;

  .label {
    flex-shrink: 0;
  }
}
.drawer__footer {
  background: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 700px;
  border-top: 1px solid #EBEEF5;
  height: 60px;
  text-align: right;
  padding: 15px;
  box-sizing: border-box;
  .el-button {
    width: 88px;
  }
}
.el-dialog {
  .pagination {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
