<template>
    <div class="component" v-loading="loading">
        <div class="search-area">
            <el-input class="search-text" @change="getTableData(true)" clearable placeholder="单位名称" suffix-icon="el-icon-search" v-model="query.name"></el-input>
            <el-select class="search-component-status" @change="getTableData(true)" clearable v-model="query.status" placeholder="单位状态">
                <el-option label="正常" value="ENABLE"></el-option>
                <el-option label="禁用" value="DISABLE"></el-option>
            </el-select>
        </div>
        <div class="table-area">
            <el-table stripe :data="tableData" class="table" height="100"
                      style="width: 100%">
                <el-table-column type="index" width="80" :index="computeIndex"></el-table-column>
                <el-table-column
                        prop="userCompanyId"
                        label="公司ID"
                        width="100">
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="公司名">
                </el-table-column>
                <el-table-column
                        prop="address"
                        label="公司地址"
                >
                </el-table-column>
<!--                <el-table-column-->
<!--                        prop="legalPerson"-->
<!--                        label="法人"-->
<!--                >-->
<!--                </el-table-column>-->
                <el-table-column
                        prop="contactPerson"
                        label="联系人"
                >
                </el-table-column>
                <el-table-column
                        prop="phone"
                        label="联系电话"
                >
                </el-table-column>
                <el-table-column
                        label="用户数"
                >
                    <template slot-scope="scope">
                        <span class="showUserLoginLog" @click="showCompanyUser(scope.row.id)">{{scope.row.userNum}}</span>
                    </template>
                </el-table-column>
<!--                <el-table-column-->
<!--                        prop="email"-->
<!--                        label="联系邮箱" >-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="fax"-->
<!--                        label="传真" >-->
<!--                </el-table-column>-->
                <el-table-column
                        label="公司状态"
                        width="100">
                    <template slot-scope="scope">
                        {{scope.row.status === 'ENABLE' ? '启用' : '禁用'}}
                    </template>
                </el-table-column>
<!--                <el-table-column-->
<!--                        label="创建时间"-->
<!--                >-->
<!--                    <template slot-scope="scope">-->
<!--                        {{scope.row.createdAt != null ? scope.row.createdAt.split(' ')[0] : ''}}-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column
                        label="更新时间"
                        width="120"
                >
                    <template slot-scope="scope">
                        {{scope.row.updatedAt != null ? scope.row.updatedAt.split(' ')[0] : ''}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" header-align="center" width="134">
                    <template slot-scope="scope">
                        <el-button class="btn" type="text" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button class="btn" type="text" @click="handleDelete(scope.row)">删除</el-button>
                        <el-button class="btn" type="text" @click="handleDisable(scope.row)">{{scope.row.status === 'ENABLE' ? '禁用' : '启用'}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <el-button class="add-component" @click="handleAdd" size="small" type="primary">添加单位</el-button>
            <el-button class="download-component" @click="handleDownload" type="text">下载Excel</el-button>
            <div class="page">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="query.currentPage"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="query.pageSize"
                        layout="total, sizes, prev, pager, next"
                        :total="total">
                </el-pagination>
            </div>
        </div>
        <transition name="el-fade-in-linear">
            <div @click.self="handleCancel" v-show="showShadow" class="shadow">
                <div class="component-detail-dialog">
                    <transition name="el-fade-in-linear">
                        <user-company-detail @commitSuccess="handleCommitSuccess" @cancel="handleCancel" v-if="showEditDialog"
                                             :dialogType="dialogType" :userCompanyId="userCompanyId">
                        </user-company-detail>
                    </transition>
                </div>
                <div class="component-detail-dialog">
                    <transition name="el-fade-in-linear">
                        <ComponentBatch v-if="showBatch" @cancel="handleCancel"></ComponentBatch>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import UserCompanyDetail from "./UserCompanyDetail"
    export default {
        components: {
            UserCompanyDetail
        },
        data(){
            return {
                query: {
                    name: '',
                    status: '',
                    pageSize: 10,
                    currentPage: 1
                },
                companyList: [],
                loading: false,
                tableData: [],
                total: 0,
                showShadow: false,
                showEditDialog: false,
                dialogType: 'ADD',
                userCompanyId: null,
                showBatch: false,
                multipleSelection: [],
            }
        },
        mounted(){
            this.getTableData()
        },
        methods: {
            computeIndex(index) {
                return (this.query.currentPage - 1) * this.query.pageSize + index + 1
            },
            showCompanyUser(companyId) {
                this.$router.push({path: '/admin/user', query: {companyId: companyId}})
            },
            getTableData(reset){
                this.loading = true;
                if (reset) {
                    this.query.currentPage = 1
                }
                this.$http.get(this.$apiPath.adminUserCompanyList, {params: this.query}).then(res => {
                    if(res.status === 200 && res.data.status === 200) {
                        this.total = res.data.data.total
                        this.tableData = res.data.data.list
                    }
                    this.loading = false;
                })
            },
            handleAdd(){
                this.showShadow = true
                this.showEditDialog = true
                this.dialogType = 'ADD'
            },
            handleEdit(row){
                this.userCompanyId = row.id
                this.dialogType = 'EDIT'
                this.showEditDialog = true
                this.showShadow = true
            },
            handleDelete(row){
                this.$confirm('此操作将永久删除该单位，是否继续？', '警告', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(this.$apiPath.adminDeleteUserCompany, {userCompanyId: row.id}).then(res => {
                        if(res.status === 200 && res.data.status === 200){
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.getTableData();
                        } else if (res.status === 200) {
                            this.$message.error(res.data.message)
                        }

                    });

                }).catch(() => {
                    //cancel
                })
            },
            handleDisable(row){
                let type = '', typeStr = '';
                if(row.status === 'ENABLE'){
                    type = 'DISABLE'
                    typeStr = '禁用'
                }else{
                    type = 'ENABLE'
                    typeStr = '启用'
                }
                this.$confirm('确认要' + typeStr +'当前公司吗？', '警告', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(this.$apiPath.adminUserCompanyStatus, {userCompanyId: row.id, type: type}).then(res => {
                        if(res.status === 200 && res.data.status === 200){
                            this.$message({
                                type: 'success',
                                message: typeStr + '成功!'
                            });
                            this.getTableData();
                        }
                    });
                }).catch(() => {
                    //cancel
                })
            },
            handleSizeChange(value){
                this.query.pageSize = value
                this.getTableData()
            },
            handleCurrentChange(value){
                this.query.currentPage = value
                this.getTableData()
            },
            handleCancel(){
                this.showEditDialog = false;
                this.showShadow = false;
                this.showBatch = false
            },
            handleCommitSuccess(){
                this.showEditDialog = false;
                this.showShadow = false;
                this.getTableData();
            },
            importComponent(){
                // this.showShadow = true
                // this.showBatch = true
            },
            handleDownload(){
                let _this = this
                this.$message({
                    message: '即将开始下载',
                    duration: 2000,
                    onClose: function(){
                        _this.$http({
                            method: 'get',
                            url: _this.$apiPath.adminDownloadUserCompany,
                            responseType: 'blob'
                        }).then(res => {
                            let blob = new Blob([res.data], {type: 'application/force-download'})
                            let aTag = document.createElement('a')
                            aTag.download = 'UserCompany.xlsx'// 下载的文件名
                            aTag.href = URL.createObjectURL(blob)
                            aTag.click()
                            URL.revokeObjectURL(blob)
                        });
                    }
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .showUserLoginLog{
        text-decoration: underline;
        color: #3F51B5;
        cursor: pointer;
        padding: 4px 10px;
    }
    .search-area{
        height: 32px;
        padding: 10px 14px;
        border-bottom: solid 1px #e8e8e8;
    }
    .search-text{
        width: 285px;
        height: 32px;
        float: left;
    }
    .search-company, .search-component-type, .search-component-status{
        margin-left: 8px;
        float: left;
    }
    .search-company{
        width: 180px;
    }
    .search-component-type{
        width: 135px;
    }
    .search-component-status{
        width: 112px;
    }
    .table{
        height: calc(100vh - 270px) !important;
    }
    .footer{
        .add-component{
            font-size: 14px;
            line-height: 14px;
            height: 30px;
            padding: 7px 15px;
            background-color: #5C8EFF;
            margin: 14px 0 0 10px;
        }

        .download-component, .import-component{
            color: #5C8EFF;
            height: 30px;
            line-height: 14px;
            padding: 7px 0;
            margin-left: 20px;
            text-decoration: underline;
        }

        .page{
            float: right;
            margin: 11px 6px 0 0;
        }
    }
    .component-detail-dialog{
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -300px 0 0 -281px;
    }
</style>

<style lang="scss">
    .search-area .el-input__inner{
        // height: 32px !important;
        // line-height: 30px !important;
    }
    .el-input__icon{
        line-height: 32px !important;
    }
</style>
