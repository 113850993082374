<template>
    <div class="import-user">
        <div class="head-part">
            <span class="title">批量导入用户</span>
            <i class="el-icon-close close" @click="cancel" title="关闭"></i>
        </div>
        <div class="container item-group">
            <div class="item">
                <div class="step">Step1</div>
                <div class="content">请下载模板，并根据模板中的导入说明仔细准备数据</div>
                <el-button @click="downloadTemplate" class="download" type="text">下载模板</el-button>
            </div>
            <div class="item">
                <div class="step">Step2</div>
                <div class="content">请根据模板中的相关规则编辑上传文件，并在此导入</div>
                <div class="file">
                    <el-upload
                        class="upload-demo"
                        :action="uploadAction"
                        :file-list="fileList"
                        ref="upload"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="fileChange"
                        :accept="acceptFiles"
                        :on-success="success">
                        <el-input class="file-input" readonly size="small" v-model="fileName"></el-input>
                        <el-button type="info" class="btn-select" size="small">选择</el-button>
                    </el-upload>
                    <el-button type="primary" class="btn-submit" @click="commit" size="small">导入</el-button>
                </div>
        </div>
        <div class="footer-part">
            <el-button class="btn" size="small" @click="cancel" type="info">关闭</el-button>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            fileName: '',
            fileList: [],
            acceptFiles: '.xlsx,.xls',
        }
    },
    computed:{
        uploadAction(){
            return this.$apiPath.adminUserImportUpload;
        }
    },
    methods: {
        cancel(){
            this.$emit('cancel');
        },
        fileChange(file){
            this.fileName = file.name;
        },
        commit(){
            if(this.fileName !== ''){
                this.$refs.upload.submit();
            }else{
                this.$message({
                    message: '请先选择需要上传的文件！',
                    type: 'warning'
                });
            }
        },
        success(res){
            if(res.status !== 200){
                if(res.status === 500){
                    this.$message.error(res.message);
                }else{
                    this.$alert(res.data, res.message, {
                        confirmButtonText: '确定'
                    });
                    this.$emit('importSuccess');
                }
            }else{
                this.$message({
                    message: '导入成功！',
                    type: 'success'
                });
                this.$emit('importSuccess');
            }

        },
        downloadTemplate(){
            let param = {
                fileName: 'UserImportTemplate.xlsx',
                folder: 'template'
            };
            this.$http.get(this.$apiPath.serviceDownloadCheck, {params: param}).then(res => {
                if(res.status === 200 && res.data.status === 200){
                    var _this = this;
                    this.$message({
                        message: '即将开始下载',
                        duration: 2000,
                        onClose: function(){
                            _this.$http({
                                method: 'get',
                                url: _this.$apiPath.serviceDownloadResource,
                                params: param,
                                responseType: 'blob'
                            }).then(res => {
                                var blob = new Blob([res.data], {type: 'application/force-download'});
                                var aTag = document.createElement('a');
                                aTag.download = 'UserImportTemplate.xlsx';// 下载的文件名
                                aTag.href = URL.createObjectURL(blob);
                                aTag.click();
                                URL.revokeObjectURL(blob);
                            });
                        }
                    });
                }else{
                    this.$message.error(res.data.message);
                }
            });
        }
    }
}
</script>


<style lang="scss" scoped>
    .import-user{
        width: 560px;
        height: 332px;
        border-radius: 3px;
        color: rgba(16, 16, 16, 1);
        font-size: 14px;
        border: 1px solid rgba(208, 208, 208, 1);
        background-color: #fff;
        position: relative;

        .head-part{
            position: relative;
            .title{
                display: block;
                font-size: 18px;
                margin-left: 18px;
                padding-top: 14px;
                color: #101010;
            }

            .close{
                position: absolute;
                color: #CECECE;
                height: 20px;
                width: 20px;
                font-size: 20px;
                right: 14px;
                top: 12px;
                cursor: pointer;
            }
        }

        .item{
            margin-top: 16px;
            padding: 0 0 7px 20px;
            position: relative;

            .step{
                height: 26px;
                line-height: 26px;
                font-size: 18px;
                font-weight: bold;
            }

            .content{
                margin-top: 2px;
                height: 26px;
                line-height: 26px;
                font-size: 14px;
                color: #101010;
            }

            .file{
                margin-top: 5px;

                .file-input{
                    width: 250px;
                }

                .btn-select{
                    width: 60px;
                    background-color: #686868;
                    border: 1px solid #686868;
                    font-size: 14px;
                    padding: 7px 15px;
                }

                .btn-submit{
                    background-color: #5C8EFF;
                    color: #FFFFFF;
                    margin-left: 13px;
                    width: 80px;
                    font-size: 14px;
                    padding: 7px 15px;
                }

                .upload-demo{
                    float: left;
                }
            }

            .download{
                padding: 0;
                text-decoration: underline;
                height: 26px;
                line-height: 26px;
                position: absolute;
                left: 20px;
                bottom: -12px;
                color: #5C8EFF;
            }
        }
        .footer-part{
            position: absolute;
            height: 54px;
            width: 100%;
            border-top: 1px solid #EEE;
            bottom: 0;
            left: 0;

            .btn{
                width: 80px;
                font-size: 14px;
                padding: 7px 15px;
                margin: 10px 12px 0 0;
                float: right;
                background-color: #686868;
                border: 1px solid #686868;
            }
        }
    }
</style>
