// import { DOMElement } from './dom'
import { IDomEditor, SlateDescendant, SlateElement } from '@wangeditor/editor'
// import { MentionElement } from './custom-types'

function parseHtml (
  elem,
  children,
  editor
) {
  // elem HTML 结构 <span data-w-e-type="mention" data-w-e-is-void data-w-e-is-inline data-value="张三" data-info="xxx">@张三</span>

  const value = elem.getAttribute('data-value') || ''
  const limit = elem.getAttribute('data-var-isLimit') || ''

  return {
    type: 'mention',
    value,
    isLimit:limit,
    children: [{ text: '' }] // void node 必须有一个空白 text
  }
}

const parseHtmlConf = {
  selector: 'span[data-w-e-type="mention"]',
  parseElemHtml: parseHtml
}

export default parseHtmlConf
