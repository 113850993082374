<template>
  <div class="batch-dialog">
    <close-header title="批量维护" @cancel="handleCancle"></close-header>
      <div class="container item-group">
        <div class="item">
          <div class="step">Step1</div>
          <div class="content">请下载模板，并根据模板中的导入说明仔细准备数据</div>
          <el-button @click="downloadTemplate" class="download" type="text">下载模板</el-button>
        </div>
        <div class="item">
          <div class="step">Step2</div>
          <div class="content">请根据模板中的相关规则编辑上传文件，并在此导入</div>
          <div class="file">
            <el-upload
                class="upload-demo"
                :action="uploadAction"
                :file-list="fileList"
                ref="upload"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="fileChange"
                :accept="acceptFiles"
                :on-success="success">
                <el-input class="file-input" readonly size="small" v-model="fileName"></el-input>
                <el-button type="info" class="btn-select" size="small">选择</el-button>
            </el-upload>
            <el-button type="primary" class="btn-submit" @click="commit" size="small">导入</el-button>
        </div>
      </div>
    </div>
    <div class="footer-part">
        <el-button class="btn" size="small" @click="cancel" type="info">关闭</el-button>
    </div>
  </div>
</template>

<script>
import CloseHeader from './CloseHeader'
export default {
  data(){
    return {
      fileName: '',
      fileList: [],
      acceptFiles: '.xlsx,.xls',
    }
  },
  computed:{
    uploadAction(){
        return this.$apiPath.adminComponentUpload
    }
  },
  methods:{
    cancel(){
      this.$emit('cancel')
    },
    fileChange(file){
      this.fileName = file.name
    },
    handleCancle(){
      this.$emit('cancel')
    },
    downloadTemplate(){
      let param = {
        fileName: 'ComponentTemplate.xlsx',
        folder: 'template'
      };
      this.$http.get(this.$apiPath.serviceDownloadCheck, {params: param}).then(res => {
        if(res.status === 200 && res.data.status === 200){
          var _this = this
          this.$message({
            message: '即将开始下载',
            duration: 2000,
            onClose: function(){
              _this.$http({
                  method: 'get',
                  url: _this.$apiPath.serviceDownloadResource,
                  params: param,
                  responseType: 'blob'
              }).then(res => {
                  var blob = new Blob([res.data], {type: 'application/force-download'})
                  var aTag = document.createElement('a')
                  aTag.download = 'ComponentTemplate.xlsx'// 下载的文件名
                  aTag.href = URL.createObjectURL(blob)
                  aTag.click()
                  URL.revokeObjectURL(blob)
              });
            }
          });
        }else{
          this.$message.error(res.data.message)
        }
      })
    },
    commit(){
      if(this.fileName !== ''){
        this.$refs.upload.submit()
      }else{
        this.$message({
          message: '请先选择需要上传的文件！',
          type: 'warning'
        })
      }
    },
    success(res){
      if(res.status !== 200){
        if(res.status === 500){
          this.$message.error(res.message)
        }else{
          this.$alert(res.data, res.message, {
            confirmButtonText: '确定'
          });
          this.$emit('importSuccess')
        }
      }else{
        this.$message({
            message: '导入成功！',
            type: 'success'
        })
        this.$emit('importSuccess')
      }
    },
  },
  components:{
    CloseHeader
  }
}
</script>

<style scoped lang="scss">
  .batch-dialog{
    width: 560px;
    height: 332px;
    background-color: #fff;

    .item{
            margin-top: 16px;
            padding: 0 0 7px 20px;
            position: relative;

            .step{
                height: 26px;
                line-height: 26px;
                font-size: 18px;
                font-weight: bold;
            }

            .content{
                margin-top: 2px;
                height: 26px;
                line-height: 26px;
                font-size: 14px;
                color: #101010;
            }

            .file{
                margin-top: 5px;

                .file-input{
                    width: 250px;
                }

                .btn-select{
                    width: 60px;
                    background-color: #686868;
                    border: 1px solid #686868;
                    font-size: 14px;
                    padding: 7px 15px;
                }

                .btn-submit{
                    background-color: #5C8EFF;
                    color: #FFFFFF;
                    margin-left: 13px;
                    width: 80px;
                    font-size: 14px;
                    padding: 7px 15px;
                }

                .upload-demo{
                    float: left;
                }
            }

            .download{
                padding: 0;
                text-decoration: underline;
                height: 26px;
                line-height: 26px;
                position: absolute;
                left: 20px;
                bottom: -12px;
                color: #5C8EFF;
            }
        }

        .footer-part{
            position: absolute;
            height: 54px;
            width: 100%;
            border-top: 1px solid #EEE;
            bottom: 0;
            left: 0;

            .btn{
                width: 80px;
                font-size: 14px;
                padding: 7px 15px;
                margin: 10px 12px 0 0;
                float: right;
                background-color: #686868;
                border: 1px solid #686868;
            }
        }
  }
</style>
